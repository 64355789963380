import {
  SET_FUND_POOL,
  SET_FUND_SEARCH_POOL,
  SET_FUND_CHART_POOL,
  SET_COMPARISON_CHART_POOL,
  GET_FUND_COMPANIES,
  GET_FUNDS_BY_COMPANY,
  GET_FUNDS_BY_COMPANYCODE,
  SET_FUNDS_BY_COMPANY_PAGINATION,
  GET_FUND_TRANSACTIONS,
  GET_FUND_TRANSACTION_DETAIL,
  GET_FUND_TRANSACTION_CONTRACT_NOTE,
  GET_FUND_CASH_TRANSACTIONS,
  RESET_SEARCH,
  GET_FUND_COMPARISON,
  SET_FUND_COMPARISON,
  GET_FUND_TRANSFER_IN_DOCS,
  GET_FUND_HOLIDAY,
  GET_MARKET_INDEX,
  GET_TOP_PERFORMANCE_FUNDS,
  GET_FUND_RELATED_TOP_PERFORMANCE_FUNDS,
  GET_HOT_BUY_FUNDS,
  SET_COMPARISON_POOL,
  SET_COMPARISON_SELECTED,
  GET_FUND_SEARCH_BASE,
  GET_FUNDS_BY_KEYWORDS,
  GET_CURATED_FUNDS,
  GET_FUND_RANK
} from '~/actions/types'

const _searchInitState = {
  q: '',
  fundCodes: [],
  pagination: {}
}

const initialState = {
  fundpool: {}, // { code: {}, ..., code: {} }, temporialy add _
  chartpool: {}, // { code: { ONE_MONTH: {}, ... }, ..., code: {} }
  searchpool: {},
  comparisonChartpool: {}, // { code: {`$start-$end-$currency`: {}, ... }, ... } e.g. `20180430-20180530-USD`
  comparison: {
    pool: [],
    selected: [],
    init: true
  },
  search: _searchInitState,
  companyFundsMap: {}, // { companyId: { fundCodes: { 1: [], 2: [] }, pagination } }
  fundCompanies: [],
  fundCompanyCodes: [],
  fundCompanyMap: {},
  fundTransactions: [],
  fundTransactionDetailDict: {}, // { transRef: {} }
  fundTransactionContractNoteDict: {}, // { transRef: {} }
  fundCashTransactions: [],
  fundsPagination: {},
  fundComparison: [],
  fundComparisonPoints: {},
  fundHolidayInfo: {},
  fundTransferInDocs: [],
  fundHolidayinfos: {},
  marketIndex: {
    stock: [],
    bond: [],
    other: []
  },
  fundRelatedTopPerformanceFunds: {},
  topPerformanceFunds: {},
  hotBuyFunds: [],
  curatedListFunds: {},
  fundRankLists: {
    topEquityFunds: [],
    topBondFunds: [],
    topEquityHybridFunds: [],
    topMoneyMarketFunds: []
  },
  fundPoolCodes: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FUND_SEARCH_BASE:
      return {
        ...state,
        searchBase: action.searchBase
      }
    case SET_FUND_POOL:
      return {
        ...state,
        fundpool: {
          ...state.fundpool,
          ...action.fundpool
        },
        fundPoolCodes: action.fundPoolCodes
      }
    case SET_FUND_SEARCH_POOL:
      return {
        ...state,
        searchpool: {
          ...state.searchpool,
          ...action.searchpool
        }
      }
    case SET_FUND_CHART_POOL:
      return {
        ...state,
        chartpool: {
          ...state.chartpool,
          ...action.chartpool
        }
      }
    case SET_COMPARISON_CHART_POOL:
      return {
        ...state,
        comparisonChartpool: {
          ...state.comparisonChartpool,
          ...action.comparisonChartpool
        }
      }
    // GET_FUND_COMPANIES udpates reducers: fund, content
    case GET_FUND_COMPANIES:
      return {
        ...state,
        fundCompanies: action.fundCompanies,
        fundCompanyCodes: action.fundCompanyCodes
      }
    case GET_FUNDS_BY_COMPANY:
      return {
        ...state,
        companyFundsMap: {
          ...state.companyFundsMap,
          [action.companyId]: {
            ...state.companyFundsMap[action.companyId],
            fundCodes: action.fundCodes
          }
        }
      }
    case GET_FUNDS_BY_COMPANYCODE:
      return {
        ...state,
        companyFundsMap: {
          ...state.companyFundsMap,
          [action.companyCode]: {
            ...state.companyFundsMap[action.companyCode],
            fundCodes: action.fundCodes
          }
        }
      }
    case SET_FUNDS_BY_COMPANY_PAGINATION:
      return {
        ...state,
        companyFundsMap: {
          ...state.companyFundsMap,
          [action.companyCode]: {
            ...state.companyFundsMap[action.companyCode],
            pagination: action.pagination
          }
        }
      }
    case GET_FUND_TRANSACTIONS:
      return {
        ...state,
        fundTransactions: action.fundTransactions
      }
    case GET_FUND_TRANSACTION_DETAIL:
      return {
        ...state,
        fundTransactionDetailDict: {
          ...state.fundTransactionDetailDict,
          [action.transRef]: action.fundTransactionDetail
        }
      }
    case GET_FUND_TRANSACTION_CONTRACT_NOTE:
      return {
        ...state,
        fundTransactionContractNoteDict: {
          ...state.fundTransactionContractNoteDict,
          [action.transRef]: action.note
        }
      }
    case GET_FUND_CASH_TRANSACTIONS:
      return {
        ...state,
        fundCashTransactions: action.fundCashTransactions
      }
    case GET_FUNDS_BY_KEYWORDS:
      return {
        ...state,
        search: action.data
      }
    case RESET_SEARCH:
      return {
        ...state,
        search: _searchInitState
      }
    case GET_FUND_COMPARISON:
      return {
        ...state,
        fundComparison: action.fundComparison,
        fundComparisonPoints: action.points
      }
    case SET_FUND_COMPARISON:
      return {
        ...state,
        fundComparison: action.fundComparison,
        fundComparisonPoints: action.points
      }
    case GET_FUND_TRANSFER_IN_DOCS:
      return {
        ...state,
        fundTransferInDocs: action.docs
      }
    case GET_FUND_HOLIDAY:
      return {
        ...state,
        fundHolidayInfo: action.holidayInfo
      }
    case GET_MARKET_INDEX:
      return {
        ...state,
        marketIndex: {
          ...state.marketIndex,
          ...action.data
        }
      }
    case GET_TOP_PERFORMANCE_FUNDS:
      return {
        ...state,
        topPerformanceFunds: {
          ...state.topPerformanceFunds,
          ...action.data
        }
      }
    case GET_FUND_RANK:
      return {
        ...state,
        fundRankLists: {
          ...state.fundRankLists,
          [action.fundType]: {
            ...state.fundRankLists[action.fundType],
            [action.period]: action.data
          }
        }
      }
    case GET_FUND_RELATED_TOP_PERFORMANCE_FUNDS:
      return {
        ...state,
        fundRelatedTopPerformanceFunds: {
          ...state.fundRelatedTopPerformanceFunds,
          ...action.data
        }
      }
    case GET_HOT_BUY_FUNDS:
      return {
        ...state,
        hotBuyFunds: action.hotBuyFunds
      }
    case SET_COMPARISON_POOL:
      return {
        ...state,
        comparison: {
          ...state.comparison,
          pool: action.pool
        }
      }
    case SET_COMPARISON_SELECTED:
      return {
        ...state,
        comparison: {
          ...state.comparison,
          selected: action.selected,
          init: action.init
        }
      }
    case GET_CURATED_FUNDS:
      return {
        ...state,
        curatedListFunds: {
          ...state.curatedListFunds,
          [action.index]: action.funds
        }
      }
    default:
      return state
  }
}
