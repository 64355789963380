import {
  ADD_API_ERROR,
  RM_API_ERROR,
  ADD_FORM_ERROR,
  RM_FORM_ERROR
} from '~/actions/types'

/**
 * addApiError
 * @param  {string} endpoint [name of api endpoint]
 * @param  {string} msg [server error message]
 * almost use in action/xx.js
 * example: addApiError('register', 'Email has been registered')
 */
export const addApiError = (endpoint, msg) => (dispatch) => {
  dispatch({
    type: ADD_API_ERROR,
    endpoint,
    msg
  })
}

/**
 * rmApiError
 * @param  {string} endpoint [name of api endpoint]
 * almost use in action/xx.js
 * example: rmApiError('register')
 */
export const rmApiError = (endpoint) => (dispatch) => {
  dispatch({
    type: RM_API_ERROR,
    endpoint
  })
}

/**
 * addFormError
 * @param {string} scope [scope of page/component]
 * @param {string} name [name of value]
 * example: addFormError('register', 'password')
 */
export const addFormError = (scope, name) => (dispatch) => {
  dispatch({
    type: ADD_FORM_ERROR,
    scope,
    name
  })
}

/**
 * rmFormError
 * @param {string} scope [scope of page/component]
 * @param {string} name [name of value]
 * example: rmFormError('register', 'password')
 */
export const rmFormError = (scope, name) => (dispatch) => {
  dispatch({
    type: RM_FORM_ERROR,
    scope,
    name
  })
}

/**
 * clearFormError
 * @param {string} scope [scope of page/component]
 * example: rmFormError('register', 'password')
 * clear all errors in a scope, use in componentWillUnmount
 */
// export const clearFormError = (scope, name) => (dispatch) => {
//   dispatch({
//     type: CLEAR_FORM_ERROR,
//     scope
//   })
// }
