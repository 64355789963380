import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import t from '~/utils/locales'
import { formatDate, numberWithCommasNSuffix } from '~/utils/num'
import { closeModal } from '~/actions/modal'
import { getFundTransactionDetail, getFundTransactionContractNote } from '~/actions/fund'

import Modal from '~/components/Layout/Modal'
import ContentSection from '~/components/Layout/ContentSection'
import Button from '~/components/Form/Button'
import TransTypeLabel from '~/components/Logic/TransTypeLabel'
import Divider from '~/components/Common/Divider'
import OrderProgress from '~/components/Logic/OrderProgress'
import Attachment from './Attachment'

import styles from './FundOrderDetail.scss'

class FundOrderDetailModal extends React.Component {
  state = {
    noTransRefYet: _isEmpty(this.props.transRef),
    transRef: this.props.transRef
  }
  componentDidMount () {
    this.getFundTransactionDetail(this.state.transRef)
  }
  shouldComponentUpdate (nextProps) {
    if (nextProps.transRef !== this.props.transRef) {
      this.setState({ transRef: nextProps.transRef })
      if (!_isEmpty(nextProps.transRef)) {
        this.getFundTransactionDetail(nextProps.transRef)
      }
    }
    return true
  }
  onConfirm = () => {
    this.close()
  }
  cancel = () => {
    this.close()
  }
  close = () => {
    this.props.closeModal('fundOrderDetail')
    this.props.onClose && this.props.onClose()
  }
  constructCurrencyAmount = (currencyKey, amountKey, data = this.props.transactionDetail) => {
    return `${_get(data, currencyKey) || '-'} ${numberWithCommasNSuffix(_get(data, amountKey)) || '-'}`
  }
  constructQuantityAmount = (quantityKey, data = this.props.transactionDetail) => {
    return `${numberWithCommasNSuffix(_get(data, quantityKey), 3) || '-'} ${t('fundOrderDetail.unit')}`
  }
  getFundTransactionDetail = (transRef) => {
    this.props.getFundTransactionDetail(transRef)
  }
  getFundTransactionContractNote = () => {
    const { url } = this.props.contractNote
    if (url) {
      window.open(url)
    } else {
      this.props.getFundTransactionContractNote(this.state.transRef)
        .then(() => {
          window.open(this.props.contractNote.url)
        })
    }
  }
  getTransTypeBasedData = () => {
    const { transactionDetail } = this.props
    const transType = _get(transactionDetail, 'transType')
    switch (transType) {
      case 'B':
        return {
          steps: _get(transactionDetail, 'steps'),
          amount: this.constructCurrencyAmount('settleCurrency', 'settleAmount')
        }
      case 'S':
        return {
          steps: _get(transactionDetail, 'steps'),
          amount: this.constructQuantityAmount('totalQuantity')
        }
      case 'XB':
      case 'XS':
        return {
          steps: _get(transactionDetail, 'steps'),
          amount: this.constructQuantityAmount('totalQuantity')
        }
      default:
        return {} // no transType
    }
  }

  /**
   * getTransType return the right transType between $transaction and
   * wrapped $switchBuyTransaction, since both switch sell and buy transRef
   * have same API response.
   * @return $transaction transType if no $switchBuyTransaction, or
   * $switchBuyTransaction transType
   */
  getTransType = () => {
    const { transactionDetail } = this.props
    const { switchBuyTransaction } = transactionDetail
    if (_isEmpty(switchBuyTransaction)) return _get(transactionDetail, 'transType')

    // return transType base on the transRef in __init_data__
    return _get(transactionDetail, 'transRef') === this.state.transRef
      ? _get(transactionDetail, 'transType')
      : _get(switchBuyTransaction, 'transType')
  }
  getCommonData = () => {
    const { transactionDetail } = this.props
    return {
      transRef: this.state.transRef,
      transType: this.getTransType(),
      isSwitch: _get(transactionDetail, 'isSwitch'),
      fundNameChi: _get(transactionDetail, 'fundNameChi'),
      fundNameEn: _get(transactionDetail, 'fundNameEn'),
      confirmAmount: this.constructCurrencyAmount('tradingCurrency', 'amount'),
      confirmQuantity: this.constructQuantityAmount('totalQuantity'),
      confirmPrice: this.constructCurrencyAmount('tradingCurrency', 'price'),
      // only if transType === 'S', use bankCharge instead of initChargeAmount
      confirmCharge: _get(transactionDetail, 'initCharge'),
      bankCharge: this.constructCurrencyAmount('settleCurrency', 'bankCharge'),
      confirmSettleDate: `${formatDate(_get(transactionDetail, 'fundSettleDate')) || '-'}`,
      iPointRedeemCommission: _get(transactionDetail, 'iPointRedeemCommission'),
      iPointRedeem: _get(transactionDetail, 'iPointRedeem'),
      toFundNameChi: _get(transactionDetail, 'toFundNameChi'),
      toFundNameEn: _get(transactionDetail, 'toFundNameEn'),
      toAmount: this.constructCurrencyAmount('switchBuyTransaction.settleCurrency', 'switchBuyTransaction.settleAmount'),
      toConfirmAmount: this.constructCurrencyAmount('switchBuyTransaction.tradingCurrency', 'switchBuyTransaction.amount'),
      toConfirmQuantity: this.constructQuantityAmount('switchBuyTransaction.totalQuantity'),
      toConfirmPrice: this.constructCurrencyAmount('switchBuyTransaction.tradingCurrency', 'switchBuyTransaction.price'),
      toConfirmCharge: _get(transactionDetail, 'initCharge')
    }
  }

  render () {
    const {
      confirmed, // order received => settled => confirmed
      switchBuyTransaction
    } = this.props.transactionDetail
    const bankChargeCount = this.props.transactionDetail.bankCharge
    const {
      transType,
      isSwitch,
      fundNameChi,
      fundNameEn,
      transRef,
      confirmAmount,
      confirmQuantity,
      confirmPrice,
      confirmCharge,
      bankCharge,
      confirmSettleDate,
      iPointRedeemCommission,
      iPointRedeem,
      toFundNameChi,
      toFundNameEn,
      toAmount,
      toConfirmAmount,
      toConfirmQuantity,
      toConfirmPrice,
      toConfirmCharge
    } = this.getCommonData()
    const {
      steps,
      amount
    } = this.getTransTypeBasedData()

    const isConfirmed = confirmed
    const isSwitchProcess = !_isEmpty(switchBuyTransaction)
    const isSwitchConfirmed = _get(switchBuyTransaction, 'confirmed', false)

    return (
      <Modal
        isModalOpen={this.props.modalOpenStatus.fundOrderDetail}
        onRequestClose={this.cancel}
      >
        <ContentSection theme='headernfooter' noPadding>
          <ContentSection.Header title={t('fundOrderDetail.title')} />
          <header className={styles.header}>
            <div className={styles.fundName}>
              <h3 className={styles.main}>{fundNameChi}</h3>
              <h3 className={styles.sub}>{fundNameEn}</h3>
            </div>
            <div className={styles.result}>
              <div>
                {transType && <TransTypeLabel transType={transType} />}
              </div>
              <p className={styles.amount}>
                {amount}
              </p>
            </div>
            {
              isSwitch &&
              <div>
                <Divider theme={'switchFund'} />
                <div>
                  <div className={styles.fundName}>
                    <h3 className={styles.main}>{toFundNameChi}</h3>
                    <h3 className={styles.sub}>{toFundNameEn}</h3>
                  </div>
                  <div className={styles.result}>
                    {
                      isSwitchProcess &&
                      <p className={styles.amount}>
                        {toAmount}
                      </p>
                    }
                  </div>
                </div>
              </div>
            }
          </header>

          {
            !_isEmpty(steps) &&
            <section className={styles.progress}>
              <OrderProgress steps={steps} />
            </section>
          }

          {
            !isConfirmed && isSwitch &&
            <div>
              <Divider theme={'gutter'} />
              <div className={styles.switchDisclaimer}>
                {t('fundOrderDetail.switchDisclaimer')}
              </div>
            </div>
          }
          <section className={styles.confirm}>
            {
              isConfirmed && !isSwitch &&
              <ContentSection>
                <ContentSection.Header
                  title={t('fundOrderDetail.confirmInfo')}
                />
                <div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmAmount')}</p>
                    <p>{confirmAmount}</p>
                  </div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmQuantity')}</p>
                    <p>{confirmQuantity}</p>
                  </div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmPrice')}</p>
                    <p>{confirmPrice}</p>
                  </div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmCharge')}</p>
                    <p>
                      {confirmCharge + '%'}
                      {iPointRedeem && <br />}
                      {iPointRedeem && <p>{t('fundOrderDetail.iPointRedeem', iPointRedeem, iPointRedeemCommission)}</p>}
                    </p>
                  </div>
                  {bankChargeCount > 0 &&
                    <div className={styles.row}>
                      <p>{t('fundOrderDetail.bankCharge')}</p>
                      <p>
                        {bankCharge}
                      </p>
                    </div>
                  }
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmSettleDate')}</p>
                    <p>{confirmSettleDate}</p>
                  </div>
                </div>
              </ContentSection>
            }
            {
              isConfirmed && isSwitch &&
              <ContentSection>
                <ContentSection.Header
                  title={t('fundOrderDetail.confirmInfo')}
                />
                <div>
                  <h3 className={styles.confirmFundName}>
                    {fundNameChi}
                  </h3>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmSwitchSellAmount')}</p>
                    <p>{confirmAmount}</p>
                  </div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmSwitchSellQuantity')}</p>
                    <p>{confirmQuantity}</p>
                  </div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmPrice')}</p>
                    <p>{confirmPrice}</p>
                  </div>
                </div>
                <Divider top={15} bottom={15} />
                <div>
                  <h3 className={styles.confirmFundName}>
                    {toFundNameChi}
                  </h3>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmSwitchBuyAmount')}</p>
                    <p>{isSwitchConfirmed ? toConfirmAmount : t('fundOrderDetail.confirming')}</p>
                  </div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmSwitchBuyQuantity')}</p>
                    <p>{isSwitchConfirmed ? toConfirmQuantity : t('fundOrderDetail.confirming')}</p>
                  </div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmPrice')}</p>
                    <p>{isSwitchConfirmed ? toConfirmPrice : t('fundOrderDetail.confirming')}</p>
                  </div>
                </div>
                <Divider top={15} bottom={15} />
                <div>
                  <div className={styles.row}>
                    <p>{t('fundOrderDetail.confirmCharge')}</p>
                    <p>{isSwitchConfirmed ? toConfirmCharge + '%' : t('fundOrderDetail.confirming')}</p>
                  </div>
                  {bankChargeCount > 0 &&
                    <div className={styles.row}>
                      <p>{t('fundOrderDetail.bankCharge')}</p>
                      <p>
                        {bankCharge}
                      </p>
                    </div>
                  }
                </div>
              </ContentSection>
            }
            <Divider top={15} bottom={15} />
            <div className={styles.row}>
              <p>{t('fundOrderDetail.transRef')}</p>
              <p>{transRef}</p>
            </div>
          </section>

          { this.props.transactionDetail.contractNoteReady &&
            <section>
              <Attachment title={t('fundOrderDetail.contractNote')} loading={this.props.isFetchingNote} onClick={this.getFundTransactionContractNote} />
            </section>
          }

          <ContentSection.Footer
            footerRight={<Button theme='primary' onClick={this.onConfirm}>{t('fundOrderDetail.confirm')}</Button>}
          />
        </ContentSection>
      </Modal>
    )
  }
}

FundOrderDetailModal.propTypes = {
  transRef: PropTypes.string.isRequired,
  onClose: PropTypes.func
}
const mapStateToProps = (state, props) => ({
  modalOpenStatus: state.modal.modalOpenStatus,
  isFetching: state.helpers.fetching.getFundTransactionDetail,
  isFetchingNote: state.helpers.fetching.getFundTransactionContractNote,
  transactionDetail: state.fund.fundTransactionDetailDict[props.transRef] || {},
  contractNote: state.fund.fundTransactionContractNoteDict[props.transRef] || {}
})
const mapDispatchToProps = {
  closeModal,
  getFundTransactionDetail,
  getFundTransactionContractNote
}
export default connect(mapStateToProps, mapDispatchToProps)(FundOrderDetailModal)
