import React from 'react'
import PropTypes from 'prop-types'

import t from '~/utils/locales'
import Button from '~/components/Form/Button'
import IconDoc from '~/assets/icons/docIcon.svg'

import styles from './Attachment.scss'

class Attachment extends React.PureComponent {
  render () {
    const { title, loading, onClick } = this.props
    return (
      <div className={styles.link}>
        <div className={styles.left}>
          <div className={styles.title}>{title}</div>
          <div>
            <Button
              className={styles.btn}
              theme='primary'
              loading={loading}
              onClick={onClick}
            >
              {t('view_attachment')}
            </Button>
          </div>
        </div>
        <div
          className={styles.icon}
          onClick={onClick}
        >
          <label>PDF</label>
          <IconDoc />
        </div>
      </div>
    )
  }
}

Attachment.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func
}
export default Attachment
