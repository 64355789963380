import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import reducers from '~/reducers'
import analyticsMiddleware from '~/shared/middlewares/analyticsMiddleware'

const configureStore = (preloadedState = {}, cookies) => {
  const middleware = [thunk.withExtraArgument({ cookies }), analyticsMiddleware]
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
  const enhancers = composeEnhancers(
    applyMiddleware(...middleware)
  )

  const store = createStore(reducers, preloadedState, enhancers)

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('~/reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

export default configureStore
