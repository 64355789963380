import _merge from 'lodash/merge'
import _omit from 'lodash/omit'
import {
  GET_REGULAR_SAVEING_FUNDS,
  UPDATE_REGULAR_SAVEING_FUNDS_SELECTION,
  UPDATE_REGULAR_SAVEING_KEYWORD,
  GET_RSP_CONFIG,
  UPDATE_REGULAR_SAVEING_SELECTED_FUND_AMOUNT
} from '~/actions/types'

function getInitialState () {
  return {
    funds: [],
    selectedFunds: {},
    keyword: '',
    rspConfig: {}
  }
}

const initialState = getInitialState()

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGULAR_SAVEING_FUNDS:
      return {
        ...state,
        funds: action.payload
      }
    case UPDATE_REGULAR_SAVEING_FUNDS_SELECTION:
      return {
        ...state,
        selectedFunds: action.payload.selected ? _merge({}, state.selectedFunds, {
          [action.payload.code]: { ...action.payload }
        }) : _omit(state.selectedFunds, action.payload.code)
      }
    case UPDATE_REGULAR_SAVEING_KEYWORD:
      return {
        ...state,
        keyword: action.keyword
      }
    case GET_RSP_CONFIG:
      return {
        ...state,
        rspConfig: action.data
      }
    case UPDATE_REGULAR_SAVEING_SELECTED_FUND_AMOUNT:
      return {
        ...state,
        selectedFunds: _merge({}, state.selectedFunds, {
          [action.fundCode]: {
            amount: action.amount
          }
        })
      }
    default:
      return state
  }
}
