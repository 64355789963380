import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import _find from 'lodash/find'
import classnames from 'classnames/bind'

import IconDropdownMenu from '~/assets/icons/dropdown-menu.svg'
import IconArrowDown from '~/assets/icons/arrowDown.svg'
import IconArrowBlue from '~/assets/icons/arrowBlue.svg'

import styles from './Dropdown.scss'
const cx = classnames.bind(styles)

class Dropdown extends React.Component {
  static getDerivedStateFromProps (nextProps) {
    return {
      selected: nextProps.value
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      selected: props.value
    }
  }

  toggleDropdown = () => {
    const isOpen = this.state.isOpen
    this.setState({ isOpen: !isOpen })
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  onSelect = (value) => {
    this.setState({ selected: value, isOpen: false })
    this.props.onChange && this.props.onChange(value)
  }

  render () {
    const { isOpen, selected } = this.state
    const { align, options, className, disabled, minimal, blue } = this.props
    const selectedOption = _find(options, { value: selected })

    return (
      <div className={cx('container', className, { show: isOpen, minimal: minimal, blue: blue })}>
        <button
          type='button'
          disabled={disabled}
          className={cx('toggler', { 'active': selectedOption && selectedOption.value !== '' })}
          onClick={this.toggleDropdown}
        >
          {selectedOption ? selectedOption.label : '　'}
          { !minimal && <IconDropdownMenu className={styles.icon} /> }
          { minimal && !blue && <IconArrowDown className={cx('icon', { rotate: isOpen })} /> }
          { blue && <IconArrowBlue className={cx('icon', { rotate: isOpen })} /> }
        </button>
        <div className={cx('dropdown', { [align]: align, hide: disabled })}>
          {
            options.map((opt, idx) =>
              <a
                key={idx}
                className={styles.option}
                onClick={() => this.onSelect(opt.value)}
              >
                { opt.label }
              </a>
            )
          }
        </div>
      </div>
    )
  }
}

Dropdown.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  align: PropTypes.string, // right / left
  minimal: PropTypes.bool,
  blue: PropTypes.bool
}

Dropdown.defaultProps = {
  value: '',
  options: [{ label: '', value: '' }],
  align: 'left'
}

export default onClickOutside(Dropdown)
