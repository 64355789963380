import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { Spinner } from '@/components'

import Link from '~/components/Common/Link'

import styles from './Button.scss'
const cx = classnames.bind(styles)

/**
 * global <a> button component with link or onClick function
 */
const Button = ({
  children,
  className,
  theme,
  variant,
  disabled,
  loading,
  isFullwidth,
  to,
  authCheck,
  onClick
}) => {
  const classNames = cx('button', {
    [className]: className,
    [theme]: theme,
    [variant]: variant,
    fullwidth: isFullwidth
  })
  if (to) {
    return (
      <Link
        to={to}
        className={classNames}
        onClick={onClick ? (e) => { onClick(e) } : null}
        authCheck={to ? authCheck : null}
      >
        {children}
      </Link>
    )
  }

  const _disabled = loading ? true : disabled

  return (
    <button
      className={classNames}
      onClick={onClick ? (e) => { onClick(e) } : null}
      disabled={_disabled}
    >
      {children}
      { loading && <Spinner color='#ccc' size='small' />}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.string, // basic|primary
  variant: PropTypes.string, // lg|sm
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isFullwidth: PropTypes.bool,
  onClick: PropTypes.func,
  authCheck: PropTypes.bool
}

Button.defaultProps = {
  theme: 'basic',
  disabled: false,
  loading: false,
  isFullwidth: false,
  authCheck: true
}

export default Button
