import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import { Switch, withRouter } from '@/router'

import { getLocalePath } from '~/utils/urlUtils'
import t from '~/utils/locales'
import { getDisclaimer } from '~/utils/cookies'

import { getAnonymousToken, getAnnouncement } from '~/actions/user'
import { openModal } from '~/actions/modal'
import { setRedirect, showMessage } from '~/actions/helpers'
import routes, { i18nRoutes, AdvRoute, RedirectRoute } from './routes'
import { getPreferLanguage } from '~/shared/selectors/preferLanguageSelector'
import AppContext from '~/shared/contexts/appContext'
import { pushAAEvent } from '~/actions/analytics'
import { isDev, isStg } from '~/utils/env'
import { COLOR_EGG } from '~/utils/constants'

import NotFound from '~/pages/NotFound'
import TestingIndicator from '~/components/Common/TestingIndicator'

import './styles/main.scss'
import './styles/global/no-script.scss'

class App extends Component {
  static fetchData (dispatch) {
    return dispatch(getAnonymousToken())
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.announcement) {
      this.checkMaintenance(nextProps.announcement)
    }
    // redirect when props.redirect has value
    if (nextProps.redirect) {
      let { redirect } = nextProps
      // redirect has language path
      redirect = getLocalePath(redirect)
      this.props.history.push(redirect)
      this.props.setRedirect(null)
    }
    return true
  }

  componentDidUpdate (prevProps) {
    this.handleRouteChange(prevProps.location.pathname, this.props.location.pathname)

    if (!window.APPIER_RETARGET && !isDev()) {
      const script = document.createElement('script')
      script.src = '//jscdn.appier.net/aa.js?id=ifund.com.hk'
      script.defer = true
      const firstScript = document.getElementsByTagName('script')[0]
      firstScript.parentNode.insertBefore(script, firstScript)
    }

    window.appier_q = window.appier_q || []
    window.appier_q.push(
      window.appier_already_passed ? {} : { 't': 'register', content: { id: 'jYmY', site: 'ifund.com.hk' } },
      { 't': 'pv_track', 'action_id': 'SCIqydICWoKv4Tr', 'track_id': '9whBm54vxahfeLc', 'isCountReload': true, 'counter': 0 },
      { 't': 'pv_track', 'action_id': 'O5LOtc7zP1Ni7DB', 'track_id': '9whBm54vxahfeLc', 'isCountReload': false, 'counter': 1 })
    window.appier_already_passed = true
  }

  async componentDidMount () {
    if (typeof window !== 'undefined') {
      window.addEventListener('offline', () => {
        this.props.showMessage(t('message.offline'))
      })
    }
    await this.props.getAnonymousToken()
    await this.props.getAnnouncement()

    this.lazyLoadGoogleFont()
  }

  lazyLoadGoogleFont () {
    // for english pages, only load Roboto
    // for zh-HK pages, load Roboto and Noto Sans HK
    // for zh-CN pages, load Roboto and Noto Sans SC
    const locale = getPreferLanguage()
    const localeFonts = {
      'zh-HK': 'Noto+Sans+HK:wght@100;300;400;500;700;900',
      'zh-CN': 'Noto+Sans+SC:wght@100;300;400;500;700;900',
      'en': 'Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900'
    }
    const fontFamilies = localeFonts.en + (locale !== 'en' ? `&family=${localeFonts[locale]}` : '')

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamilies}&display=swap`
    document.head.appendChild(link)
  }
  /**
   * route change
   * @param {*} from
   * @param {*} to
   */
  handleRouteChange (from, to) {
    this.showDisclaimer(from, to)
    if (from !== to && typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
  }
  /**
   * show disclaimer every 24 hours
   */
  showDisclaimer (from, to) {
    // /about-us and /app don't show disclaimer
    if (to.search('/about-us') >= 0 || to.search('/app') >= 0) {
      return false
    }

    if (getDisclaimer() || this.props.modalOpenStatus.disclaimer) {
      return false
    }

    this.props.openModal('disclaimer')
    if (from.search('/about-us') >= 0 || from.search('/app') >= 0) {
      this.props.setRedirect(from)
    }
    return true
  }

  checkMaintenance (data) {
    if (
      data.maintenance &&
      location.href.search('maintenance') < 0 &&
      !this.props.cookies.get(COLOR_EGG)) {
      this.props.setRedirect('/maintenance')
    }
  }
  render () {
    const { preferLanguage, featureToggle, profile } = this.props
    return (
      <AppContext.Provider value={{ preferLanguage, featureToggle }}>
        {isStg() && <TestingIndicator />}
        <Switch>
          {routes.map(route => <RedirectRoute {...route} key={route.path} />)}

          {i18nRoutes.map(route => <AdvRoute profile={profile} {...route} key={route.path} />)}

          <AdvRoute component={NotFound} profile={profile} />
        </Switch>
      </AppContext.Provider>
    )
  }
}

App.propTypes = {
  openModal: PropTypes.func.isRequired,
  setRedirect: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  getAnonymousToken: PropTypes.func.isRequired,
  profile: PropTypes.any,
  redirect: PropTypes.any,
  modalOpenStatus: PropTypes.any,
  preferLanguage: PropTypes.string,
  history: PropTypes.any,
  location: PropTypes.any
}

const mapProps = [
  state => ({
    profile: state.user.profile,
    redirect: state.helpers.redirect,
    modalOpenStatus: state.modal.modalOpenStatus,
    announcement: state.user.announcement,
    preferLanguage: getPreferLanguage(state),
    featureToggle: state.featureToggle
  }),
  {
    openModal,
    setRedirect,
    showMessage,
    getAnonymousToken,
    getAnnouncement,
    appierAnalyse: pushAAEvent
  }
]

export default withCookies(withRouter(connect(...mapProps)(App)))
