import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { showMessage } from '~/actions/helpers'

export const DEFAULT_MSG_DISMISS_TIME = 5 * 1000
export default (WrappedComponent) => {
  class WithMessageWrapper extends Component {
    alert = (msg, dismissTime = DEFAULT_MSG_DISMISS_TIME) => {
      this.props.showMessage(msg, 'error', dismissTime)
    }
    info = (msg, dismissTime = DEFAULT_MSG_DISMISS_TIME) => {
      this.props.showMessage(msg, 'info', dismissTime)
    }
    render () {
      const restProps = this.props
      return (
        <React.Fragment>
          <WrappedComponent
            {...restProps}
            alert={this.alert}
            info={this.info}
          />
        </React.Fragment>
      )
    }
  }
  const mapProps = [
    null,
    { showMessage }
  ]

  WithMessageWrapper.propTypes = {
    showMessage: PropTypes.func.isRequired
  }

  return connect(...mapProps)(WithMessageWrapper)
}
