import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from '@/router'

import { closeModal } from '~/actions/modal'

import Modal from '~/components/Layout/Modal'
import LoginFlow from '~/components/Logic/LoginFlow'

class LoginModal extends React.Component {
  onModalClose = () => {
    this.props.closeModal('login')
  }
  render () {
    return (
      <Modal
        isModalOpen={this.props.modalOpenStatus.login}
        onRequestClose={this.onModalClose}
      >
        <LoginFlow />
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  modalOpenStatus: state.modal.modalOpenStatus
})
const mapDispatchToProps = {
  closeModal
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginModal))
