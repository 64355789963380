import {
  SET_GLOBAL_FUND_SEARCH_CODES,
  SET_GLOBAL_SEARCH_PAGINATION,
  SET_GLOBAL_SEARCH_KEYWORD,
  SET_SEARCH_TAB,
  SET_GLOBAL_SEARCH_FUNDS,
  SET_GLOBAL_SEARCH_ARTICLES,
  GET_CSV,
  SET_CSV_TOTAL_PAGE,
  RESET_CSV
} from '~/actions/types'

import { Fund, Search } from '~/utils/api'
import { addFetching, rmFetching } from './helpers'

const PageSize = 20

const config = {
  'fund': {
    setDataActionType: SET_GLOBAL_SEARCH_FUNDS,
    endpoint: 'getFundSearchResult',
    request: Fund.list.get
  },
  'article': {
    setDataActionType: SET_GLOBAL_SEARCH_ARTICLES,
    endpoint: 'getArticleSearchResult',
    request: Search.article.get
  }
}

export const getFundSearchResult = (query) => (dispatch) => {
  dispatch(getSearchResult(query, 'fund'))
}

export const getArticleSearchResult = (query) => (dispatch) => {
  dispatch(getSearchResult(query, 'article'))
}

export const getSearchResult = (query, key) => (dispatch, getState) => {
  query = {
    size: PageSize,
    ...query
  }
  const lang = getState().helpers.lang
  const keyword = query.q
  const page = query.page

  const cacheKey = `${lang}.${keyword}`

  const cachedItem = getState().search[key].pool[cacheKey]
  const cachedData = cachedItem && cachedItem[page]

  if (cachedData) {
    dispatch({
      type: config[key].setDataActionType,
      data: cachedData,
      cache: {}
    })
    dispatch({
      type: SET_GLOBAL_SEARCH_PAGINATION,
      key,
      pagination: cachedItem.pagination
    })
  } else {
    const endpoint = config[key].endpoint
    dispatch(addFetching(endpoint))
    return config[key].request(query)
      .then(({ data, headers }) => {
        const pagination = headers.pagination
        dispatch({
          type: config[key].setDataActionType,
          data,
          cache: { key: cacheKey, value: { pagination, [page]: data } }
        })
        dispatch({
          type: SET_GLOBAL_SEARCH_PAGINATION,
          key,
          pagination
        })
        dispatch(rmFetching(endpoint))
      })
  }
}

export const clearFundCodes = () => dispatch => {
  dispatch({
    type: SET_GLOBAL_FUND_SEARCH_CODES,
    codes: []
  })
  dispatch({
    type: SET_GLOBAL_SEARCH_PAGINATION,
    pagination: {}
  })
}

export const setGlobalSearchKeyword = ({ keyword }) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_SEARCH_KEYWORD,
    keyword
  })
}

export const setSearchTab = (tab) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_TAB,
    tab
  })
}

export const resetCsv = () => (dispatch) => {
  dispatch({
    type: RESET_CSV
  })
}

export const getCsv = (query) => (dispatch, getState) => {
  const endpoint = 'getCsv'
  const size = 100
  const previousCsvData = getState().search.csv
  dispatch(addFetching(endpoint))

  return Search.csv.get({ ...query, size })
    .then(({ data, headers: { pagination } }) => {
      dispatch(rmFetching(endpoint))
      dispatch({
        type: GET_CSV,
        data: data.concat(previousCsvData)
      })
      dispatch({
        type: SET_CSV_TOTAL_PAGE,
        pagination
      })
      return data
    })
}
