import React from 'react'

const withTempalate = (Template) => {
  return (Comp) => {
    class WithTemplate extends React.Component {
      render () {
        return (
          <Template {...this.props}>
            <Comp {...this.props} />
          </Template>
        )
      }
    }

    const templateName = Template.displayName || Template.name || ''

    WithTemplate.displayName = `withTemplate(${templateName})`

    return WithTemplate
  }
}

export default withTempalate
