import React, { Component } from 'react'
import classnames from 'classnames/bind'

import IconHeaderMenuAngle from '~/assets/icons/headerMenuAngle.svg'

import styles from './AngleDropdownBox.scss'
const cx = classnames.bind(styles)

class AngleDropdownBox extends Component {
  render () {
    const { children, className, bodyClassName, show, angleClassName, angleInCenter, angleLeft, angleRight } = this.props
    return (
      <div className={cx('dropdownBox', className, { show })}>
        <div className={cx('body', bodyClassName)}>
          {children}
        </div>
        <IconHeaderMenuAngle
          className={cx('angle', angleClassName, { angleInCenter })}
          style={{
            left: angleLeft,
            right: angleRight
          }}
        />
      </div>
    )
  }
}

export default AngleDropdownBox
