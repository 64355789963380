import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import _map from 'lodash/map'
import _partial from 'lodash/partial'

import { closeModal } from '~/actions/modal'
import { update } from '~/actions/featureToggle'

import Modal from '~/components/Layout/Modal'
import ContentSection from '~/components/Layout/ContentSection'
import Button from '~/components/Form/Button'
import Checkbox from '~/components/Form/Checkbox'

class FeatureToggle extends PureComponent {
  close = () => {
    this.props.closeModal('FeatureToggle')
    return true
  }

  handleCheck = (toggleName, toggleValue) => {
    this.props.updateFeatureToggle({
      [toggleName]: toggleValue
    })
  }

  render () {
    const { featureToggle } = this.props

    return (
      <Modal
        isModalOpen={this.props.modalOpenStatus.FeatureToggle}
        onRequestClose={this.close}
        overlayDismiss
      >
        <ContentSection theme='headernfooter'>
          <ContentSection.Header title={'Feature Toggles'} />
          <div>
            {
              _map(featureToggle, (toggle, toggleName) => {
                return <Checkbox key={toggleName} value={toggle} label={toggleName} onChecked={_partial(this.handleCheck, toggleName)} />
              })
            }
          </div>
          <ContentSection.Footer
            footerRight={<Button theme='primary' onClick={this.close}>Close</Button>}
          />
        </ContentSection>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  modalOpenStatus: state.modal.modalOpenStatus,
  featureToggle: state.featureToggle
})
const mapDispatchToProps = {
  closeModal,
  updateFeatureToggle: update
}
const Connect = connect(mapStateToProps, mapDispatchToProps)(FeatureToggle)
export default Connect
