import React, { Component } from 'react'
import Propypes from 'prop-types'
import { Route, Redirect } from '@/router'

import { withLoginStatus } from '~/hoc'
import { getLocalePath } from '~/utils/urlUtils'

class _AdvRoute extends Component {
  shouldComponentUpdate (nextProps) {
    if (nextProps.isLoginClient() && nextProps.path === this.props.path) {
      return false
    }
    return true
  }

  redirect (path) {
    const { location } = this.props
    const back = location.pathname + location.search
    const to = {
      pathname: getLocalePath(path)
    }
    if (back) {
      to.search = `?back=${encodeURIComponent(back)}`
    }
    return <Redirect to={to} />
  }
  render () {
    const { path, exact, component, authority, isLoginUser, isLoginClient } = this.props
    if (authority) {
      if (!isLoginUser()) {
        return this.redirect('/login')
      }

      if (authority.indexOf('client') > -1 && !isLoginClient()) {
        return this.redirect('/login')
      }
    }

    const Component = component

    return (
      <Route
        path={path}
        exact={exact}
        render={(props) => (
          <Component {...props} />
        )}
      />
    )
  }
}

_AdvRoute.propTypes = {
  location: Propypes.object,
  isLoginClient: Propypes.func.isRequired,
  isLoginUser: Propypes.func.isRequired,
  isClient: Propypes.func.isRequired,
  path: Propypes.string,
  exact: Propypes.any,
  component: Propypes.any,
  isPrivate: Propypes.any,
  only: Propypes.any,
  profile: Propypes.any
}

export const AdvRoute = withLoginStatus(_AdvRoute)
