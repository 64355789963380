import {
  GET_POST,
  GET_POSTS,
  GET_PROCEDURES,
  GET_MISC_CONTENT,
  GET_GETTING_STARTED,
  GET_STATIC_CONTENT,
  GET_FUND_COMPANIES,
  GET_CMS_FUND_COMPANIES,
  GET_CMS_FUND_COMPANY,
  GET_CMS_CATEGORIES,
  UPDATE_CMS_FILTER,
  GET_CASH_TOPUP,
  SET_LIGHTBOX_IMAGES,
  OPEN_LIGHTBOX,
  CLOSE_LIGHTBOX,
  SET_CURATED_THEME,
  GET_CMS_PAGE,
  GET_FAQ,
  GET_DICTIONARY,
  GET_DISCLAIMER,
  GET_SIDE_POSTS
} from '~/actions/types'

const initialState = {
  misc: {},
  posts: {}, // { postId: {}, ..., postId: {} }
  postList: {
    investmentTalk: {
      list: [],
      pagination: { page: 1, isEnd: false }
    },
    fundNotices: {
      list: [],
      pagination: { page: 1, isEnd: false }
    },
    marketInsights: {
      list: [],
      pagination: { page: 1, isEnd: false }
    },
    fundManagerViews: {
      list: [],
      pagination: { page: 1, isEnd: false }
    },
    blogPost: {
      list: [],
      pagination: { page: 1, isEnd: false }
    },
    iClass: {
      list: [],
      pagination: { page: 1, isEnd: false }
    },
    iNews: {
      list: [],
      pagination: { page: 1, isEnd: false }
    }
  },
  cmsPages: {},
  fundCompanies: [],
  fundCompanyCodes: [],
  cmsCompanies: [],
  cmsCompany: {},
  cmsCategories: [],
  cmsFilter: {
  },
  // cmsResources: {
  //   faq: {},
  //   gettingStarted: {},
  //   procedures: {},
  //   importantDisclaimer: {},
  //   clientAgreement: {}
  // }, [TODO]
  procedures: {},
  gettingStarted: {},
  statics: {},
  cashTopup: {},
  ligthboxImages: [{ src: '' }],
  lightboxIsOpen: false,
  curatedTheme: {},
  dictionary: {},
  disclaimer: {},
  sidePosts: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          ...action.post
        }
      }
    case GET_POSTS:
      return {
        ...state,
        postList: {
          ...state.postList,
          [action.postType]: {
            list: action.nextList,
            pagination: { page: action.nextPage, isEnd: action.isEnd }
          }
        }
      }
    // GET_FUND_COMPANIES udpates reducers: fund, content
    case GET_FUND_COMPANIES:
      return {
        ...state,
        fundCompanies: action.fundCompanies,
        fundCompanyCodes: action.fundCompanyCodes
      }
    case GET_CMS_FUND_COMPANIES:
      return {
        ...state,
        cmsCompanies: action.cmsCompanies
      }
    case GET_CMS_FUND_COMPANY:
      return {
        ...state,
        cmsCompany: action.cmsCompanies
      }
    case GET_CMS_CATEGORIES: {
      return {
        ...state,
        cmsCategories: action.cmsCategories
      }
    }
    case UPDATE_CMS_FILTER: {
      return {
        ...state,
        cmsFilter: {
          ...state.cmsFilter,
          ...action.cmsFilter
        }
      }
    }
    case GET_PROCEDURES:
      return {
        ...state,
        procedures: {
          ...state.procedures,
          ...action.procedures
        }
      }
    case GET_DICTIONARY:
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          ...action.dictionary
        }
      }
    case GET_MISC_CONTENT:
      return {
        ...state,
        misc: {
          ...state.misc,
          [action.key]: action.data
        }
      }
    case GET_GETTING_STARTED:
      return {
        ...state,
        gettingStarted: {
          ...state.gettingStarted,
          ...action.gettingStarted
        }
      }
    case GET_STATIC_CONTENT:
      return {
        ...state,
        statics: {
          ...state.statics,
          [action.key]: action.data
        }
      }
    case GET_CASH_TOPUP:
      return {
        ...state,
        cashTopup: action.cashTopup
      }
    case SET_LIGHTBOX_IMAGES:
      return {
        ...state,
        ligthboxImages: action.images
      }
    case OPEN_LIGHTBOX:
      return {
        ...state,
        lightboxIsOpen: true
      }
    case CLOSE_LIGHTBOX:
      return {
        ...state,
        lightboxIsOpen: false
      }
    case SET_CURATED_THEME:
      return {
        ...state,
        curatedTheme: action.cureated
      }
    case GET_CMS_PAGE:
      return {
        ...state,
        cmsPages: {
          ...state.cmsPages,
          [action.slug]: action.content
        }
      }
    case GET_FAQ:
      return {
        ...state,
        faq: {
          ...state.faq,
          [action.slug]: action.data
        }
      }
    case GET_DISCLAIMER:
      return {
        ...state,
        disclaimer: {
          ...state.disclaimer,
          [action.slug]: action.data
        }
      }
    case GET_SIDE_POSTS: {
      return {
        ...state,
        sidePosts: {
          ...state.sidePosts,
          [action.category]: action.data
        }
      }
    }
    default:
      return state
  }
}
