import {
  SET_REDIRECT,
  REDIRECT_TO_LOGIN,
  ADD_FETCHING,
  ADD_TIMEOUT,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  RM_TIMEOUT,
  RM_FETCHING,
  SET_COLUMNS,
  SET_LANG
} from './types'
import { setLanguage } from '~/utils/cookies'
import { DEFAULT_MSG_DISMISS_TIME } from '~/hoc/withMessage'

export const redirectToLogin = (path) => (dispatch) => {
  dispatch({
    type: REDIRECT_TO_LOGIN,
    path
  })
}

export const setRedirect = (path, callback_path) => (dispatch) => {
  if (path && !/^\//.test(path)) {
    path = `/${path}`
  }
  if (callback_path) {
    path = `${path}?callback=${callback_path}`
  }
  dispatch({
    type: SET_REDIRECT,
    path
  })
}

export const addFetching = (endpoint) => (dispatch) => {
  dispatch({
    type: ADD_FETCHING,
    endpoint
  })
}

export const rmFetching = (endpoint) => (dispatch) => {
  dispatch({
    type: RM_FETCHING,
    endpoint
  })
}

export const addTimeout = (endpoint) => (dispatch) => {
  dispatch({
    type: ADD_TIMEOUT,
    endpoint
  })
}

export const rmTimeout = (endpoint) => (dispatch) => {
  dispatch({
    type: RM_TIMEOUT,
    endpoint
  })
}

export const getColumns = (key, defaultColumns) => (dispatch) => {
  let storedColumns = null
  if (typeof window !== 'undefined') {
    storedColumns = JSON.parse(window.localStorage.getItem(key))
  }
  const columns = storedColumns || defaultColumns
  dispatch({
    type: SET_COLUMNS,
    payload: { [key]: columns }
  })
  return columns
}

export const setColumns = (key, columns) => (dispatch) => {
  dispatch({
    type: SET_COLUMNS,
    payload: { [key]: columns }
  })
  window.localStorage.setItem(key, JSON.stringify(columns || []))
}

/**
 * show instant error or info message
 * @param  {String} message
 * @param  {String} [type='error' || 'info']
 * @param  {Number} dismissTime
 */
export const showMessage = (message, theme = 'error', dismissTime = DEFAULT_MSG_DISMISS_TIME) => (dispatch) => {
  dispatch({ type: SHOW_MESSAGE, message, theme, dismissTime })
}

/**
 * dismiss instant message
 */
export const dismissMessage = () => (dispatch) => {
  dispatch({ type: HIDE_MESSAGE })
}

export const setLang = (lang) => (dispatch) => {
  setLanguage(lang)
  dispatch({
    type: SET_LANG,
    lang
  })
}
