import _format from 'date-fns/format'
import _isNumber from 'lodash/isNumber'
import _isNil from 'lodash/isNil'

// import { colors } from '~/styles/vars'
import { isBrowser } from '~/utils/env'

/*
  About Site settings
*/

export const getStockColorSetting = () => {
  return 'greenIsUp' // or 'redIsUp'
}

/*
  About Number
*/

// unit: s
export const formatDate = (timestamp, format = 'YYYY-MM-DD') => {
  const iTimestamp = parseInt(timestamp, 10)
  if (isNaN(iTimestamp)) return timestamp
  return _format(iTimestamp * 1000, format)
}

// num can be 1 / -1
export const fixNum = (num, digits = 2) => {
  if (!_isNumber(num)) return num
  return parseFloat(num).toFixed(digits)
}

// convert 1234567890 to 1,234,567,890
export const numberWithCommas = (num) => {
  if (_isNil(num)) return num
  const [int, decimal] = num.toString().split('.')
  return int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (decimal ? `.${decimal}` : '')
}

/*
  About Color
*/
// export const getUpDownColors = () => (
//   getStockColorSetting() === 'redIisBrowserp'
//     ? { up: colors.persianred, down: colors.oceangreen }
//     : { up: colors.oceangreen, down: colors.persianred }
// )

// export const getColorBySign = (num, fallbackColor = colors.fontColor) => {
//   const upDownColors = getUpDownColors()
//   if (num > 0) {
//     return upDownColors.up
//   } else if (num < 0) {
//     return upDownColors.down
//   }
//   return fallbackColor
// }

/*
  About String / Regexp
*/
export const SPACE_HOLDER = '　' // full shape space

// convert `\n` to `<br />`
export const newline2Br = str => (str || '').replace(/\n+/g, '<br />')

/**
 * openInNewTab when in browser
 * @param  {String} url
 */
export const openInNewTab = (url) => {
  if (!isBrowser() || typeof document === 'undefined') return
  let a = document.createElement('a')
  // win7 IE11 will stop the blank tab
  if (isIE()) {
    window.open(url, '_self')
  } else {
    a.target = '_blank'
    a.href = url
    a.click()
  }
}

export const refreshPage = () => {
  if (!isBrowser()) return
  window.location.reload()
}

/**
 * generate options when pagination is changed to dropdown apperance
 * @param {*} pageCount number
 */
export const getPaginationDropdownOptions = (pageCount) => {
  return Array.from(new Array(pageCount), (val, index) => ({ value: index + 1, label: index + 1 }))
}

/**
 * generate a short unqiue id
 */
export const getUniqueId = () => {
  return Math.random().toString(36).substring(3, 8)
}

export const isIE = () => {
  if (!isBrowser || typeof document === 'undefined') return
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  }
}
