import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { closeModal } from '~/actions/modal'
import t from '~/utils/locales'
import { setDisclaimer } from '~/utils/cookies'

import Modal from '~/components/Layout/Modal'
import ContentSection from '~/components/Layout/ContentSection'
import Button from '~/components/Form/Button'
import Link from '~/components/Common/Link'
import styles from './Disclaimer.scss'

class Disclaimer extends PureComponent {
  disagree = () => {
    this.props.closeModal('disclaimer')
    return true
  }
  agree = () => {
    setDisclaimer()
    this.props.closeModal('disclaimer')
    return true
  }
  render () {
    return (
      <Modal
        isModalOpen={this.props.modalOpenStatus.disclaimer}
        overlayDismiss={false}
      >
        <ContentSection theme='headernfooter' dataTest='disclaimerModal'>
          <ContentSection.Header title={t('disclaimer.title')} />
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('disclaimer.content') }} />
          <ContentSection.Footer
            footerLeft={<Link light to='/about-us' onClick={this.disagree}>{t('disclaimer.disagree')}</Link>}
            footerRight={<Button theme='primary' onClick={this.agree}>{t('disclaimer.agree')}</Button>}
          />
        </ContentSection>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  modalOpenStatus: state.modal.modalOpenStatus
})
const mapDispatchToProps = {
  closeModal
}
const Connect = connect(mapStateToProps, mapDispatchToProps)(Disclaimer)
export default Connect
