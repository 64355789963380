import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import t from '~/utils/locales'
import SwitchToIcon from '~/assets/icons/convertArrow.svg'
import CashExchangeIcon from '~/assets/icons/exchange.svg'
import CashWithdrawIcon from '~/assets/icons/withdraw.svg'

import styles from './Divider.scss'
const cx = classnames.bind(styles)

const Divider = ({
  top,
  bottom,
  height,
  theme
}) => (
  {
    'default':
  <hr style={{ marginTop: `${top}px`, marginBottom: `${bottom}px` }} />,
    'gutter':
  <div
    className={styles.divider}
    style={{ height: `${height}px`, marginTop: `${top}px`, marginBottom: `${bottom}px` }}
  />,
    'switchFund':
  <div
    className={cx('dividerWithText', 'switch')}
    style={{ marginTop: `${top}px`, marginBottom: `${bottom}px` }}
  >
    <span> <SwitchToIcon /> <label>{t('components.divider.switchTo')}</label></span>
  </div>,
    'exchangeCash':
  <div
    className={cx('dividerWithText', 'cash')}
    style={{ marginTop: `${top}px`, marginBottom: `${bottom}px` }}
  >
    <span> <CashExchangeIcon /> <label>{t('components.divider.exchangeTo')}</label></span>
  </div>,
    'withdrawCash':
  <div
    className={cx('dividerWithText', 'cash')}
    style={{ marginTop: `${top}px`, marginBottom: `${bottom}px` }}
  >
    <span> <CashWithdrawIcon /> <label>{t('components.divider.toAccount')}</label></span>
  </div>
  }[theme]
)

Divider.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  height: PropTypes.number,
  theme: PropTypes.oneOf(['default', 'gutter', 'switchFund', 'exchangeCash', 'withdrawCash'])
}
Divider.defaultProps = {
  top: 5,
  bottom: 5,
  height: 5,
  theme: 'default'
}
export default Divider
