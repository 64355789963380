import React from 'react'

import SharedMeta from '../SharedMeta'
import Header from '~/components/Common/Header'
import Footer from '~/components/Common/Footer'
import Page from '~/components/Layout/Page'
import LoginModal from '~/components/Modal/Login'
import DisclaimerModal from '~/components/Modal/Disclaimer'
import Message from '~/components/Common/Message'

const DefaultTemplate = ({ children, history, location }) => {
  return (
    <Page>
      <SharedMeta />
      <Header history={history} location={location} />
      <main>
        {children}
        <div className='placeHolder' />
      </main>
      <Footer />
      <Message />
      <LoginModal />
      <DisclaimerModal />
    </Page>
  )
}

export default DefaultTemplate
