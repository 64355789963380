import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import styles from './Clickable.scss'
const cx = classnames.bind(styles)

/**
 * Clickable component is used as a clickable tag instead of using raw <a> or <button>,
 * aims to abstract a layer for user click tracking with GA, etc.
 * @return <a> or <button>
 */
class Clickable extends React.PureComponent {
  render () {
    const {
      onClick,
      a,
      button,
      light,
      className,
      disabled,
      children
    } = this.props
    const classnames = cx('clickable', className, { light })

    return (
      <Fragment>
        {
          a && !button &&
          <a
            onClick={(e) => { onClick && onClick(e) }}
            className={classnames}
          >
            {children}
          </a>
        }
        {
          button &&
          <button
            disabled={disabled}
            onClick={(e) => { onClick && onClick(e) }}
            className={classnames}
          >
            {children}
          </button>
        }
      </Fragment>
    )
  }
}

Clickable.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  light: PropTypes.bool,
  a: PropTypes.bool,
  button: PropTypes.bool
}
Clickable.defaultProps = {
  a: true,
  button: false
}
export default Clickable
