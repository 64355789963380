import _fetch from './_fetch'
import {
  FUND_TRADING_SERVICE,
  FUND_ACCOUNT_SERVICE,
  CORE_SERVICE,
  GEO_SERVICE,

  USER_TOKEN_KEY,
  CLIENT_TOKEN_KEY,
  ANONYMOUS_TOKEN_KEY
} from '~/utils/constants/api'

const User = {
  Register: {
    post (body, query) { // {"email": "string","password": "string"}
      return _fetch({
        method: 'POST',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users',
        query: { ...query, from: 'web' },
        body
      })
    }
  },
  Notification: {
    get (query) {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/notifications',
        query
      })
    },
    put ({ id, body }) {
      let path = '/users/me/notifications'
      if (id) {
        path = `${path}/${id}`
      }
      return _fetch({
        method: 'PUT',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path,
        body
      })
    }
  },
  Announcement: {
    get (query) {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        // same announcement as app
        path: '/appAnnouncement',
        query
      })
    }
  },
  AnonymousToken: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/tokens/anonymous'
      })
    }
  },
  Tokens: {
    post ({ body, token }) {
      return _fetch({
        method: 'POST',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/tokens',
        query: { type: 'password' }, // could be 'sns' for future sns login
        body,
        token
      })
    }
  },
  TokensRefresh: {
    post (body) {
      return _fetch({
        method: 'POST',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/tokens/refresh',
        query: { type: 'password' }, // could be 'sns' for future sns login
        body
      })
    }
  },
  ClientTokens: {
    post (body, type) {
      return _fetch({
        method: 'POST',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/clientTokens',
        query: { type },
        body
      })
    }
  },
  ClientTokensRefresh: {
    post () {
      return _fetch({
        method: 'POST',
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/clientTokens/refresh',
        body: { device: 'web' }
      })
    }
  },
  Me: {
    get ({ token } = {}) {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me',
        token
      })
    },
    put ({ body }) {
      return _fetch({
        method: 'PUT',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me',
        query: { from: 'web' },
        body
      })
    },
    clientPut ({ body }) {
      return _fetch({
        method: 'PUT',
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me',
        query: { from: 'web' },
        body
      })
    }
  },
  Password: {
    put ({ currentPassword, newPassword }) {
      return _fetch({
        method: 'PUT',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/password',
        body: { currentPassword, newPassword }
      })
    },
    clientPut ({ currentPassword, newPassword }) {
      return _fetch({
        method: 'PUT',
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/password',
        body: { currentPassword, newPassword }
      })
    }
  },
  Profile: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/profile'
      })
    },
    post ({ body, token }) {
      return _fetch({
        method: 'POST',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/profile',
        query: { platform: 'web' },
        body,
        token
      })
    },
    put ({ body, token }) {
      return _fetch({
        method: 'PUT',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/profile',
        body,
        token
      })
    },
    patch ({ body, token }) {
      return _fetch({
        method: 'PATCH',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/profile',
        body,
        token
      })
    }
  },
  Account: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me'
      })
    }
  },
  iPoint: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/users/me/ipoint'
      })
    }
  },
  Nationalities: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/nationalities'
      })
    }
  },
  Countries: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/countries'
      })
    }
  },
  Currencies: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/currencies'
      })
    }
  },
  Occupations: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/occupations'
      })
    }
  },
  Industries: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/industries'
      })
    }
  },
  PendingOrders: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        path: '/fundPendingOrders'
      })
    }
  },
  fundPendingCash: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        path: '/fundPendingCash'
      })
    }
  },
  FundHoldings: {
    get ({ query, params = {} }) {
      return _fetch({
        method: 'GET',
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        path: params.code ? `/fundHoldings/${params.code}` : '/fundHoldings',
        query
      })
    },
    post (body) {
      return _fetch({
        method: 'POST',
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        path: '/fundHoldings',
        body
      })
    }
  },
  FundAccountValueChart: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        path: '/fundAccountValueChart'
      })
    }
  },
  FundAccountInfo: {
    get (query) {
      return _fetch({
        method: 'GET',
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        path: '/fundAccountInfo',
        query
      })
    }
  },
  fundCashBalance: {
    get (query) {
      return _fetch({
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        method: 'GET',
        path: '/fundCashBalance',
        query
      })
    }
  },
  bankAccount: {
    get () {
      return _fetch({
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        method: 'GET',
        path: '/userBankAccount',
        query: { multiple: 'yes' }
      })
    }
  },
  fundCash: {
    post (body) {
      return _fetch({
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        method: 'POST',
        path: '/fundCash',
        body
      })
    }
  },
  fundMonthlyStatements: {
    get ({ query = {}, id = '' }) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'GET',
        path: `/fundMonthlyStatements/${id}`,
        query
      })
    }
  },
  chargeGroup: {
    get () {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'GET',
        path: '/chargeGroup'
      })
    }
  },
  inviteFriends: {
    post (body) { // { alias: '', emails: '' }
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'POST',
        path: '/users/me/inviteFriends',
        body
      })
    }
  },
  assetAllocation: {
    get () {
      return _fetch({
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        method: 'GET',
        path: '/assetAllocation'
      })
    }
  },
  userFundStat: {
    get (query = { fundCode: '' }) {
      return _fetch({
        api: {
          service: FUND_ACCOUNT_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_ACCOUNT_SERVICE.version
        },
        method: 'GET',
        path: '/userFundStat',
        query
      })
    }
  },
  contactUs: {
    post (body) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'POST',
        path: '/contactUs',
        body
      })
    }
  },
  sendEmail: {
    post (type) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'POST',
        path: '/sendEmail',
        body: {
          type
        }
      })
    }
  },
  updateBankInfo: {
    post () {
      return User.sendEmail.post('ifund_change_bank_account')
    }
  },
  uploadImage ({ file, uploadType, token }) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('uploadType', uploadType)
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: USER_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'POST',
      path: '/upload/image',
      body: formData,
      token
    })
  },
  Address: {
    get (query) {
      return _fetch({
        api: {
          service: GEO_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: GEO_SERVICE.version
        },
        method: 'GET',
        path: '/addr',
        query
      })
    }
  },
  Rpq: {
    get (query) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'GET',
        query,
        path: '/users/me/rpq'
      })
    }
  },
  genOTP (body) {
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: ANONYMOUS_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'POST',
      path: '/genOTP',
      body // { type: 'sms, email', action: 'reg, pwd, bind', identifier: 'email address, mobile no' }
    })
  },
  verifyOTP (body) {
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: ANONYMOUS_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'POST',
      path: '/verifyOTP',
      body // { code: '', identifier: 'email address, mobile no' }
    })
  },
  requestPasswordReset (body) {
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: ANONYMOUS_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'POST',
      path: '/users/requestPasswordReset',
      body
    })
  },
  resetPassword (body) { // { newPassword, passwordHashcode, mobile, mobileOTP }
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: ANONYMOUS_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'POST',
      path: '/users/resetPassword',
      body
    })
  },
  requestEmailVerify (body) { // { email }
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: ANONYMOUS_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'POST',
      path: '/users/requestEmailVerify',
      query: { from: 'web' },
      body
    })
  },
  verifyEmail (body) { // { email, hashcode }
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: ANONYMOUS_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'POST',
      path: '/users/verifyEmail',
      body
    })
  },
  Questionnaire: {
    post (body) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'POST',
        path: '/questionnaire',
        body
      })
    }
  },
  favourites: {
    get () {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'GET',
        path: '/users/me/favorites'
      })
    },
    post (code) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'POST',
        path: '/users/me/favorites',
        body: { fundCode: code }
      })
    },
    delete (code) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'DELETE',
        path: '/users/me/favorites',
        body: { fundCode: code }
      })
    }
  },
  getRspConfig () {
    return _fetch({
      api: {
        service: CORE_SERVICE.name,
        tokenType: ANONYMOUS_TOKEN_KEY,
        version: CORE_SERVICE.version
      },
      method: 'GET',
      path: '/rspConfig'
    })
  },
  rsp: {
    get () {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'GET',
        path: '/users/me/rsp'
      })
    },
    post (body) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'POST',
        body,
        path: '/users/me/rsp'
      })
    },
    put (body) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'PUT',
        body,
        path: '/users/me/rsp'
      })
    },
    delete () {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'DELETE',
        path: '/users/me/rsp'
      })
    }
  },
  Maker: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/fi/users'
      })
    }
  },
  All: {
    get () {
      return _fetch({
        method: 'GET',
        api: {
          service: CORE_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        path: '/fi/users'
      })
    }
  }
}

export default User
