/**
 * Color Variables
 * naming convention according to http://chir.ag/projects/name-that-color
 * and keep them lowercase; if duplicated, add number suffix and consider combine them together
 * Design src: https://app.zeplin.io/project/5aa0be18101e01525e3992f2/screen/5aa230b47494f996711b5ecc
 */

const theme2020 = {
  blue: '#3b62a8',
  blue2: '#172f5c',
  blue3: '#6e9df5',
  yellow: '#FFBE19',
  deepYellow: '#B37012'
}

const colors = {
  silver: '#c2c2c2',
  alto2: '#e0e0e0', // name-that-color duplicated
  alabaster: '#f7f7f7',
  mercury: '#e8e8e8',
  gallery: '#eeeeee',
  catskillwhite: '#e9eff4',
  riverbed: '#4e5d6b',
  azureradiance: '#0084fb',

  comparisons: [
    '#4a7ce0',
    '#e8812f',
    '#e8c12f',
    '#da627c',
    '#6c4cbd'
  ],

  dropdownTriggerShadow: '0 1px 2px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(50, 50, 93, 0.08), 0 0 0 1px rgba(50, 50, 93, 0.1)',
  dropdownContentShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.08), 0 15px 35px 0 rgba(50, 50, 93, 0.1), 0 0 1px 0 rgba(136, 152, 170, 0.1)',
  inputShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
  inputActiveShadow: `0 1px 2px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(50, 50, 93, 0.08), 0 3px 9px 0 rgba(50, 50, 93, 0.08), 0 2px 5px 0 rgba(50, 50, 93, 0.05), 0 0 0 1px ${theme2020.blue};`,
  messageShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.24)',

  // new color --------
  blue: theme2020.blue,
  blue2: theme2020.blue2,
  blue3: theme2020.blue3,
  yellow: theme2020.yellow,
  deepYellow: theme2020.deepYellow,

  primaryColor: theme2020.blue, // main
  mariner: theme2020.blue2, // activeMain
  royalblue: '#4a7ce0',
  selago: '#edf4fd',
  titanwhite: '#e4e9ff',
  oceangreen: '#64b82d',
  a11ygreen: '#488320', // darken oceangreen to pass Ally contrast
  grannyapple: '#e8faec',
  mantis: '#5dbe4f',
  persianred: '#d33232',
  cranberry: '#da627c',
  careyspink: '#d8a3ac',
  linen: '#faeee8',
  linen2: '#fcf0ea',
  citrinewhite: '#fbf7db',
  sunglow: theme2020.yellow,
  transIn: '#85b3f0',
  transOut: '#5dbe4f',
  zest: '#e8812f',
  midgray: '#606166', // fontColor
  mineshaft: '#333333', // heavyFontColor
  nobel: '#b3b3b3',
  dustygray: '#9b9b9b',
  alto: '#d8d8d8',
  alto3: '#ddd',
  athensgray: '#f6f6f8',
  athensgray2: '#f5f6f8', // name-that-color duplicated
  athensgray3: '#e7eaef', // border
  concrete: '#f2f2f2',
  tundora: '#454545',
  spunpearl: '#abacb4',
  whitelilac: '#f8f9fd', // bg
  dustygray2: '#999999',
  geyser: '#dae1e7', // border
  iron: '#d7d7d9', // disabled border
  linkwater: '#e2e6f7', // datepicker default hover
  grey: '#9e9e9e',
  lighterGrey: '#fafafa',
  // semantic naming when cannot refer to color name
  loadingBg: 'rgba(255, 255, 255, .5)',
  orange: '#f60'
}

module.exports = colors
