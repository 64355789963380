import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import cookies from '~/cookies'

import { setLang } from '~/actions/helpers'
import configureStore from './configureStore'
import { getPreferLanguage } from '~/utils/preferLanguageUtils'
import { isBrowser } from '~/utils/env'

let initState = {}

if (isBrowser() && !_isEmpty(_get(window, '__INIT_STATE__'))) {
  initState = window.__INIT_STATE__
  delete window.__INIT_STATE__
}

const store = configureStore(initState, cookies)

if (isBrowser()) {
  const lang = getPreferLanguage()
  store.dispatch(setLang(lang))
}

export default store
