import React from 'react'
import { connect } from 'react-redux'

import { openModal } from '~/actions/modal'
import t from '~/utils/locales'
import { getAnnouncement, setAnnouncement } from '~/utils/cookies'
import Notification from '~/components/Common/Notification'

import AnnouncementModal from '~/components/Modal/Announcement'

import styles from './Announcement.scss'

class Header extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (nextProps.announcement) {
      const { notice, maintenance } = nextProps.announcement
      if (!maintenance && !getAnnouncement() && notice) {
        this.showNotice(nextProps.announcement)
      }
    }
    return true
  }

  showNotice = (data) => {
    if (data) {
      Notification.single({
        content: this._renderTitle(data),
        onClose: () => {
          setAnnouncement(data.endTime)
        }
      })
    }
  }

  _renderTitle (data) {
    return (
      <div className={styles.announcementTitle}>
        <span>{data.title}</span>
        <span className={styles.viewDetail} onClick={this._openContentModal}>{t('announcement.viewDetail')}</span>
      </div>
    )
  }

  _openContentModal = () => {
    this.props.openModal('announcement')
  }

  render () {
    const { announcement } = this.props
    return announcement ? <AnnouncementModal announcement={announcement} /> : null
  }
}

const mapToProps = [
  (state) => ({
    announcement: state.user.announcement
  }),
  {
    openModal
  }
]
export default connect(...mapToProps)(Header)
