import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import _includes from 'lodash/includes'
import _filter from 'lodash/filter'
import _cloneDeep from 'lodash/cloneDeep'

import { showMessage } from '~/actions/helpers'
import { getFundList } from '~/actions/fund'

import t from '~/utils/locales'

import {
  GET_PENDING_ORDERS,
  GET_FUND_HOLDINGS,
  GET_FUND_HOLDING,
  GET_FUND_ACCOUNT_VALUE_CHART,
  GET_FUND_ACCOUNT_INFO,
  GET_FUND_CASH_BALANCE,
  GET_IPOINT,
  GET_FUND_MONTHLY_STATEMENTS,
  GET_CHARGE_GROUP,
  GET_FUND_PENDING_CASH,
  GET_ASSET_ALLOCATION,
  GET_USER_FUND_STAT,
  GET_USER_BANK_ACCOUNT,
  SET_PERFORMANCE_VISIBILITY,
  GET_PROFILE,
  CLEAR_OPEN_ACCOUNT_FORM_DATA,
  SAVE_OTP,
  GET_ME,
  GET_RPQ,
  SET_FAVOURITES,
  SET_BACTH_FUNDS_QUEUE,
  GET_ANNOUNCEMENT,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_PAGINATION,
  SET_NOTIFICATIONS_QUERY,
  SET_UNREAD_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATIONS_COUNT,
  SET_NOTIFICATION_AS_READ,
  SET_NOTIFICATIONS_ALL_READ,
  GET_RSP,
  GET_MAKER,
  GET_ALLUSER,
  GET_CLIENTS
} from './types'

import { addApiError, rmApiError } from './errorsv2'
import { addFetching, rmFetching } from './helpers'
import { getApiErrMsg, getApiErrMsgByField } from '~/utils/net'
import { User } from '~/utils/api'
import {
  setUserToken,
  setClientToken,
  setIsClient,
  rmAllCookies,
  getClientTokenObj,
  setAnonymousToken
} from '~/actions/cookies'

/**
 * @param  {Object} opts
 * @param  {String} [opts.device='web']
 * @param  {String} opts.email
 * @param  {String} opts.mobile
 * @param  {String} opts.password
 * @param  {String} opts.snsAccessToken - facebook token
 * @return {Object} Promise
 */
export const loginUser = ({ device = 'web', type, email, mobile, password, snsAccessToken }) => (dispatch) => {
  const endpoint = 'loginUser'

  // init erros and fetching status
  dispatch(rmApiError(endpoint))
  dispatch(addFetching(endpoint))
  dispatch({
    type: CLEAR_OPEN_ACCOUNT_FORM_DATA
  })
  // request user token
  const body = _omitBy({ device, email, mobile, password, snsAccessToken }, _isEmpty)
  return User.Tokens.post({ body })
    .then(({ data }) => {
      dispatch(setUserToken(data))
      dispatch(rmFetching(endpoint))
      // request me to check if client
      return dispatch(getMe())
    })
    .catch(e => {
      dispatch(addApiError(endpoint, getApiErrMsgByField(e, type)))
      dispatch(rmFetching(endpoint))
    })
}

export const loginClient = ({ type, otp, device = 'web' }) => (dispatch) => {
  const endpoint = 'loginClient'

  dispatch(rmApiError(endpoint))
  dispatch(addFetching(endpoint))

  const body = { device }
  if (type === 'mobileOTP') {
    body.mobileOTP = otp
  } else {
    body.emailOTP = otp
  }
  return User.ClientTokens.post(body, type).then(({ data }) => {
    dispatch(setClientToken(data))
    dispatch(rmFetching(endpoint))
    return dispatch(getProfile())
  }).catch(e => {
    dispatch(addApiError(endpoint, getApiErrMsg(e)))
    dispatch(rmFetching(endpoint))
  })
}

export const logout = () => (dispatch, getState) => {
  if (window.confirm(t('userPanel.confirmLogout'))) {
    dispatch(rmAllCookies())
    dispatch({
      type: CLEAR_OPEN_ACCOUNT_FORM_DATA
    })
    dispatch({
      type: SET_FAVOURITES,
      favourites: []
    })
    window.localStorage.clear()
  }
}

export const register = ({ password, email, mobile, mobileOTP, invitationCode }) => (dispatch) => {
  const endpoint = 'register'
  dispatch(addFetching(endpoint))

  const registerData = { password }
  let query = {}
  if (email) {
    registerData.email = email
  } else if (mobile) {
    query.type = 'mobile'
    registerData.mobile = mobile
    registerData.mobileOTP = mobileOTP
  }
  if (invitationCode) {
    registerData.invitationCode = invitationCode
  }
  dispatch(rmApiError(endpoint))
  return User.Register.post(registerData, query)
    .then(() => {
      dispatch(setIsClient(false))
      delete registerData.mobileOTP
      const loginData = {
        device: 'web',
        ...registerData
      }
      dispatch(rmFetching(endpoint))
      return dispatch(loginUser(loginData))
    })
    .catch(e => {
      dispatch(rmFetching(endpoint))
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

export const getAnonymousToken = () => (dispatch) => {
  return User.AnonymousToken.get().then(({ data }) => {
    dispatch(setAnonymousToken(data))
    return Promise.resolve()
  })
}

// get /users/me
export const getMe = () => (dispatch) => {
  const endpoint = 'getMe'
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))
  return User.Me.get().then(({ data }) => {
    const isClient = !!_get(data, 'clientCode', false)
    dispatch(setIsClient(isClient))
    dispatch({
      type: GET_ME,
      me: data
    })
    return dispatch(rmFetching(endpoint))
  }).catch(e => {
    dispatch(addApiError(endpoint, getApiErrMsg(e)))
    dispatch(rmFetching(endpoint))
  })
}

/**
 * updateMe with PUT
 * if user is Client and need udpate email or mobile
 * should use User.Me.clientPut with clientToken
 * @param  {Object} body
 * @return {@Promise}
 */
export const updateMe = (body, isClient = false) => (dispatch) => {
  const endpoint = 'updateMe'
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))

  const apiAction = isClient ? User.Me.clientPut : User.Me.put
  return apiAction({ body: _omitBy(body, _isEmpty) }).then(({ data }) => {
    dispatch({
      type: GET_ME,
      me: data
    })
  }).catch(e => {
    dispatch(addApiError(endpoint, getApiErrMsg(e)))
  }).then(() => {
    dispatch(rmFetching(endpoint))
  })
}

/**
 * getProfile
 * get user's full profile
 */
export const getProfile = () => (dispatch) => {
  const endpoint = 'getProfile'
  dispatch(rmApiError(endpoint))
  dispatch(addFetching(endpoint))
  return User.Profile.get()
    .then(({ data: profile }) => {
      const isClient = !!_get(profile, 'clientCode', false)
      dispatch(setIsClient(isClient))
      dispatch({ type: GET_PROFILE, profile })
      dispatch(rmFetching(endpoint))
      return profile
    })
    .catch(e => {
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

/**
 * updateProfile with Put
 * @param  {Object} body
 */
export const updateProfile = (body) => (dispatch) => {
  const endpoint = 'updateProfile'
  dispatch(rmApiError(endpoint))
  dispatch(addFetching(endpoint))

  return User.Profile.put({ body })
    .then(({ data: profile }) => {
      dispatch({ type: GET_PROFILE, profile })
      dispatch(rmFetching(endpoint))
    })
    .catch(e => {
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

/**
 * updateProfile with Put
 * @param  {Object} body
 */
export const patchProfile = (body) => (dispatch) => {
  const endpoint = 'patchProfile'
  dispatch(rmApiError(endpoint))
  dispatch(addFetching(endpoint))

  return User.Profile.patch({ body })
    .then(({ data: profile }) => {
      dispatch({ type: GET_PROFILE, profile })
      dispatch(rmFetching(endpoint))
    })
    .catch(e => {
      dispatch(rmFetching(endpoint))
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

/**
 * getFundAccountInfo
 * @param  {Object} [query={}] { currency: currency || 'HKD' }
 */
export const getFundAccountInfo = (query = {}) => (dispatch) => {
  const endpoint = 'getFundAccountInfo'
  dispatch(addFetching(endpoint))

  User.FundAccountInfo.get(query)
    .then(({ data: accountInfo }) => {
      dispatch({
        type: GET_FUND_ACCOUNT_INFO,
        info: {
          [query.currency]: accountInfo[0] // e.g. HKD: {}
        }
      })
      dispatch(rmFetching(endpoint))
    })
}

export const getFundAccountValueChart = () => (dispatch) => {
  User.FundAccountValueChart.get()
    .then(({ data: chartData }) => {
      dispatch({
        type: GET_FUND_ACCOUNT_VALUE_CHART,
        chartData: chartData[0]
      })
    })
}

/**
 * getPendingOrders
 * get user's pending fund orders
 */
export const getPendingOrders = () => (dispatch) => {
  const endpoint = 'getPendingOrders'
  dispatch(addFetching(endpoint))

  User.PendingOrders.get()
    .then(({ data: orders }) => {
      dispatch({
        type: GET_PENDING_ORDERS,
        pendingOrders: _get(orders, '0.fundPendingOrderItems', [])
      })
      dispatch(rmFetching(endpoint))
    })
}

/**
 * getPendingCash
 * get user's pending cash orders
 */
export const getPendingCash = () => (dispatch) => {
  const endpoint = 'getPendingCash'
  dispatch(addFetching(endpoint))

  return User.fundPendingCash.get()
    .then(({ data }) => {
      dispatch(rmFetching(endpoint))
      const cash = []
      data.map(item => {
        item.fundPendingCashItems.map(val => {
          val['clientCode'] = item.clientCode
          cash.push(val)
        })
      })
      dispatch({
        type: GET_FUND_PENDING_CASH,
        items: _orderBy(cash, ['transDate'], ['desc'])
      })
    })
}

export const getFundHoldings = (query) => (dispatch) => {
  const type = _get(query, 'type') || ''
  const endpoint = `getFundHoldings${type}`
  dispatch(addFetching(endpoint))
  return User.FundHoldings.get({ query })
    .then(({ data: fundHoldings }) => {
      const fundHoldingItems = _get(fundHoldings, '0.fundHoldingItems') || []
      const rspHoldingItems = _get(fundHoldings, '0.rspHoldingItems') || []

      dispatch({
        type: GET_FUND_HOLDINGS,
        holdingType: '',
        fundHoldings: fundHoldingItems
      })
      dispatch({
        type: GET_FUND_HOLDINGS,
        holdingType: 'RSP',
        fundHoldings: rspHoldingItems
      })
      dispatch(rmFetching(endpoint))
      return fundHoldingItems.map((item) => item.fund.fundCode)
    })
}

export const getFundHolding = (code, type) => (dispatch) => {
  const endpoint = 'getFundHolding'
  dispatch(addFetching(endpoint))
  User.FundHoldings.get({ params: { code, type } })
    .then(({ data }) => {
      if (type === 'RSP') {
        dispatch({
          type: GET_FUND_HOLDING,
          holdingType: type || '',
          fundHolding: _get(data, '[0].rspHoldingItem')
        })
      } else {
        dispatch({
          type: GET_FUND_HOLDING,
          holdingType: type || '',
          fundHolding: _get(data, '[0].fundHoldingItem')
        })
      }
      dispatch(rmFetching(endpoint))
    })
}

export const getMarketValueConverted = ({ fundCode, currency }) => () => {
  return User.FundHoldings.get({ currency })
    .then(({ data: fundHoldings }) => {
      const fundHoldingItems = _get(fundHoldings, '0.fundHoldingItems')
      let result = null
      for (let i = 0, l = fundHoldingItems.length; i < l; i++) {
        const item = fundHoldingItems[i]
        if (item && item.fund.fundCode === fundCode) {
          result = item
          break
        }
      }
      return {
        marketValueConverted: result.marketValueConverted,
        marketValueConvertedCurrency: result.marketValueConvertedCurrency
      }
    })
}

/**
 * getFundCashBalance
 * get client's cash balance array
 */
export const getFundCashBalance = (code) => (dispatch) => {
  const endpoint = 'getFundCashBalance'
  dispatch(addFetching(endpoint))

  return User.fundCashBalance.get({ clientCode: code || '' })
    .then(({ data: balances }) => {
      dispatch({
        type: GET_FUND_CASH_BALANCE,
        fundCashBalance: balances[0]
      })
      dispatch(rmFetching(endpoint))
    })
}

/**
 * getIPoint
 */
export const getIPoint = () => (dispatch) => {
  const endpoint = 'getIPoint'
  dispatch(addFetching(endpoint))

  User.iPoint.get()
    .then(({ data: iPoint }) => {
      dispatch({
        type: GET_IPOINT,
        iPoint
      })
      dispatch(rmFetching(endpoint))
    })
}

export const getChargeGroup = () => (dispatch) => {
  const endpoint = 'getChargeGroup'
  dispatch(addFetching(endpoint))
  User.chargeGroup.get()
    .then(({ data: chargeGroup }) => {
      dispatch({
        type: GET_CHARGE_GROUP,
        chargeGroup
      })
      dispatch(rmFetching(endpoint))
    })
}

/**
 * inviteFriends
 * @param  {[type]} body [description]
 *  alias: this.state.nickname,
 *  emails: this.state.emails.toString()
 * @return {[type]}      [description]
 */
export const inviteFriends = (body) => (dispatch) => {
  const endpoint = 'inviteFriends'
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))

  return User.inviteFriends.post(body)
    .then(() => {
      dispatch(rmFetching(endpoint))
    })
    .catch(e => {
      dispatch(rmFetching(endpoint))
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

/**
 * getAssetAllocation for CapitalAnalysis
 */
export const getAssetAllocation = () => (dispatch) => {
  const endpoint = 'getAssetAllocation'
  dispatch(addFetching(endpoint))

  User.assetAllocation.get()
    .then(({ data }) => {
      data.geoFocus = _orderBy(data.geoFocus, ['allocation'], ['desc'])
      data.investedClass = _orderBy(data.investedClass, ['allocation'], ['desc'])
      dispatch({
        type: GET_ASSET_ALLOCATION,
        data
      })
      dispatch(rmFetching(endpoint))
    })
}

export const getUserFundStat = (code) => (dispatch) => {
  User.userFundStat.get({ fundCode: code })
    .then(({ data: userFundStat }) => {
      dispatch({
        type: GET_USER_FUND_STAT,
        code,
        userFundStat
      })
    })
}

export const postFeedback = (data) => () => {
  return User.contactUs.post(data)
}

export const sendupdateBankInfo = () => () => {
  return User.updateBankInfo.post()
}

// otp: object
// { mobileOTP: '', emailOTP: '' }
export const saveOTP = otp => dispatch => {
  dispatch({ type: SAVE_OTP, otp })
}

// type: [ sms, email ]
// action: [ reg, pwd, bind ]
// identifier: string, example: example@email.com / +85212345678
export const genOTP = ({ type, action, identifier }) => (dispatch) => {
  const endpoint = 'genOTP'
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))
  return User.genOTP({ type, action, identifier })
    .catch(e => {
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
      // if (e.status === 400) {
      //   dispatch(addApiError(endpoint, getApiErrMsg(e)))
      // } else {
      //   dispatch(addApiError(endpoint, getApiErrMsg(e)))
      // }
      dispatch(rmFetching(endpoint))
      throw e
    })
}

// code: string, The 6-digit code post by user: 123456
// identifier: string, example: example@email.com / +85212345678
export const verifyOTP = ({ code, identifier }) => () => {
  return User.verifyOTP({ code, identifier })
}

/**
 * updatePassword with Put
 * if user is Client and need udpate password
 * should use User.Password.clientPut with clientToken
 * @param  {String} currentPassword
 * @param  {String} newPassword
 * @param  {Bool}   isClient
 */
export const updatePassword = ({ currentPassword, newPassword }, isClient = false) => dispatch => {
  const endpoint = 'updatePassword'
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))

  const apiAction = isClient ? User.Password.clientPut : User.Password.put
  return apiAction({
    currentPassword,
    newPassword
  }).catch(e => {
    dispatch(addApiError(endpoint, getApiErrMsg(e)))
  }).then(() => {
    dispatch(rmFetching(endpoint))
  })
}

export const resetPassword = ({ newPassword, passwordHashcode, mobile, mobileOTP }) => dispatch => {
  const endpoint = 'resetPassword'
  let data = { newPassword }
  if (mobileOTP && mobile) {
    data = {
      ...data,
      mobile,
      mobileOTP
    }
  } else if (passwordHashcode) {
    data = {
      ...data,
      passwordHashcode
    }
  }
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))
  return User.resetPassword(data)
    .then(() => {
      dispatch(rmFetching(endpoint))
    })
    .catch((e) => {
      dispatch(rmFetching(endpoint))
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

export const requestPasswordReset = ({ email }) => dispatch => {
  const endpoint = 'requestPasswordReset'
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))
  return User.requestPasswordReset({ email })
    .then(() => {
      dispatch(rmFetching(endpoint))
      dispatch(rmApiError(endpoint))
    })
    .catch(e => {
      dispatch(rmFetching(endpoint))
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

/**
 * getUserBankAccounts
 */
export const getUserBankAccounts = () => (dispatch) => {
  const endpoint = 'getUserBankAccounts'
  dispatch(addFetching(endpoint))

  return User.bankAccount.get()
    .then(({ data }) => {
      dispatch({
        type: GET_USER_BANK_ACCOUNT,
        userBankAccounts: data
      })
      dispatch(rmFetching(endpoint))
      return data
    })
}

/**
 * getFundMonthlyStatements
 * @param  {Number} [months=12] default get recent 12 months
 */
export const getFundMonthlyStatements = (months = 12) => (dispatch) => {
  const endpoint = 'getFundMonthlyStatements'
  dispatch(addFetching(endpoint))

  return User.fundMonthlyStatements.get({ query: { months } })
    .then(({ data: fundMonthlyStatements }) => {
      dispatch({
        type: GET_FUND_MONTHLY_STATEMENTS,
        fundMonthlyStatements
      })
      dispatch(rmFetching(endpoint))
    })
}

/**
 * getFundMonthlyStatementById
 * @param  {String} id [description]
 */
export const getFundMonthlyStatementById = (id) => () => {
  return User.fundMonthlyStatements.get({ id })
    .then(({ data }) => {
      return data
    })
}

/**
 * postQuestionnaire post questionnaire data to backend
 * @param  {[type]} body [description]
 */
export const postQuestionnaire = (body) => (dispatch) => {
  const endpoint = 'postQuestionnaire'
  dispatch(addFetching(endpoint))

  return User.Questionnaire.post(body)
    .then(() => {
      dispatch(rmFetching(endpoint))
    })
}

/**
 * getFavourites
 * @param  {String} code fundCode only for changing fetching endpoint
 */
export const getFavourites = (code) => dispatch => {
  const endpoint = `getFavourites${code || ''}`
  dispatch(addFetching(endpoint))

  return User.favourites.get()
    .then(({ data: favourites }) => {
      dispatch({
        type: SET_FAVOURITES,
        favourites
      })
      dispatch(rmFetching(endpoint))
      return favourites
    })
}
export const addFavourite = (code) => dispatch => {
  const endpoint = `addFavourite${code}`
  dispatch(addFetching(endpoint))

  return User.favourites.post(code)
    .then(({ data: favourites }) => {
      dispatch({
        type: SET_FAVOURITES,
        favourites
      })
      dispatch(rmFetching(endpoint))
      return favourites
    })
    .catch(e => {
      console.error(e) /* eslint-disable-line no-console */
    })
}
export const rmFavourite = (code) => dispatch => {
  const endpoint = `rmFavourite${code}`
  dispatch(addFetching(endpoint))

  return User.favourites.delete(code)
    .then(({ data: favourites }) => {
      dispatch({
        type: SET_FAVOURITES,
        favourites
      })
      dispatch(rmFetching(endpoint))
      return favourites
    })
    .catch(e => {
      console.log(e) /* eslint-disable-line no-console */
    })
}

export const setPerformanceVisibility = (visibility) => dispatch => {
  dispatch({
    type: SET_PERFORMANCE_VISIBILITY,
    visibility
  })
}

export const uploadImage = ({ file, uploadType, token }) => () => {
  return User.uploadImage({ file, uploadType, token })
}

export const verifyEmail = ({ email, hashcode }) => () => {
  return User.verifyEmail({ email, hashcode })
}

/**
 * sendVerificationEmail: send or resend verify email
 * @param  {String} email
 */
export const sendVerificationEmail = ({ email }) => (dispatch) => {
  const endpoint = 'sendVerificationEmail'
  dispatch(addFetching(endpoint))
  dispatch(rmApiError(endpoint))
  return User.requestEmailVerify({ email })
    .then(() => {
      dispatch(rmFetching(endpoint))
    })
    .catch(e => {
      dispatch(rmFetching(endpoint))
      return dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

export const updateHoldingGainLoss = ({ fundCode, targetGain, stopLoss }) => (dispatch) => {
  const endpoint = 'updateHoldingGainLoss'
  dispatch(addFetching(endpoint))
  return User.FundHoldings.post({
    operations: [{
      fundCode,
      targetGain,
      stopLoss
    }]
  }).then(({ data }) => {
    dispatch(rmFetching(endpoint))
    return dispatch({
      type: GET_FUND_HOLDINGS,
      fundHoldings: data[0].fundHoldingItems
    })
  }).catch(() => {
    dispatch(rmFetching(endpoint))
  })
}

/**
 * postCashExchange
 * @param  {String} fromCurrency
 * @param  {Number} amount
 * @param  {String} toCurrency
 */
export const postCashExchange = ({ fromCurrency, amount, toCurrency }) => dispatch => {
  const endpoint = 'postCashExchange'
  dispatch(addFetching(endpoint))
  const operations = [{
    orderType: 'exchange',
    fromCurrency,
    amount,
    toCurrency
  }]

  return User.fundCash.post({ operations })
    .then(({ data }) => {
      dispatch(rmFetching(endpoint))
      dispatch(rmApiError(endpoint))
      return data
    })
    .catch(e => {
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

/**
 * postCashWithdraw
 * @param  {String} fromCurrency
 * @param  {Number} amount
 */
export const postCashWithdraw = ({ fromCurrency, amount, toBank }) => dispatch => {
  const endpoint = 'postCashWithdraw'
  dispatch(addFetching(endpoint))
  const operations = [{
    orderType: 'withdraw',
    fromCurrency,
    amount,
    toBank
  }]

  return User.fundCash.post({ operations })
    .then(({ data }) => {
      dispatch(rmFetching(endpoint))
      return data
    })
    .catch(e => {
      dispatch(addApiError(endpoint, getApiErrMsg(e)))
    })
}

/**
 * addBatchFund add fundcode into store for further bulk buy or sell
 * @param {String} fundCode
 */
export const addBatchFund = (fundCode) => (dispatch, getState) => {
  const { batchFundsQueue } = getState().user
  if (_includes(batchFundsQueue, fundCode)) return true
  const _queue = _cloneDeep(batchFundsQueue)
  _queue.push(fundCode)
  dispatch({
    type: SET_BACTH_FUNDS_QUEUE,
    batchFundsQueue: _queue
  })
}

/**
 * rmBatchFund rm fundcode from store
 * @param  {String} fundCode
 */
export const rmBatchFund = (fundCode) => (dispatch, getState) => {
  const { batchFundsQueue } = getState().user
  if (!_includes(batchFundsQueue, fundCode)) return true
  const _queue = _filter(batchFundsQueue, code => code !== fundCode)
  dispatch({
    type: SET_BACTH_FUNDS_QUEUE,
    batchFundsQueue: _queue
  })
}

/**
 * cleanBatchFund
 */
export const cleanBatchFund = () => (dispatch) => {
  dispatch({
    type: SET_BACTH_FUNDS_QUEUE,
    batchFundsQueue: []
  })
}

/**
 * sendUpdateBankInfo send udpate bank info PDF to user's email
 */
export const sendUpdateBankInfo = () => () => {
  return User.updateBankInfo.post()
}

/**
 * autoRefreshClientTokenIfNeeded:
 * help user auto refresh client token when last serveral mins not idle
 * logic:
 * in period of [CLIENT_TOKEN_TO_REFRESH_IN, CLIENT_TOKEN_EXPIRES] and [user active]
 * e.g.:
 * CLIENT_TOKEN_EXPIRES: 1531291200
 * CLIENT_TOKEN_TO_REFRESH_IN: 1080s
 * CLIENT_TOKEN_EXPIRES_IN: 1200s
 * if user active in [1531291080, 1531291200] then help user to refresh client token
 */
export const autoRefreshClientTokenIfNeeded = () => dispatch => {
  const { clientToken, clientTokenExpires, clientTokenExpiresIn, clientTokenToRefreshIn } = dispatch(getClientTokenObj())
  const totalRefreshTimeSection = clientTokenExpiresIn - clientTokenToRefreshIn
  const clientTokenStartRefreshAt = clientTokenExpires - totalRefreshTimeSection

  const now = Math.floor((new Date()).getTime() / 1000)
  const needRefresh = now > clientTokenStartRefreshAt && now < parseInt(clientTokenExpires, 10)

  if (clientToken && needRefresh) {
    User.ClientTokensRefresh.post().then(({ data }) => {
      dispatch(setClientToken(data))
    }).catch(e => console.error(e)) /* eslint-disable-line no-console */
  } else {
  }
}

export const getAnnouncement = () => (dispatch) => {
  // version paramerter is arbitrary
  return User.Announcement.get({ platform: 'web', version: '0.0.1' })
    .then(({ data }) => {
      const createdAt = _get(data, 'createdAt')
      const startTime = _get(data, 'startTime')
      const endTime = _get(data, 'endTime')
      const now = Date.now() / 1000

      let announcement = null
      const notice = now >= createdAt && now <= endTime
      const maintenance = now >= startTime && now <= endTime
      if (notice || maintenance) {
        announcement = { ...data, notice, maintenance, content: data.content.replace(/\n/g, '<br/>') }
      }

      dispatch({
        type: GET_ANNOUNCEMENT,
        announcement
      })
      return announcement
    })
}

export const getNotifications = () => (dispatch, getState) => {
  const query = getState().user.notification.query
  const endpoint = 'getNotifications'
  dispatch(addFetching(endpoint))
  return User.Notification.get(query)
    .then(({ headers, data }) => {
      dispatch(rmFetching(endpoint))
      dispatch({
        type: SET_NOTIFICATIONS,
        items: data
      })

      dispatch({
        type: SET_NOTIFICATIONS_PAGINATION,
        pagination: headers.pagination
      })
    })
}

export const setNotificationsQuery = (query) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATIONS_QUERY,
    query
  })
  return dispatch(getNotifications())
}

export const getDropdownNotifications = () => (dispatch) => {
  return Promise.all([User.Notification.get({ status: 'unread' }), User.Notification.get()])
    .then(responses => {
      dispatch({
        type: SET_UNREAD_NOTIFICATIONS_COUNT,
        unreadCount: responses[0].headers.pagination.totalCount
      })
      dispatch({
        type: SET_UNREAD_NOTIFICATIONS,
        dropdownItems: responses[1].data
      })
    })
}

export const setNotificationAsReadById = (id) => (dispatch) => {
  return User.Notification.put({ id, body: { status: 'read' } })
    .then(() => {
      dispatch({
        type: SET_NOTIFICATION_AS_READ,
        id: id
      })
    })
}

export const setNotificationItemsAsRead = (items) => (dispatch) => {
  if (items.length === 0) {
    dispatch(showMessage(t('notifications.empty'), 'info'))
  } else {
    const idList = _filter(items, item => item.status === 'unread').map(item => item.id)

    if (idList.length === 0) {
      dispatch(showMessage(t('notifications.marked'), 'info'))
    } else {
      return User.Notification.put({ id: idList.join(','), body: { status: 'read' } })
        .then(() => {
          dispatch({
            type: SET_NOTIFICATIONS_ALL_READ,
            idList
          })
          dispatch(showMessage(t('notifications.marked'), 'info'))
        })
    }
  }
}

export const getRpq = () => (dispatch) => {
  const endpoint = 'getRpq'
  dispatch(addFetching(endpoint))
  return User.Rpq.get()
    .then(({ data }) => {
      dispatch(rmFetching(endpoint))
      dispatch({
        type: GET_RPQ,
        rpq: data
      })
    })
}

export const getRsp = () => (dispatch) => {
  const name = 'getUserRsp'

  dispatch(addFetching(name))
  return User.rsp.get().then(({ data }) => {
    dispatch(rmFetching(name))
    dispatch({
      type: GET_RSP,
      rsp: data
    })
    if (data.rsp.length) {
      const fundCodes = []
      data.rsp.map(item => fundCodes.push(item.fundCode))
      dispatch(getFundList({ fundCodes }))
    }
  })
}

export const deleteRsp = () => async (dispatch) => {
  const name = 'deleteUserRsp'

  dispatch(addFetching(name))
  try {
    await User.rsp.delete()
    return true
  } catch (err) {
    dispatch(showMessage(getApiErrMsg(err), 'error'))
    return false
  } finally {
    dispatch(rmFetching(name))
  }
}

export const getMakers = () => (dispatch) => {
  const name = 'getMakers'

  dispatch(addFetching(name))
  return User.Maker.get().then(({ data }) => {
    const makers = _filter(data, (item) => {
      return ['maker', 'admin'].indexOf(item.role) > -1
    })
    dispatch(rmFetching(name))
    dispatch({
      type: GET_MAKER,
      makers
    })
  })
}

export const getAllUser = () => (dispatch) => {
  const name = 'getAllUser'

  dispatch(addFetching(name))
  return User.All.get().then(({ data }) => {
    dispatch(rmFetching(name))
    dispatch({
      type: GET_ALLUSER,
      all: data
    })
  })
}

export const getClients = () => (dispatch) => {
  const name = 'getClients'

  dispatch(addFetching(getClients))

  return new Promise((resolve, reject) => {
    const data = []

    for (let i = 0; i < 100; i++) {
      data.push({
        clientCode: `i8xxxxx${i + 1}`,
        name: `Mr. Client ${i + 1}`,
        contractNo: `Contract No. ${i + 1}`,
        email: 'xxxxxxxxxxx@gmail.com'
      })
    }

    dispatch(rmFetching(name))
    dispatch({
      type: GET_CLIENTS,
      clients: data
    })

    resolve(data)
  })
}
