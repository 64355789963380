/**
 * DOWNLOAD_HOST: url host for static files
 * future maybe change to '//downloads.ifund.com.hk'
 * now keep the same with webview
 */
import { isProd } from '~/utils/env'

export const DOWNLOAD_HOST = isProd() ? 'https://webview.ifund.com.hk' : 'http://webview-stg.ifund.com.hk'

export const FUND_CPFC_PERIODS = ['cpFc1m', 'cpFc3m', 'cpFc6m', 'cpFc1y', 'cpFc3y', 'cpFc5y']

// all 15 currencies supported for fund info & comparison
export const SUPPORTED_CURRENCIES = ['HKD', 'RMB', 'USD', 'GBP', 'AUD', 'CAD', 'CHF', 'EUR', 'JPY', 'NZD', 'SEK', 'SGD', 'MOP', 'TWD', 'VND']
// for cash exchange, etc
// original from https://lion-swagger.firebaseapp.com/ifund-account #/fundCash
// [ AUD, CAD, CHF, EUR, GBP, HKD, NZD, RMB, SGD, USD, JPY ]
// adjust HKD RMB USD to top
export const CASH_OPERATION_CURRENCIES = ['HKD', 'RMB', 'USD', 'JPY', 'SGD', 'CAD', 'AUD', 'EUR', 'CHF', 'GBP', 'NZD']
// for account info API, backend only support the following ones
export const ACCOUNT_INFO_CURRENCIES = ['EUR', 'GBP', 'HKD', 'USD', 'RMB', 'JPY']

export const COOKIE_LANGUAGE_KEY = 'LANGUAGE'

export const COOKIE_DISCLAIMER_KEY = 'DISCLAIMER'

export const COOKIE_ANNOUNCEMENT_KEY = 'ANNOUNCEMENT'

export const ME_API_LANGS = ['zh', 'zh-CN', 'en']

// cookie keys of upDownColor and default currency
export const UP_DOWN_COLOR_KEY = 'UP_DOWN_COLOR'
export const DEFAULT_CURRENCY_KEY = 'DEFAULT_CURRENCY'

// localstorage key of opt count down
export const OTP_COUNT_DOWN_KEY = 'OTP_COUNT_DOWN'

// TODO
export const OPEN_CLIENT_AGREEMENT_TC_PDF = `${DOWNLOAD_HOST}/client-agreement-tc-pdf`
export const OPEN_CLIENT_AGREEMENT_EN_PDF = `${DOWNLOAD_HOST}/client-agreement-en-pdf`

export const IFUND_FEE_SC_PDF = `${DOWNLOAD_HOST}/ifund-fee-sc-pdf`
export const IFUND_FEE_TC_PDF = `${DOWNLOAD_HOST}/ifund-fee-tc-pdf`
export const IFUND_FEE_EN_PDF = `${DOWNLOAD_HOST}/ifund-fee-en-pdf`

export const IFUND_PAYMENNT_INSTRUCTION_PDF = `${DOWNLOAD_HOST}/payment-instruction-pdf`

export const IPOINT_EN_PDF = `${DOWNLOAD_HOST}/ipoint-en-pdf`
export const IPOINT_TC_PDF = `${DOWNLOAD_HOST}/ipoint-tc-pdf`

export const UPDATE_BANK_INFO_PDF = `${DOWNLOAD_HOST}/update-bank-pdf`

export const HOME_BANNER_JSON_FILE_NAME = 'webBanners.json'
export const CURATED_JSON_FILE_NAME = 'curated.json'
// export const FUND_BANNR_FILE_NAME = 'coverBanners.json'
export const INVESTMENT_ANSLYSIS_FILE_NAME = 'investmentAnalysis.json'

// App Links
export const IOS_APP_LINK = 'https://itunes.apple.com/app/id1295548235'
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.ifund.android'
export const ANDROID_APK_LINK = 'https://content.ifund.com.hk/files/app/ifund-android.apk'

export const GLOBAL_SEARCH_INPUT_DEBOUNCE_TIME = 300
// global search and mobile search result page size
export const GLOBAL_SEARCH_PAGE_SIZE = 5
// max file upload size
export const MAX_FILE_UPLOAD_SIZE = 1024 * 1024
export const INVESTMENT_ANALYSIS_RESULT = 'INVESTMENT_ANALYSIS_RESULT'

export const COLOR_EGG = 'COLOR_EGG'
