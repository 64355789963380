import featureToggleConfig from '~/shared/configs/featureToggle'
import { UPDATE_FEATURE_TOGGLE } from '~/actions/types'

export default function featureToggle (state = featureToggleConfig, action) {
  switch (action.type) {
    case UPDATE_FEATURE_TOGGLE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
