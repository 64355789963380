/* eslint no-multi-spaces: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import t from '~/utils/locales'

import styles from './TransTypeLabel.scss'
const cx = classnames.bind(styles)

class TransTypeLabel extends React.PureComponent {
  /**
   * RSP is a virtual type, which comes from isRsp in transaction record
   */
  getTranTypeLabel = transType => ({
    B: t('transType.buy'),              // 買入 / Buy
    S: t('transType.sell'),             // 賣出 / Sell,
    X: t('transType.switch'),           // 轉換 / Switch
    XB: t('transType.switchBuy'),       // 轉換買入 / Switch Buy,
    XS: t('transType.switchSell'),      // 轉換賣出 / Switch Sell,
    I: t('transType.transIn'),          // 轉入 / Transfer In,
    O: t('transType.transOut'),         // 轉出 / Transfer Out
    exchange: t('transType.exchange'),  // 现金兌換
    withdraw: t('transType.withdraw'),  // 现金提款
    RSP: t('transType.RSP')             // 月供申购
  }[transType])

  getBgColor = transType => ({
    B: 'buy',
    S: 'sell',
    X: 'switchBuy',
    XB: 'switchBuy',
    XS: 'switchSell',
    I: 'transIn', // for future transfer
    O: 'transOut',
    exchange: 'exchange',
    withdraw: 'withdraw',
    RSP: 'buy'
  }[transType])

  render () {
    const { transType, className } = this.props

    return (
      transType
        ? (
          <label className={cx('type', `${this.getBgColor(transType)}`, className)}>
            {this.getTranTypeLabel(transType)}
          </label>
        ) : null
    )
  }
}

TransTypeLabel.propTypes = {
  transType: PropTypes.oneOf(['B', 'S', 'XB', 'XS', 'I', 'O', 'exchange', 'withdraw']).isRequired,
  className: PropTypes.string
}
TransTypeLabel.defaultProps = {
  isSwitch: false
}
export default TransTypeLabel
