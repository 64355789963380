import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import IconSearch from '~/assets/icons/search.svg'
import styles from './SearchInput.scss'

const cx = classnames.bind(styles)

class SearchInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      focused: false,
      value: props.value
    }
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.value !== this.props.value && nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value })
    }
    return true
  }

  onChange = (e) => {
    const value = e.target.value
    if (this.props.numberOnly && isNaN(value)) {
      // @TODO
    } else {
      this.setState({ value })
    }
  }

  onBlur = () => {
    this.setState({ focused: false })
    this.props.onBlur && this.props.onBlur(this.state.value)
  }

  onFocus = () => {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus(this.state.value, this.inputDom)
  }

  onInput = (e) => {
    const { onInput } = this.props
    onInput && onInput(e.target.value)
  }

  onKeyDown = (e) => {
    const { onEnter } = this.props
    if (e.keyCode === 13 && this.state.value !== '') {
      e.preventDefault()
      this.inputDom.blur()
      onEnter && onEnter(this.state.value)
    }
  }

  render () {
    const { value, focused } = this.state
    const { placeholder, disabled, maxLength, type, className } = this.props

    const inputClass = cx({
      searchInputWrapper: true,
      focused: focused,
      [className]: className
    })

    return (
      <div className={inputClass}>
        <IconSearch className={styles.icon} />
        <input
          ref={el => { this.inputDom = el }}
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          onChange={this.onChange}
          onInput={this.onInput}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onKeyDown={e => this.onKeyDown(e)}
          autoComplete='off'
        />
      </div>
    )
  }
}

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  numberOnly: PropTypes.bool
}
SearchInput.defaultProps = {
  type: 'text'
}

export default SearchInput
