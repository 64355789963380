import classnames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'

import TransactionEmptyIcon from '~/assets/icons/transactionEmpty.svg'
import FundFilterEmptyIcon from '~/assets/icons/fundFilterEmpty.svg'
import FavouritesEmptyIcon from '~/assets/icons/favouriteEmpty.svg'
import CompareEmptyIcon from '~/assets/icons/compareEmpty.svg'
import SearchEmptyIcon from '~/assets/icons/searchEmpty.svg'
import DocEmptyIcon from '~/assets/icons/docEmpty.svg'
import NotificationEmptyIcon from '~/assets/icons/notificationEmpty.svg'

import Button from '~/components/Form/Button'

import styles from './EmptyIcon.scss'
const cx = classnames.bind(styles)

const EmptyIcon = ({
  transaction,
  filters,
  favourites,
  comparison,
  search,
  doc,
  notification,
  withText,
  withButton,

  height,
  text,
  buttonLabel,
  buttonOnClick,
  children,
  className
}) => (
  <div
    style={{ height: `${height}px` }}
    className={cx('emtpy', className)}
    data-test='emptyIcon'
  >
    { transaction && <TransactionEmptyIcon /> }
    { filters && <FundFilterEmptyIcon /> }
    { favourites && <FavouritesEmptyIcon /> }
    { comparison && <CompareEmptyIcon /> }
    { search && <SearchEmptyIcon /> }
    { doc && <DocEmptyIcon /> }
    {notification && <NotificationEmptyIcon /> }

    { withText && <p className={styles.text}>{text}</p> }
    { withButton && <Button className={styles.button} theme='primary' variant='sm' onClick={buttonOnClick}>{buttonLabel}</Button> }
    {children}
  </div>
)
EmptyIcon.propTypes = {
  transaction: PropTypes.bool,
  filters: PropTypes.bool,
  favourites: PropTypes.bool,
  comparison: PropTypes.bool,
  doc: PropTypes.bool,
  withText: PropTypes.bool,
  withButton: PropTypes.bool,

  height: PropTypes.number,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
}
EmptyIcon.defaultProps = {
  height: 200,
  buttonOnClick: () => {}
}
export default EmptyIcon
