import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withLoginStatus } from '~/hoc'
import { formatDate } from '~/utils/num'
import { type } from '~/utils/constants/notifications'
import { setNotificationAsReadById } from '~/actions/user'
import { setRedirect } from '~/actions/helpers'
import { openModal } from '~/actions/modal'

import FundOrderDetailModal from '~/components/Modal/FundOrderDetail'

import styles from './NotificationList.scss'

class NotificationList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      transRef: ''
    }
  }

  openFundOrderDetailModal = (item) => {
    this.setState({ transRef: item.value }, () => {
      this.props.openModal('fundOrderDetail')
      this.setNotificationAsReadById(item)
    })
  }

  redirectTo = (item, url) => {
    this.props.setRedirect(url)
    this.setNotificationAsReadById(item)
  }

  setNotificationAsReadById = (item) => {
    if (item.status === 'unread') {
      this.props.setNotificationAsReadById(item.id)
    }
  }

  onItemClick = (item) => {
    const isClient = this.props.isClient()

    switch (item.type) {
      case 'buy':
      case 'sell':
      case 'switchBuy':
      case 'switchSell':
      case 'rspBuy':
        this.props.loginClientCallback(() => {
          this.openFundOrderDetailModal(item)
        })
        break
      case 'addValue':
      case 'exchange':
      case 'withdraw':
        this.props.loginClientCallback(() => {
          this.redirectTo(item, '/account/cashtx/history')
        })
        break
      case 'account':
        this.redirectTo(item, isClient ? '/user/setting' : '/open-account')
        break
      case 'post':
        this.redirectTo(item, `/post/${item.value}`)
        break
      default:
        this.setNotificationAsReadById(item)
        break
    }
  }

  clearTransRef = () => {
    this.setState({ transRef: '' })
  }

  render () {
    const { items } = this.props
    const { transRef } = this.state
    return (
      <React.Fragment>
        <ul className={styles.list}>
          {items.map((item) => {
            return (
              <li key={item.id} className={styles.item} onClick={() => this.onItemClick(item)}>
                <div className={styles.content}>{item.content}</div>
                <div className={styles.desc}>
                  <div className={styles.type}>{type[item.type]}</div>
                  <div className={styles.date}>{formatDate(item.createdAt, 'YYYY-MM-DD hh:mm')}</div>
                </div>
                {(item.status === 'unread') && <div className={styles.unread} />}
              </li>
            )
          })}
        </ul>
        {transRef && <FundOrderDetailModal transRef={transRef} onClose={this.clearTransRef} />}
      </React.Fragment>
    )
  }
}

NotificationList.props = {
  items: PropTypes.array

}

const mapStateToProps = (state) => ({
  notifications: state.user.notification.items
})
const mapDispatchToProps = {
  setNotificationAsReadById,
  setRedirect,
  openModal
}

const Connect = withLoginStatus(connect(mapStateToProps, mapDispatchToProps)(NotificationList))

export default Connect
