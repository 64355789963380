import _merge from 'lodash/merge'

import {
  SET_GLOBAL_FUND_SEARCH_CODES,
  SET_GLOBAL_SEARCH_PAGINATION,
  SET_GLOBAL_SEARCH_KEYWORD,
  SET_SEARCH_TAB,
  SET_GLOBAL_SEARCH_FUNDS,
  SET_GLOBAL_SEARCH_ARTICLES,
  GET_CSV,
  SET_CSV_TOTAL_PAGE,
  RESET_CSV
} from '~/actions/types'

const initialState = {
  tab: 'fund',
  fund: {
    codes: [],
    items: [],
    pagination: {},
    pool: {}
  },
  article: {
    items: [],
    pagination: {},
    pool: {}
  },
  csv: [],
  csvPagination: {},
  globalSearchKeyword: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_FUND_SEARCH_CODES:
      return {
        ...state,
        fund: {
          ...state.fund,
          codes: {
            ...state.fund.codes,
            ...action.payload
          }
        }
      }
    case SET_GLOBAL_SEARCH_PAGINATION:
      return {
        ...state,
        [action.key]: { ...state[action.key], pagination: action.pagination }
      }
    case SET_GLOBAL_SEARCH_KEYWORD:
      return {
        ...state,
        globalSearchKeyword: action.keyword
      }
    case SET_SEARCH_TAB: {
      return {
        ...state,
        tab: action.tab
      }
    }
    case SET_GLOBAL_SEARCH_FUNDS: {
      const { cache, data } = action
      let pool = _merge({}, state.fund.pool, { [cache.key]: cache.value })
      return {
        ...state,
        fund: {
          ...state.fund,
          items: data,
          pool
        }
      }
    }
    case SET_GLOBAL_SEARCH_ARTICLES: {
      const { cache, data } = action
      let pool = _merge({}, state.article.pool, { [cache.key]: cache.value })
      return {
        ...state,
        article: {
          ...state.article,
          items: data,
          pool
        }
      }
    }
    case GET_CSV: {
      const { data } = action
      return {
        ...state,
        csv: data
      }
    }
    case SET_CSV_TOTAL_PAGE: {
      return {
        ...state,
        csvPagination: action.pagination
      }
    }
    case RESET_CSV: {
      return {
        ...state,
        csv: [],
        csvPagination: {}
      }
    }
    default:
      return state
  }
}
