import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import styles from './NoticeLabel.scss'
const cx = classnames.bind(styles)

const NoticeLabel = ({
  theme,
  content,
  show,
  id,
  className,
  children
}) => {
  return (
    show
      ? (
        <div className={cx('label', theme, { [className]: className })} id={id} data-role={theme}>
          {content || children}
        </div>
      )
      : null
  )
}

NoticeLabel.defaultProps = {
  theme: 'error',
  show: true
}
NoticeLabel.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(['error', 'info', 'warning']),
  content: PropTypes.string,
  show: PropTypes.bool,
  id: PropTypes.string
}

export default NoticeLabel
