import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'

import { getColorBySign } from '~/utils/style'

class FormattedNum extends PureComponent {
  format = () => {
    const { num, prefix, suffix, digits, padEnd, commas, percent, showPlus } = this.props
    const floatNum = parseFloat(num)
    let _num = floatNum
    if (digits) {
      _num = _num.toFixed(digits)
    }
    if (!padEnd) {
      _num = _num.toString().replace(/0+$/, '').replace(/\.$/, '')
    }
    if (commas) {
      const [int, decimal] = _num.toString().split('.')
      const intWithCommas = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      _num = intWithCommas + (decimal ? `.${decimal}` : '')
    }
    if (percent) {
      _num = `${_num}%`
    }
    if (showPlus && floatNum > 0) {
      _num = `+${_num}`
    }
    if (prefix) {
      _num = `${prefix}${_num}`
    }
    if (suffix) {
      _num = `${_num}${suffix}`
    }
    return _num
  }
  render () {
    const { num, color, fallback } = this.props
    if (isNaN(parseFloat(num)) || _isNil(num) || num === '') {
      return <span>{fallback}</span>
    }
    const formattedNum = this.format()
    let style = {}
    if (color) {
      style = {
        color: getColorBySign({ num: parseFloat(num), useA11y: true })
      }
    }
    if (!_isEmpty(style)) {
      return <span style={style}>{formattedNum}</span>
    }
    return <Fragment>{formattedNum}</Fragment>
  }
}

FormattedNum.propTypes = {
  num: PropTypes.node,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  color: PropTypes.bool,
  commas: PropTypes.bool,
  digits: PropTypes.number,
  padEnd: PropTypes.bool, // will not append 0 while equal to false
  percent: PropTypes.bool, // will append % while equal to true
  fallback: PropTypes.string, // empty fallback string
  showPlus: PropTypes.bool
}

FormattedNum.defaultProps = {
  digits: 2,
  padEnd: true,
  fallback: '-'
}

export default FormattedNum
