import _fetch from './_fetch'
import {
  SEARCH_SERVICE,
  ANONYMOUS_TOKEN_KEY,
  CLIENT_TOKEN_KEY
} from '~/utils/constants/api'

const Search = {
  article: {
    get (query = {}) {
      return _fetch({
        api: {
          service: SEARCH_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: SEARCH_SERVICE.version
        },
        method: 'GET',
        path: '/contents',
        query
      })
    }
  },
  csv: {
    get (query = {}) {
      return _fetch({
        api: {
          service: SEARCH_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: SEARCH_SERVICE.version
        },
        method: 'GET',
        path: '/audit',
        query
      })
    }
  }
}

export default Search
