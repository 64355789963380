import React, { isValidElement, Fragment } from 'react'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import _find from 'lodash/find'

import { getPreferLanguage } from '~/utils/preferLanguageUtils'
import cn from './CN.json'
import hk from './HK.json'
import en from './EN.json'

export const supportedLanguages = ['zh-hk', 'zh-cn', 'en']

export const langPathRegExp = new RegExp(`^/(${supportedLanguages.join('|')})/`)

const dictionaryMap = {
  'zh-CN': cn,
  'zh-HK': hk,
  'en': en
}

// t('Hi %s !', 'Kei') => 'Hi Kei !'
// TODO: refactor the i18n args to object
const t = (key, ...args) => {
  const locale = getPreferLanguage()
  args = args || []
  let value = _get(dictionaryMap[locale], key, key)
  // without args
  if (!args.length) {
    return value
  }
  let hasNodeArgs = !!_find(args, arg => isValidElement(arg))
  if (!hasNodeArgs) {
    if (args[0] && typeof args[0] === 'object') {
      let obj = args[0]
      Object.keys(obj).forEach(key => {
        value = value.replace(`{${key}}`, obj[key])
      })
    } else {
      // with string args only
      args.forEach(arg => {
        value = value.replace('%s', _isNil(arg) ? '-' : arg)
      })
    }

    return value
  } else {
    // with node args
    value = value.split('%s').map((text, idx) => {
      if (args[idx] && isValidElement(args[idx])) {
        return <Fragment key={idx}>{text}{args[idx]}</Fragment>
      } else if (args[idx]) {
        return `${text}${args[idx]}`
      }
      return text
    })
    return <Fragment>{value}</Fragment>
  }
}

// return site title
export const getSiteTitle = (extra) => {
  extra = _isNil(extra) ? '' : ` - ${extra}`
  return `${t('title')}${extra}`
}

export default t
