import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from '@/router'
import _isEmpty from 'lodash/isEmpty'

import t from '~/utils/locales'
import { withLoginStatus } from '~/hoc'
import { qs } from '~/utils/net'
import { getMe, getProfile, loginClient, logout } from '~/actions/user'
import { setRedirect } from '~/actions/helpers'
import { closeModal } from '~/actions/modal'

import ExtraFormControl from '~/components/Layout/ExtraFormControl'
import TextInput from '~/components/Form/TextInput'
import Button from '~/components/Form/Button'
import Clickable from '~/components/Form/Clickable'
import ContentSection from '~/components/Layout/ContentSection'
import NoticeLabel from '~/components/Form/NoticeLabel'
import LoginOtpSender from '../LoginOtpSender'

class LoginClient extends React.Component {
  state = {
    isOtpSent: false,
    type: '',
    otp: '',
    showErr: false
  }

  componentDidMount () {
    const { isLoginUser, me, getMe, profile, getProfile } = this.props
    if (isLoginUser()) {
      if (_isEmpty(me)) {
        getMe()
      }
      if (_isEmpty(profile)) {
        getProfile()
      }
    }
  }

  onChangeType = (type) => {
    this.setState({
      type: type === 'email' ? 'emailOTP' : 'mobileOTP'
    })
  }

  onSendOTP = () => {
    this.setState({
      isOtpSent: true
    })
  }

  onUpdateOtp = (otp) => {
    this.setState({ otp })
  }

  loginClient = () => {
    this.setState({ showErr: true })
    const { type, otp } = this.state
    if (!otp) {
      return false
    }
    this.props.loginClient({
      type,
      otp
    }).then(() => {
      if (!this.props.loginClientApiErr) {
        if (this.props.isLoginModalOpen) {
          this.props.closeModal('login')
          this.props.openLoginCallback && this.props.openLoginCallback()
        } else {
          const { search } = this.props.location
          const { back } = qs.parse(search)
          this.props.setRedirect(back || '/account')
        }
      }
    })
  }

  cancel = () => {
    const { isLoginModalOpen, closeModal, logout, onLogout } = this.props
    if (isLoginModalOpen) {
      closeModal('login')
    } else {
      logout()
      onLogout && onLogout()
    }
  }

  render () {
    const { otp, showErr, isOtpSent } = this.state
    const { loginClientApiErr, isLoginModalOpen } = this.props
    return (
      <ContentSection theme='headernfooter'>
        <ContentSection.Header
          title={t('otp.title')}
        />

        <LoginOtpSender onChangeType={this.onChangeType} onSendOTP={this.onSendOTP} />

        <ExtraFormControl label={t('otp.otp')}>
          <TextInput
            value={otp}
            focus={isOtpSent}
            onInput={this.onUpdateOtp}
            placeholder={t('otp.inputOTP')}
            onEnter={() => { this.loginClient() }}
          />
          <NoticeLabel show={showErr && !otp} content={t('errors.inputOTP')} /> <br />
          <NoticeLabel show={showErr && !!loginClientApiErr} content={loginClientApiErr} />
        </ExtraFormControl>

        <NoticeLabel show theme='info' content={t('otp.smsOnly')} />

        <ContentSection.Footer
          footerLeft={<Clickable light onClick={this.cancel}>{isLoginModalOpen ? t('login.cancel') : t('login.logout')}</Clickable>}
          footerRight={
            <Button theme='primary' loading={this.props.isLogining} onClick={this.loginClient}>
              {t('otp.submit')}
            </Button>
          }
        />
      </ContentSection>
    )
  }
}

const mapStateToProps = state => ({
  me: state.user.me,
  profile: state.user.profile,
  isLogining: state.helpers.fetching.loginClient,
  isLoginModalOpen: state.modal.modalOpenStatus.login,
  loginClientApiErr: state.errorsv2.api.loginClient,
  openLoginCallback: state.modal.callbacks.login.open
})
const mapDispatchToProps = {
  loginClient,
  logout,
  closeModal,
  setRedirect,
  getMe,
  getProfile
}
LoginClient.propTypes = {
  onLogout: PropTypes.func
}
export default withLoginStatus(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginClient)))
