import _merge from 'lodash/merge'
import {
  ADD_API_ERROR,
  RM_API_ERROR,
  ADD_FORM_ERROR,
  RM_FORM_ERROR
  // ,
  // CLEAR_FORM_ERROR
} from '~/actions/types'

const initialState = {
  // for server api return
  api: {
    loginUser: ''
  },
  // for front end pages
  form: {
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_API_ERROR:
      return _merge({}, state, {
        api: {
          [action.endpoint]: action.msg
        }
      })
    case RM_API_ERROR:
      return _merge({}, state, {
        api: {
          [action.endpoint]: ''
        }
      })
    case ADD_FORM_ERROR:
      return _merge({}, state, {
        form: {
          [action.scope]: {
            [action.name]: true
          }
        }
      })
    case RM_FORM_ERROR:
      return _merge({}, state, {
        form: {
          [action.scope]: {
            [action.name]: false
          }
        }
      })
    // case CLEAR_FORM_ERROR:
    //   return _merge({}, state, {
    //     form: {
    //       [scope]: {}
    //     }
    //   })
    default:
      return state
  }
}
