import React, { Component } from 'react'
import { withRouter } from '@/router'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import classnames from 'classnames/bind'
import _isEmpty from 'lodash/isEmpty'
import _debounce from 'lodash/debounce'
import { connect } from 'react-redux'

import { searchFundsByKeyword } from '~/actions/fund'
import { setGlobalSearchKeyword } from '~/actions/search'
import { searchResultFundsSelector } from '~/reducers/selector'
import t from '~/utils/locales'
import { getLanguagePath } from '~/utils/urlUtils'
import { openModal } from '~/actions/modal'
import { GLOBAL_SEARCH_INPUT_DEBOUNCE_TIME, GLOBAL_SEARCH_PAGE_SIZE } from '~/utils/constants'

import SearchInput from '~/components/Form/SearchInput'
import EmptyIcon from '~/components/Layout/Empty/EmptyIcon'
import SearchResultList from './SearchResultList'

import styles from './GlobalSearch.scss'
const cx = classnames.bind(styles)

class GlobalSearch extends Component {
  state = {
    keyword: '',
    entered: false,
    showResult: false
  }

  handleClickOutside = () => {
    this.setState({ showResult: false })
  }

  handleKeywordClick = () => {
    this.setState({ showResult: false })
  }

  // keyword should be set immediately, due to the key enter event.
  handleSearchInput = (keyword) => {
    this.setState({ keyword, entered: false, showResult: !!keyword })
    this.searchFundByKeyword()
  }

  handleSearchEnter = () => {
    const { onEnter, history, tab } = this.props
    const { keyword } = this.state
    if (onEnter) {
      onEnter()
      this.setState({ showResult: false })
    }
    if (keyword) {
      this.setState({ entered: true })
      history.push(`${getLanguagePath()}/search/${tab}/${keyword}`)
    }
  }

  handleSearchReset = () => {
    this.setState({ keyword: '', entered: false, showResult: false })
    this.props.setGlobalSearchKeyword({ keyword: '' })
  }

  handleSearchFocus = (val, input) => {
    if (!this.props.ifLogin) {
      input.blur()
      this.props.openModal('login')
    }
  }

  toggleToolBox = () => {
    this.setState(prevState => {
      prevState.showResult = !prevState.showResult
      return prevState
    })
  }

  /**
   * debounce for sending request only.
   * if input keyword and entered quickly, request should not be sent.
   */
  searchFundByKeyword = _debounce(() => {
    const { keyword, entered } = this.state
    if (keyword && !entered) {
      this.props.searchFundsByKeyword({ q: keyword, size: GLOBAL_SEARCH_PAGE_SIZE })
    }
  }, GLOBAL_SEARCH_INPUT_DEBOUNCE_TIME)

  render () {
    const { isFetching, funds, initialKeyword } = this.props
    const { keyword, showResult } = this.state

    return (
      <div className={styles.container} data-test='globalSearch'>
        <SearchInput
          value={initialKeyword || keyword}
          onInput={this.handleSearchInput}
          onEnter={this.handleSearchEnter}
          onFocus={this.handleSearchFocus}
          placeholder={t('search.placeholder')}
        />

        <div className={cx('box', { show: showResult })}>
          {
            _isEmpty(funds)
              ? (
                <React.Fragment>
                  <EmptyIcon
                    text={t('search.emptyFund')}
                    buttonOnClick={this.handleSearchReset}
                    buttonLabel={t('search.reset')}
                    height={300}
                    withText
                    withButton
                    search
                  />
                  { isFetching && <div className={styles.loading} /> }
                </React.Fragment>
              )
              : (
                <React.Fragment>
                  <SearchResultList funds={funds} keyword={keyword} onKeywordClick={this.handleKeywordClick} />
                  { isFetching && <div className={styles.loading} /> }
                </React.Fragment>
              )
          }
        </div>
      </div>
    )
  }
}
GlobalSearch.propTypes = {
  onEnter: PropTypes.func,
  ifLogin: PropTypes.bool
}
GlobalSearch.defaultProps = {
  onEnter: () => {}
}
const mapStateToProps = state => ({
  tab: state.search.tab,
  isFetching: state.helpers.fetching['searchFundsByKeyword'],
  funds: searchResultFundsSelector(state),
  initialKeyword: state.search.globalSearchKeyword
})
const mapDispatchToProps = {
  searchFundsByKeyword,
  setGlobalSearchKeyword,
  openModal
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(onClickOutside(GlobalSearch)))
