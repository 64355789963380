/*
  get cookies value outof react-redux lifecycle
*/
import _some from 'lodash/some'
import addDays from 'date-fns/add_days'
import cookies from '~/cookies'

import {
  ANONYMOUS_TOKEN_KEY,
  USER_TOKEN_KEY,
  CLIENT_TOKEN_KEY,
  CLIENT_TOKEN_EXPIRES_KEY,
  CLIENT_TOKEN_EXPIRES_IN_KEY,
  CLIENT_TOKEN_TO_REFRESH_IN_KEY
} from '~/utils/constants/api'
import {
  COOKIE_LANGUAGE_KEY,
  UP_DOWN_COLOR_KEY,
  COOKIE_DISCLAIMER_KEY,
  COOKIE_ANNOUNCEMENT_KEY,
  INVESTMENT_ANALYSIS_RESULT
} from './constants'

export const getDisclaimer = () => cookies.get(COOKIE_DISCLAIMER_KEY)

export const setDisclaimer = () => {
  cookies.set(
    COOKIE_DISCLAIMER_KEY,
    1,
    { path: '/', expires: new Date((new Date().getTime()) + 24 * 60 * 60 * 1000), sameSite: 'Strict' }
  )
}

export const getAnnouncement = () => cookies.get(COOKIE_ANNOUNCEMENT_KEY)

export const setAnnouncement = (endTime) => {
  cookies.set(
    COOKIE_ANNOUNCEMENT_KEY,
    1,
    { path: '/', expires: new Date(endTime * 1000), sameSite: 'Strict' }
  )
}

export const getUserToken = () => cookies.get(USER_TOKEN_KEY)

export const getClientToken = () => cookies.get(CLIENT_TOKEN_KEY)

export const isClientTokenExists = () => {
  const values = [
    cookies.get(CLIENT_TOKEN_KEY),
    cookies.get(CLIENT_TOKEN_EXPIRES_KEY),
    cookies.get(CLIENT_TOKEN_EXPIRES_IN_KEY),
    cookies.get(CLIENT_TOKEN_TO_REFRESH_IN_KEY)
  ]
  return _some(values, value => !!value)
}

export const getAnonymousToken = () => cookies.get(ANONYMOUS_TOKEN_KEY)

export const getApiTokens = () => {
  const tokens = {
    [USER_TOKEN_KEY]: `Bearer ${getUserToken()}`,
    [CLIENT_TOKEN_KEY]: `Bearer ${getClientToken()}`,
    [ANONYMOUS_TOKEN_KEY]: `Bearer ${getAnonymousToken()}`
  }
  return tokens
}

/*
  Set language by user from client side
*/
export const setLanguage = lang => {
  cookies.set(COOKIE_LANGUAGE_KEY, lang, {
    path: '/',
    sameSite: 'Strict'
  })
}

export const getLanguage = () => {
  return cookies.get(COOKIE_LANGUAGE_KEY) || ''
}

export const getColor = () => {
  return cookies.get(UP_DOWN_COLOR_KEY) || ''
}

export const saveInvestmentAnalysisResult = (result) => {
  cookies.set(INVESTMENT_ANALYSIS_RESULT, JSON.stringify(result), {
    path: '/',
    expires: addDays(new Date(), 30),
    sameSite: 'Strict'
  })
}

export const getInvestmentAnalysisResult = () => {
  return cookies.get(INVESTMENT_ANALYSIS_RESULT) || {}
}
