import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _noop from 'lodash/noop'
import _isArray from 'lodash/isArray'

import { GA_SEND_EVENT, AA_SEND_EVENT, AA_PUSH_EVENT } from '~/actions/types'

const analyticsMiddleware = store => next => action => {
  if (action.type === GA_SEND_EVENT) {
    const { eventAction } = action.payload
    const ga = _get(window, 'ga', _noop)

    let event = {}

    switch (eventAction) {
      case 'RegisterCompleted':
      case 'LoginSuccess':
        event = { ...action.payload, eventValue: _get(store.getState(), 'user.me.id') }
        break
      default:
        event = action.payload
    }

    ga('send', event)
  }

  if (action.type === AA_SEND_EVENT) {
    const send = _get(window, 'APPIER_RETARGET.send', _noop)

    if (_isArray(action.payload)) {
      _forEach(action.payload, payload => {
        send({ ...payload })
      })
    } else {
      send({ ...action.payload })
    }
  }

  if (action.type === AA_PUSH_EVENT) {
    window.appier_q = window.appier_q || []
    window.appier_q.push(...action.payload)
  }

  return next(action)
}

export default analyticsMiddleware
