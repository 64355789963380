import {
  ADD_ERROR,
  REMOVE_ERROR
} from '~/actions/types'

const initialState = {
  register: '',
  loginUser: '',
  loginClient: '',
  getProfile: '',
  addCart: '',
  genOTP: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        [action.name]: action.msg
      }
    case REMOVE_ERROR:
      return {
        ...state,
        [action.name]: ''
      }
    default:
      return state
  }
}
