import _get from 'lodash/get'
import {
  isSSR,
  isBrowser
} from '~/utils/env'
import {
  getLanguage as getLanguageFromCookie
} from '~/utils/cookies'

const getLanguageByPath = (path) => {
  if (path.match(/^\/zh-cn\/?/)) {
    return 'zh-CN'
  } else if (path.match(/^\/zh-hk\/?/)) {
    return 'zh-HK'
  } else if (path.match(/^\/en\/?/)) {
    return 'en'
  }
}

const formatLang = lang => {
  lang = lang || ''
  switch (lang.toLowerCase()) {
    case 'zh-cn':
      return 'zh-CN'
    case 'en':
      return 'en'
    case 'zh-tw':
    case 'zh-hk':
    default:
      return 'zh-HK'
  }
}

export function getPreferLanguage (language) {
  let preferLanguage = language
  if (language) {
    return formatLang(language)
  } else if (isBrowser()) {
    preferLanguage = getLanguageByPath(window.location.pathname) ||
      getLanguageFromCookie() ||
      _get(window, ['navigator', 'userLanguage']) ||
      _get(window, ['navigator', 'language'])
  } else if (isSSR()) {
    preferLanguage = global.__LANG__
  }

  return formatLang(preferLanguage || 'en')
}
