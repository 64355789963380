import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { openModal } from '~/actions/modal'

import {
  USER_TOKEN_KEY,
  CLIENT_TOKEN_KEY,
  IS_CLIENT_KEY,
  JOINT_USER_TOKEN_KEY
} from '~/utils/constants/api'

export default (WrappedComponent) => {
  class ComponentWithCookieCheck extends Component {
    getUserToken = () => {
      return this.props.cookies.get(USER_TOKEN_KEY)
    }
    getIsClient = () => {
      return this.props.cookies.get(IS_CLIENT_KEY)
    }
    getClientToken = () => {
      return this.props.cookies.get(CLIENT_TOKEN_KEY)
    }
    getJointUserToken = () => {
      return this.props.cookies.get(JOINT_USER_TOKEN_KEY)
    }
    loginClientCallback = (callback) => {
      if (!!this.getClientToken()) {
        callback()
      } else {
        this.props.openModal('login', () => {
          callback()
        })
      }
    }
    render () {
      return (
        <WrappedComponent
          {...this.props}

          isLoginUser={() => !!this.getUserToken()}
          isClient={() => this.getIsClient() === 'true'}
          isLoginClient={() => !!this.getClientToken()}
          isLoginJointUser={() => !!this.getJointUserToken()}

          getUserToken={this.getUserToken}
          getClientToken={this.getClientToken}
          getJointUserToken={this.getJointUserToken}
          loginClientCallback={this.loginClientCallback}
        />
      )
    }
  }

  const mapStateToProps = () => ({})
  const mapDispatchToProps = {
    openModal
  }

  ComponentWithCookieCheck.propTypes = {
    cookies: PropTypes.any
  }

  return withCookies(connect(mapStateToProps, mapDispatchToProps)(ComponentWithCookieCheck))
}
