import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import _merge from 'lodash/merge'

import Clickable from '~/components/Form/Clickable'
import IconModalClose from '~/assets/icons/modalClose.svg'

import styles from './Modal.scss'

class Modal extends React.Component {
  defaultStyles = {
    content: {
      position: 'relative',
      overflow: 'initial',
      border: '1px solid transparent',
      borderRadius: '4px',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      margin: '100px auto 100px',
      padding: '0'
    }
  }

  render () {
    const { isModalOpen, onAfterOpen, overlayDismiss, onRequestClose, children, customStyles = {}, contentClassName } = this.props
    return (
      <ReactModal
        isOpen={isModalOpen}
        style={_merge({}, this.defaultStyles, { content: customStyles })}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={overlayDismiss}
        contentLabel='Example'
        ariaHideApp={false}
        className={contentClassName}
      >
        {onRequestClose && (
          <Clickable onClick={onRequestClose} className={styles.close} button>
            <IconModalClose />
          </Clickable>
        )}
        {children}
      </ReactModal>
    )
  }
}

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  overlayDismiss: PropTypes.bool, // click on overlay trigger close or not
  children: PropTypes.node
}
Modal.defaultProps = {
  overlayDismiss: true
}
export default Modal
