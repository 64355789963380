import _noop from 'lodash/noop'
import {
  OPEN_MODAL,
  CLOSE_MODAL
} from './types'

/**
 * openModal: global function to open any modal
 * @param  {String}   key      modal name key
 * @param  {Function} callback callback function
 *  default _noop so if you call this func second time without callback
 *  _noop will replace last callback
 */
export const openModal = (key, callback = _noop) => dispatch => {
  dispatch({
    type: OPEN_MODAL,
    key,
    callback
  })
}

/**
 * closeModal: global function to close any modal
 * @param  {String}   key      modal name key
 * @param  {Function} callback callback function
 *  default _noop so if you call this func second time without callback
 *  _noop will replace last callback
 */
export const closeModal = (key, callback = _noop) => dispatch => {
  dispatch({
    type: CLOSE_MODAL,
    key,
    callback
  })
}
