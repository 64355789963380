import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'

import Link from '~/components/Common/Link'
import AngleDropdownBox from '~/components/Common/AngleDropdownBox'

import LocaleToggler from '../LocaleToggler'

import styles from './MobileNav.scss'

class MobileNav extends Component {
  state = {
    show: false,
    activeIndex: -1
  }

  toggleShow = () => {
    this.setState(prevState => {
      prevState.show = !prevState.show
      return prevState
    })
  }

  handleClickOutside = () => {
    this.setState({
      show: false
    })
  }

  handleEnMenuGroupClick = (currentIdx) => {
    const { activeIndex } = this.state
    if (currentIdx !== activeIndex) {
      this.setState({
        activeIndex: currentIdx
      })
    }
  }

  render () {
    const { navs } = this.props
    const { show } = this.state
    return (
      <div className={styles.mobileNav}>
        <button className={styles.trigger} onClick={this.toggleShow}>
          <span />
          <span />
          <span />
        </button>
        <AngleDropdownBox
          className={styles.menu}
          bodyClassName={styles.menuInner}
          show={show}
          // angleRight={5}
        >
          <React.Fragment>
            <LocaleToggler isMobileMenu />
            <div className={styles.navWrapper}>
              {navs.map((nav, idx) =>
                <div
                  key={idx}
                  className={styles.menuGroup}
                >
                  <h2 className={styles.title}>{nav.title}</h2>
                  {this._renderMenuGroup(nav, idx)}
                </div>
              )}
            </div>
          </React.Fragment>
        </AngleDropdownBox>
      </div>
    )
  }
  _renderMenuGroup = (nav, idx) => {
    return <div className={styles.links}>
      {nav.menu.map((item, mIdx) =>
        <Link key={mIdx} to={item.link} className={styles.subItem}>
          <span>{item.title}</span>
        </Link>
      )}
    </div>
  }
}

MobileNav.propTypes = {
  navs: PropTypes.array
}

const mapStateToProps = (state) => ({
  lang: state.helpers.lang
})

const mapDispatchToProps = {
}

const Connect = connect(mapStateToProps, mapDispatchToProps)(onClickOutside(MobileNav))
export default Connect
