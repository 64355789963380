import React from 'react'
import { connect } from 'react-redux'
import _flowRight from 'lodash/flowRight'

import { Row, Col } from '@/components'

import { withLoginStatus, withLanguage } from '~/hoc'
import t from '~/utils/locales'
import { openModal } from '~/actions/modal'
import { setRedirect } from '~/actions/helpers'

import IconFacebook from '~/assets/icons/footer-facebook.svg'
import IconYoutube from '~/assets/icons/footer-youtube.svg'
import IconInstagram from '~/assets/icons/instagram-footer.png'
import IconLinkedIn from '~/assets/icons/linked-in-footer.svg'

import StarFilled from '~/assets/icons/level1.svg'
import LionLogo from '~/assets/icons/lion-logo.svg'

import Link from '~/components/Common/Link'
import FeatureToggle from '~/components/Modal/FeatureToggle'
import { isDev, isStg } from '~/utils/env'

import styles from './Footer.scss'

class Footer extends React.PureComponent {
  openFeatureToggleModal = () => {
    this.props.openModal('FeatureToggle')
  }

  handleOpenAccount = () => {
    if (this.props.isLoginUser()) {
      if (this.props.isClient()) {
        this.props.setRedirect('/user/settings')
      } else {
        this.props.setRedirect('/open-account')
      }
    } else {
      this.props.setRedirect('/register')
    }
  }

  render () {
    // responsive layout params --
    const fullRow = { span: 24, offset: 0 }
    const eleGrid = this.props.lang === 'en'
      ? {
        'address': {
          'xs': fullRow,
          'sm': { span: 15, offset: 0 },
          'md': { span: 12, offset: 0 },
          'lg': { span: 9, offset: 0 }
        },
        'contact': {
          'xs': fullRow,
          'sm': { span: 9, offset: 0 },
          'md': { span: 8, offset: 0 },
          'lg': { span: 4, offset: 0 }
        },
        'chore': {
          'xs': fullRow,
          'sm': fullRow,
          'md': fullRow,
          'lg': { span: 9, offset: 1 }
        }
      }
      : {
        'address': {
          'xs': fullRow,
          'sm': { span: 10, offset: 1 },
          'md': { span: 10, offset: 1 },
          'lg': { span: 6, offset: 1 }
        },
        'contact': {
          'xs': fullRow,
          'sm': { span: 10, offset: 0 },
          'md': { span: 10, offset: 0 },
          'lg': { span: 6, offset: 0 }
        },
        'chore': {
          'xs': fullRow,
          'sm': fullRow,
          'md': fullRow,
          'lg': { span: 8, offset: 0 }
        }
      }
    const addressGrid = eleGrid.address
    const contactGrid = eleGrid.contact
    const choreGrid = eleGrid.chore
    // [end] responsive layout params --

    return (
      <footer className={styles.footer}>
        <div className={styles.container}>
          <Row gutter={20} align={'middle'} justify={'space-around'}>
            <Col
              xs={addressGrid.xs}
              sm={addressGrid.sm}
              md={addressGrid.md}
              lg={addressGrid.lg}
              className={styles.address}
            >
              <p className={styles.text}>
                {t('footer.address')}
              </p>
              <p className={styles.text}>
                {t('footer.workday')}
              </p>
              <p className={styles.text}>
                {t('footer.businessHours')}
              </p>
            </Col>
            <Col
              xs={contactGrid.xs}
              sm={contactGrid.sm}
              md={contactGrid.md}
              lg={contactGrid.lg}
              className={styles.contact}
            >
              <div className={styles.inner}>
                <p className={styles.text}>
                  <span>{t('footer.phone.title')}</span>
                </p>
                <div className={styles.phone}>
                  <div className={styles.countryCode}>{t('footer.phone.countryCode')}</div>
                  <div className={styles.number}>{t('footer.phone.number')}</div>
                </div>
              </div>
            </Col>
            <Col
              xs={choreGrid.xs}
              sm={choreGrid.sm}
              md={choreGrid.md}
              lg={choreGrid.lg}
              className={styles.chore}
            >
              <LionLogo />
              <div>
                <div className={styles.links}>
                  <Link to='https://zh-hk.facebook.com/ifund.com.hk/' noreferrer ariaLabel='Facebook' target='_blank'>
                    <IconFacebook />
                  </Link>
                  <Link to='https://www.youtube.com/channel/UCsRMuAVEAHgWc8a5RPHk9UA' noreferrer ariaLabel='YouTube' target='_blank'>
                    <IconYoutube />
                  </Link>
                  <Link to='https://www.instagram.com/ifundhk/' noreferrer ariaLabel='Instagram' target='_blank'>
                    <img src={IconInstagram} width='17' height='17' alt='Instagram' />
                  </Link>
                  <Link to='https://www.linkedin.com/company/ifund-hk' noreferrer ariaLabel='LinkedIn' target='_blank'>
                    <IconLinkedIn />
                  </Link>
                  {(isDev() || isStg()) && <StarFilled onClick={this.openFeatureToggleModal} />}
                  <br />
                  <Link to='/resources' primary>{t('footer.resources')}</Link>
                  <Link to='/about-us' primary>{t('footer.aboutUs')}</Link>
                  <Link to='/resources/disclaimer' primary>{t('footer.importantDisclaimer')}</Link>
                </div>
                <p className={styles.copyright} dangerouslySetInnerHTML={{ __html: t('footer.copyrighting', new Date().getFullYear()) }} />
              </div>
            </Col>
            <FeatureToggle />
          </Row>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = () => ({
})
const mapDispatchToProps = {
  openModal,
  setRedirect
}

export default _flowRight(
  withLoginStatus,
  withLanguage,
  connect(mapStateToProps, mapDispatchToProps)
)(Footer)
