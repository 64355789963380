import {
  OPEN_MODAL,
  CLOSE_MODAL
} from '~/actions/types'
import _noop from 'lodash/noop'

const initialState = {
  modalOpenStatus: {
    login: false,
    register: false,
    verifyEmail: false,
    importComparisonFromFav: false,
    importComparisonFromCompanies: false,
    confirmOrder: false,
    receipt: false,
    fundOrderDetail: false,
    fromJointToPersonal: false,
    loginJointUser: false,
    registerJointUser: false,
    gainLossAlert: false,
    cashTopup: false,
    cashExchange: false,
    cashWithdraw: false,
    updateBankInfo: false,
    capitalAnalysis: false,
    companiesHoliday: false,
    favHoliday: false,
    changeEmail: false,
    changePassword: false,
    changeMobile: false,
    inviteFriends: false,
    derivativesTraining: false,
    rpqResult: false,
    mobileSearch: false,
    // TODO: change a better name
    confirmModal: false,
    disclaimer: false,
    noEmail: false,
    announcement: false,
    changeProfile: false,
    regularSavingRule: false
  },
  callbacks: {
    login: { open: _noop, close: _noop },
    fromJointToPersonal: { open: _noop, close: _noop }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalOpenStatus: {
          ...state.modalOpenStatus,
          [action.key]: true
        },
        callbacks: {
          ...state.callbacks,
          [action.key]: {
            ...state.callbacks[action.key],
            open: action.callback
          }
        }
      }
    case CLOSE_MODAL:
      return {
        ...state,
        modalOpenStatus: {
          ...state.modalOpenStatus,
          [action.key]: false
        },
        callbacks: {
          ...state.callbacks,
          [action.key]: {
            ...state.callbacks[action.key],
            close: action.callback
          }
        }
      }
    default:
      return state
  }
}
