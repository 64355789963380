import _fetch from './_fetch'
import {
  FUND_TRADING_SERVICE,
  CLIENT_TOKEN_KEY,
  USER_TOKEN_KEY
} from '~/utils/constants/api'

const Trading = {
  /**
   * Precheck order's input amount
   * API use Trading.fundOrders with { preCheckOnly: true } to preCheck
   * @type {Object}
   */
  preCheckFundOrders: {
    post (body) {
      return Trading.fundOrders.post(
        body,
        {
          preCheckOnly: true
        }
      )
    }
  },
  /**
   * fundOrdes.post to make a order
   * delete to cancel a order
   * @type {Object}
   */
  fundOrders: {
    get (query) {
      return _fetch({
        method: 'GET',
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        path: '/fundOrders',
        query
      })
    },
    post (body, query) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'POST',
        path: '/fundOrders',
        body,
        query
      })
    },
    delete (body) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'DELETE',
        path: '/fundOrders',
        body
      })
    },
    patch ({ code, body }) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'PATCH',
        path: `/fundOrders/${code}`,
        body
      })
    }
  },
  fundToConfirmOrders: {
    post (body) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'POST',
        path: '/fundToConfirmOrders',
        body
      })
    }
  },
  fundConfirmOrders: {
    post (body) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'POST',
        path: '/fundConfirmOrders',
        body
      })
    }
  },
  fundOrderInitialCharge: {
    post (body) { // { fundCode, confirmNow: false, orderSide, [toFundCode]  }
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'POST',
        path: '/fundOrderInitialCharge',
        body: Object.assign({
          confirmNow: false
        }, body)
      })
    }
  },
  fundOrderConfirmInfoBase: {
    get (query) { // { fundCode, orderSide }
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: USER_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'GET',
        path: '/fundOrderConfirmInfoBase',
        query
      })
    }
  }
}

export default Trading
