import React from 'react'
import { withRouter } from '@/router'
import { connect } from 'react-redux'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'

import t from '~/utils/locales'
import { getLanguagePath } from '~/utils/urlUtils'
import { GLOBAL_SEARCH_INPUT_DEBOUNCE_TIME, GLOBAL_SEARCH_PAGE_SIZE } from '~/utils/constants'
import { closeModal } from '~/actions/modal'
import { searchFundsByKeyword, resetSearch } from '~/actions/fund'
import { withMessage } from '~/hoc'
import { searchResultFundsSelector } from '~/reducers/selector'

import Modal from '~/components/Layout/Modal'
import SearchInput from '~/components/Form/SearchInput'
import EmptyIcon from '~/components/Layout/Empty/EmptyIcon'
import SearchResultList from '~/components/Logic/GlobalSearch/SearchResultList'

import styles from './MobileSearch.scss'

class MobileSearchModal extends React.Component {
  state = {
    keyword: '',
    entered: false
  }

  handleSearchInput = (keyword) => {
    this.setState({ keyword, entered: false })
    this.searchFundByKeyword()
  }

  handleSearchEnter = () => {
    const { history, closeModal, tab } = this.props
    const { keyword } = this.state
    if (keyword) {
      this.setState({ entered: true })
      closeModal('mobileSearch')
      history.push(`${getLanguagePath()}/search/${tab}/${keyword}`)
    }
  }

  handleSearchReset = () => {
    this.setState({ keyword: '' })
  }

  handleResultItemClick = () => {
    this.handleModalClose()
  }

  handleKeywordClick = () => {
    this.props.closeModal('mobileSearch')
  }

  handleModalClose = () => {
    // since modal don't run unMount, clear the state by hand (Refactor needed)
    this.setState({ keyword: '' })
    this.props.resetSearch()
    this.props.closeModal('mobileSearch')
  }

  searchFundByKeyword = _debounce(() => {
    const { keyword, entered } = this.state
    if (keyword && !entered) {
      this.props.searchFundsByKeyword({ q: keyword, size: GLOBAL_SEARCH_PAGE_SIZE })
    }
  }, GLOBAL_SEARCH_INPUT_DEBOUNCE_TIME)

  render () {
    const { isFetching, funds } = this.props
    const { keyword } = this.state
    return (
      <Modal
        isModalOpen={this.props.modalOpenStatus.mobileSearch}
        onRequestClose={this.handleModalClose}
      >
        <div className={styles.container}>
          <div className={styles.search}>
            <SearchInput
              value={keyword}
              onInput={this.handleSearchInput}
              onEnter={this.handleSearchEnter}
              placeholder={t('search.placeholder')}
            />
          </div>

          <div>
            {
              _isEmpty(funds)
                ? (
                  <React.Fragment>
                    <EmptyIcon
                      text={keyword !== '' ? t('search.emptyResult') : t('search.placeholder')}
                      buttonOnClick={this.handleSearchReset}
                      buttonLabel={t('search.reset')}
                      height={300}
                      withText
                      withButton={keyword !== ''}
                      search
                    />
                    { isFetching && <div className={styles.loading} /> }
                  </React.Fragment>
                )
                : (
                  <div className={styles.result}>
                    <SearchResultList funds={funds} keyword={keyword} onKeywordClick={this.handleKeywordClick} onItemClick={this.handleResultItemClick} />
                    { isFetching && <div className={styles.loading} /> }
                  </div>
                )
            }
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  tab: state.search.tab,
  modalOpenStatus: state.modal.modalOpenStatus,
  isFetching: state.helpers.fetching['searchFundsByKeyword'],
  funds: searchResultFundsSelector(state)
})
const mapDispatchToProps = {
  closeModal,
  resetSearch,
  searchFundsByKeyword
}
export default withRouter(withMessage(connect(mapStateToProps, mapDispatchToProps)(MobileSearchModal)))
