import Colors from './colors'
const wheelColors = [
  // '#133363',
  // '#1050B0',
  Colors.blue,
  Colors.blue3,
  '#2079CF',
  '#2B99F0',
  '#68B7F3',
  '#93CBF7',
  '#C9E5CA',
  '#A8D5A8',
  '#86C587',
  '#54AD55',
  '#408C40',
  '#367A36',
  '#225D24',
  '#827622',
  '#9E9B31',
  '#C1C842',
  '#D5DF61',
  '#E7ECA0',
  '#FFEF7E',
  '#FCD648',
  '#F9BF40',
  '#F6A738',
  '#F17F2C',
  '#EB6B1F',
  '#E2521B',
  '#D84315',
  '#BF360C',
  '#B71C1C',
  '#D32F2F',
  '#F44336',
  '#E57373',
  '#FFCDD2',
  '#D0C5E8',
  '#B29FD9',
  '#9379CB',
  '#7C5BBF',
  '#6442B4',
  '#4E34A5',
  '#2E258F',
  '#231C6B'
]

module.exports = wheelColors
