import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IdleTimer from 'react-idle-timer'

import { connect } from 'react-redux'
import { autoRefreshClientTokenIfNeeded } from '~/actions/user'

const IDLE_TIMEOUT = 1000 * 5 // after 5 seconds not move, idleStatus = true
export default (WrappedComponent) => {
  class ClientTokenAutoRefreshWrapper extends Component {
    onActive = () => {
      this.props.autoRefreshClientTokenIfNeeded()
    }
    render () {
      const restProps = this.props
      return (
        <IdleTimer
          element={document}
          onActive={this.onActive}
          timeout={IDLE_TIMEOUT}
        >
          <WrappedComponent {...restProps} />
        </IdleTimer>
      )
    }
  }
  const mapProps = [
    null,
    { autoRefreshClientTokenIfNeeded }
  ]

  ClientTokenAutoRefreshWrapper.propTypes = {
    autoRefreshClientTokenIfNeeded: PropTypes.func.isRequired
  }

  return connect(...mapProps)(ClientTokenAutoRefreshWrapper)
}
