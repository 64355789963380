import React, { Component } from 'react'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _toNumber from 'lodash/toNumber'

import { isUserVerifiedSelector } from '~/reducers/selector'
import { getMe, getFavourites } from '~/actions/user'
import { setRedirect } from '~/actions/helpers'
import { withLoginStatus, withoutSSR } from '~/hoc'
import { sendGAEvent, sendAAEvent } from '~/actions/analytics'

import LoginUser from './LoginUser'
import LoginClient from './LoginClient'

class LoginFlow extends Component {
  constructor (props) {
    super(props)
    if (props.isLoginClient()) {
      const { profile } = props
      if (!profile || !profile.investExp) {
        this.props.setRedirect('/account')
      }
    }
    this.state = {
      finishLoginUser: this.props.isLoginUser()
    }
  }

  componentDidMount () {
    const { isLoginUser, me, getMe } = this.props
    if (isLoginUser() && _isEmpty(me)) {
      getMe()
    }
  }

  onLoginUser = (loginMethod) => {
    const { googleAnalyse, appierAnalyse, me, getFavourites } = this.props
    this.setState({
      finishLoginUser: true
    })
    getFavourites()

    googleAnalyse({
      eventCategory: 'UserAccount',
      eventAction: 'LoginSuccess',
      eventLabel: loginMethod,
      eventValue: _toNumber(me.id)
    })
    appierAnalyse({ 't': 'type_process', 'content': 'login', 'action_id': '3H2bTdKoEHwKwei', 'track_id': '9whBm54vxahfeLc', 'opts': { unique_key: 'true' } })
  }

  render () {
    const { finishLoginUser } = this.state
    const { isLoginUser } = this.props
    if (!isLoginUser() || !finishLoginUser) {
      return <LoginUser onLoginUser={this.onLoginUser} />
    } else {
      return <LoginClient />
    }
  }
}

const mapStateToProps = (state) => ({
  isVerified: isUserVerifiedSelector(state),
  me: state.user.me,
  profile: state.user.profile
})
const mapDispatchToProps = {
  setRedirect,
  getMe,
  getFavourites,
  googleAnalyse: sendGAEvent,
  appierAnalyse: sendAAEvent
}

export default withoutSSR(withLoginStatus(connect(mapStateToProps, mapDispatchToProps)(LoginFlow)))
