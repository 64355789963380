import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class Notice extends Component {
  static propTypes = {
    duration: PropTypes.number,
    onClose: PropTypes.func,
    update: PropTypes.bool
  };

  static defaultProps = {
    onEnd () {
    },
    onClose () {
    },
    duration: 1.5,
    style: {
      right: '50%'
    }
  };

  componentDidMount () {
    this.startCloseTimer()
  }

  componentDidUpdate (prevProps) {
    if (this.props.duration !== prevProps.duration ||
      this.props.update) {
      this.restartCloseTimer()
    }
  }

  componentWillUnmount () {
    this.clearCloseTimer()
  }

  close = () => {
    this.clearCloseTimer()
    this.props.onClose()
  }

  startCloseTimer = () => {
    if (this.props.duration) {
      this.closeTimer = setTimeout(() => {
        this.close()
      }, this.props.duration * 1000)
    }
  }

  clearCloseTimer = () => {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer)
      this.closeTimer = null
    }
  }

  restartCloseTimer () {
    this.clearCloseTimer()
    this.startCloseTimer()
  }

  render () {
    const props = this.props
    const componentClass = `${props.prefixCls}-notice`
    const className = {
      [`${componentClass}`]: 1,
      [`${componentClass}-closable`]: props.closable,
      [props.className]: !!props.className
    }
    return (
      <div className={classNames(className)} style={props.style} onMouseEnter={this.clearCloseTimer}
        onMouseLeave={this.startCloseTimer}
      >
        <div className={`${componentClass}-content`}>{props.children}</div>
        {props.closable
          ? <a tabIndex='0' onClick={this.close} className={`${componentClass}-close`}>
            {props.closeIcon || <span className={`${componentClass}-close-x`} />}
          </a> : null
        }
      </div>
    )
  }
}
