import _isNull from 'lodash/isNull'
import _isUndefined from 'lodash/isUndefined'
import _isNumber from 'lodash/isNumber'
import _isNil from 'lodash/isNil'
import _format from 'date-fns/format'

import { getReversedUpDownColors } from '~/utils/style'
import t from '~/utils/locales'

// only allow \d & one .
export const parseNumber = num => {
  num = num.toString().replace(/[^\d.]/g, '')
  const dotIdx = num.indexOf('.')
  num = num.replace(/\./g, (dot, idx) => idx === dotIdx ? dot : '')
  return num
}

const isInvalidNum = num => (
  _isUndefined(num) || _isNull(num) || num.toString().indexOf('%') > -1 || !_isNumber(num)
)

export const safeParseFloat = num => (
  isNaN(parseFloat(num)) ? num : parseFloat(num)
)

export const fixNum = (num, digits = 2) => {
  // if (isInvalidNum(parseFloat(num))) return num
  if (_isNil(num) || isNaN(parseFloat(num))) {
    return num
  }

  return parseFloat(num).toFixed(digits)
}

export const truncateNum = (num, digit = 4) => {
  if (num % 1 === 0) {
    // if no decimal, no change
    return num
  }
  let str = num.toString()
  return Number(str.substring(0, str.lastIndexOf('.') + digit + 1))
}

export const fixAnalysisNum = (num, digits = 2) => {
  // if (isInvalidNum(parseFloat(num))) return num
  if (_isNil(num) || isNaN(parseFloat(num))) {
    return num
  }

  if (num < 0.01) {
    return 0.1
  }
  const curNum = String(num).substring(0, String(num).lastIndexOf('.') + 3)
  if (curNum === '99.99') {
    return 99.99
  }

  return parseFloat(num).toFixed(digits)
}

export const removeCommas = (num) => {
  if (_isNil(num)) return num
  return num.toString().replace(/,/g, '')
}

export const numberWithCommas = (num) => {
  if (isInvalidNum(num)) return num

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const numberWithCommasNSuffix = (num, digits) => {
  if (isInvalidNum(num)) return num

  let str = digits ? fixNum(num, digits) : num.toString()
  let [integer, decimal] = str.split('.')
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return decimal ? (integer + '.' + decimal) : integer
}

// unit: s
export const toLocalTimezone = (time) => {
  const secondOffset = new Date().getTimezoneOffset() * 60
  time -= secondOffset
  return time
}

// unit: s
export const formatDate = (time, format = 'YYYY-MM-DD') => {
  if (_isNil(time)) return time
  const result = _format(parseInt(time, 10) * 1000, format)
  return result
}

export const getSignByNum = (num, minus = false) => {
  let sign = ''
  if (num > 0) {
    sign = '+'
  } else if (num < 0 && minus) {
    sign = '-'
  }
  return sign
}

export const getNumColorBySign = (num) => {
  if (isInvalidNum(num)) return num

  const colorObj = getReversedUpDownColors({})
  return num > 0 ? colorObj.up : colorObj.down
}

export const simplifyNum = (num, digits = 2) => {
  if (isInvalidNum(num)) return num || '-'
  let result = fixNum(num, digits)
  if (Math.abs(num) >= 1000000000000) {
    result = fixNum(num / 1000000000000, digits) + t('unit.THOUSAND_BILLION')
  } else if (Math.abs(num) >= 100000000) {
    result = fixNum(num / 100000000, digits) + t('unit.HUNDRED_MILLION')
  } else if (Math.abs(num) >= 10000) {
    result = fixNum(num / 10000, digits) + t('unit.TEN_THOUSAND')
  }
  return result
}

export const withPercent = (num) => {
  return _isNil(num) ? ' ' : `${fixNum(num)}%`
}

// from +85288888888 to { countryCode: '+852', number: '88888888' }
export const parsePhone = (phone) => {
  const countryCodes = ['86', '852', '853', '886']
  const pattern = new RegExp(`^\\+{0,1}(\\${countryCodes.join('|\\')})(\\d+)`)
  const matched = phone.match(pattern)
  if (matched) {
    return {
      countryCode: matched[1],
      number: matched[2]
    }
  }
  return {
    countryCode: '',
    number: phone
  }
}
