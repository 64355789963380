import React from 'react'

import styles from './Page.scss'
class Page extends React.PureComponent {
  render () {
    return (
      <div className={styles.page}>
        {this.props.children}
      </div>
    )
  }
}

export default Page
