import React from 'react'
import Notification from './Notification'
import IconRemove from '~/assets/icons/remove.svg'

// Notification single instance
let _singleInstance = null
// props of _singleInstance
const _singleProps = {
  closeIcon: <IconRemove />,
  maxCount: 1
}

// default options of notice function
const _defaultNoticeOptions = {
  animation: 'fade',
  duration: null,
  closable: true
}

/**
 * call notice
 * @param {*} instance
 * @param {*} options
 */
const _notice = (instance, options) => {
  instance.notice({ ..._defaultNoticeOptions, ...options })
}

export default {
  /**
   * notice
   * @param {*} props The props of Notification
   * @param {*} options The options of notice function
   */
  single (options) {
    if (_singleInstance) {
      _notice(_singleInstance, options)
    } else {
      Notification.newInstance(_singleProps, (n) => {
        _singleInstance = n
        _notice(_singleInstance, options)
      })
    }
  }
}
