/*
  handle set cookies actions
  cookies is injected in `/src/store/index.js`
*/
import {
  USER_TOKEN_KEY,
  USER_TOKEN_EXPIRES_KEY,
  USER_REFRESH_TOKEN_KEY,
  USER_REFRESH_TOKEN_EXPIRES_KEY,

  CLIENT_TOKEN_KEY,
  CLIENT_TOKEN_EXPIRES_KEY,
  CLIENT_TOKEN_EXPIRES_IN_KEY,
  CLIENT_TOKEN_TO_REFRESH_IN_KEY,

  JOINT_USER_TOKEN_KEY,
  JOINT_USER_TOKEN_EXPIRES_KEY,

  ANONYMOUS_TOKEN_KEY,

  IS_CLIENT_KEY
} from '~/utils/constants/api'
import {
  UP_DOWN_COLOR_KEY,
  DEFAULT_CURRENCY_KEY,
  COLOR_EGG
} from '~/utils/constants'

/*
 * User Token cookies handlers
 */
export const getUserToken = () => (dispatch, getState, { cookies }) => {
  cookies.get(USER_TOKEN_KEY)
}
export const setAnonymousToken = ({ token, accessTokenExpires }) => (dispatch, getState, { cookies }) => {
  cookies.set(
    ANONYMOUS_TOKEN_KEY,
    token,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
}
export const setUserToken = ({ accessToken, accessTokenExpires, refreshToken, refreshTokenExpires }) => (dispatch, getState, { cookies }) => {
  cookies.set(
    USER_TOKEN_KEY,
    accessToken,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
  // store userTokenExpires in cookie for further use
  cookies.set(
    USER_TOKEN_EXPIRES_KEY,
    accessTokenExpires,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
  cookies.set(
    USER_REFRESH_TOKEN_KEY,
    refreshToken,
    { path: '/', expires: new Date(refreshTokenExpires * 1000), sameSite: 'Strict' }
  )
  // store userRefreshTokenExpires in cookie for further use
  cookies.set(
    USER_REFRESH_TOKEN_EXPIRES_KEY,
    refreshTokenExpires,
    { path: '/', expires: new Date(refreshTokenExpires * 1000), sameSite: 'Strict' }
  )
}

export const rmUserToken = () => (dispatch, getState, { cookies }) => {
  cookies.remove(USER_TOKEN_KEY, { path: '/' })
  cookies.remove(USER_TOKEN_EXPIRES_KEY, { path: '/' })
  cookies.remove(USER_REFRESH_TOKEN_KEY, { path: '/' })
  cookies.remove(USER_REFRESH_TOKEN_EXPIRES_KEY, { path: '/' })
}

/*
 * Client Token cookies handlers
 */
export const getClientToken = () => (dispatch, getState, { cookies }) => {
  cookies.get(CLIENT_TOKEN_KEY)
}
export const setClientToken = ({ accessToken, accessTokenExpires, accessTokenExpiresIn, accessTokenToRefreshIn }) => (dispatch, getState, { cookies }) => {
  cookies.set(
    CLIENT_TOKEN_KEY,
    accessToken,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
  // store clientTokenExpires in cookie for further use
  cookies.set(
    CLIENT_TOKEN_EXPIRES_KEY,
    accessTokenExpires,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
  cookies.set(
    CLIENT_TOKEN_EXPIRES_IN_KEY,
    accessTokenExpiresIn,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
  // store clientRefreshTokenExpires in cookie for further use
  cookies.set(
    CLIENT_TOKEN_TO_REFRESH_IN_KEY,
    accessTokenToRefreshIn,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
}
export const rmClientToken = () => (dispatch, getState, { cookies }) => {
  cookies.remove(CLIENT_TOKEN_KEY, { path: '/' })
  cookies.remove(CLIENT_TOKEN_EXPIRES_KEY, { path: '/' })
  cookies.remove(CLIENT_TOKEN_EXPIRES_IN_KEY, { path: '/' })
  cookies.remove(CLIENT_TOKEN_TO_REFRESH_IN_KEY, { path: '/' })
}

/*
 * storage isClient in cookie
 */
export const getClient = () => (dispatch, getState, { cookies }) => {
  if (typeof cookies.get(IS_CLIENT_KEY) !== 'undefined') {
    return cookies.get(IS_CLIENT_KEY) === 'true'
  }
  return null
}
export const setIsClient = (isClient) => (dispatch, getState, { cookies }) => {
  const userTokenExpires = cookies.get(USER_TOKEN_EXPIRES_KEY)
  cookies.set(
    IS_CLIENT_KEY,
    isClient,
    { path: '/', expires: new Date(userTokenExpires * 1000), sameSite: 'Strict' })
}
export const rmIsClient = () => (dispatch, getState, { cookies }) => {
  cookies.remove(IS_CLIENT_KEY, { path: '/' })
}
/*
 * storage joint account token in cookie
 */

export const getJointUserToken = () => (dispatch, getState, { cookies }) => {
  return cookies.get(JOINT_USER_TOKEN_KEY)
}
export const setJointUserToken = ({ accessToken, accessTokenExpires }) => (dispatch, getState, { cookies }) => {
  cookies.set(
    JOINT_USER_TOKEN_KEY,
    accessToken,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
  // store userTokenExpires in cookie for further use
  cookies.set(
    JOINT_USER_TOKEN_EXPIRES_KEY,
    accessTokenExpires,
    { path: '/', expires: new Date(accessTokenExpires * 1000), sameSite: 'Strict' }
  )
}
export const rmJointUserToken = () => (dispatch, getState, { cookies }) => {
  cookies.remove(JOINT_USER_TOKEN_KEY, { path: '/' })
  cookies.remove(JOINT_USER_TOKEN_EXPIRES_KEY, { path: '/' })
}

export const rmAllCookies = () => (dispatch) => {
  dispatch(rmUserToken())
  dispatch(rmClientToken())
  dispatch(rmIsClient())
  dispatch(rmJointUserToken())
}

export const getClientTokenObj = () => (dispatch, getState, { cookies }) => ({
  clientToken: cookies.get(CLIENT_TOKEN_KEY),
  clientTokenExpires: cookies.get(CLIENT_TOKEN_EXPIRES_KEY),
  clientTokenExpiresIn: cookies.get(CLIENT_TOKEN_EXPIRES_IN_KEY),
  clientTokenToRefreshIn: cookies.get(CLIENT_TOKEN_TO_REFRESH_IN_KEY)
})

export const setUpDownColor = (upDownColor) => (dispatch, getState, { cookies }) => {
  cookies.set(UP_DOWN_COLOR_KEY, upDownColor, { path: '/' })
}

export const setDefaultCurrency = (currency) => (dispatch, getState, { cookies }) => {
  cookies.set(DEFAULT_CURRENCY_KEY, currency, { path: '/', sameSite: 'Strict' })
}

export const setColorEgg = () => (dispatch, getState, { cookies }) => {
  cookies.set(COLOR_EGG, 1, { path: '/', sameSite: 'Strict' })
}
