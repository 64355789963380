// utils
export const GET_SCREEN_SIZE = 'utils/GET_SCREEN_SIZE'

// fund
export const GET_FUND = 'fund/GET_FUND'
export const GET_FUND_COMPANIES = 'fund/GET_FUND_COMPANIES'
export const GET_FUNDS_BY_COMPANY = 'fund/GET_FUNDS_BY_COMPANY'
export const GET_FUNDS_BY_COMPANYCODE = 'fund/GET_FUNDS_BY_COMPANYCODE'
export const SET_FUNDS_BY_COMPANY_PAGINATION = 'fund/SET_FUNDS_BY_COMPANY_PAGINATION'
export const GET_MORE_FUNDS = 'fund/GET_MORE_FUNDS'
export const GET_FUND_TRANSACTIONS = 'fund/GET_FUND_TRANSACTIONS'
export const GET_FUND_TRANSACTION_DETAIL = 'fund/GET_FUND_TRANSACTION_DETAIL'
export const GET_FUND_TRANSACTION_CONTRACT_NOTE = 'fund/GET_FUND_TRANSACTION_CONTRACT_NOTE'
export const GET_FUND_CASH_TRANSACTIONS = 'fund/GET_FUND_CASH_TRANSACTIONS'
export const GET_FUND_CHART = 'fund/GET_FUND_CHART'
export const GET_FUND_COMPARISON = 'fund/GET_FUND_COMPARISON'
export const SET_FUND_COMPARISON = 'fund/SET_FUND_COMPARISON'
export const GET_FUND_TRANSFER_IN_DOCS = 'fund/GET_FUND_TRANSFER_IN_DOCS'
export const GET_FUND_HOLIDAY = 'fund/GET_FUND_HOLIDAY'
export const GET_MARKET_INDEX = 'fund/GET_MARKET_INDEX'
export const GET_TOP_PERFORMANCE_FUNDS = 'fund/GET_TOP_PERFORMANCE_FUNDS'
export const GET_HOT_BUY_FUNDS = 'fund/GET_HOT_BUY_FUNDS'
export const SET_FUND_POOL = 'fund/SET_FUND_POOL'
export const SET_FUND_SEARCH_POOL = 'fund/SET_FUND_SEARCH_POOL'
export const SET_FUND_CHART_POOL = 'fund/SET_FUND_CHART_POOL'
export const SET_COMPARISON_CHART_POOL = 'fund/SET_COMPARISON_CHART_POOL'
export const GET_FUND_RELATED_TOP_PERFORMANCE_FUNDS = 'fund/GET_FUND_RELATED_TOP_PERFORMANCE_FUNDS'
export const SET_COMPARISON_POOL = 'fund/SET_COMPARISON_POOL'
export const SET_COMPARISON_SELECTED = 'fund/SET_COMPARISON_SELECTED'
export const SET_GLOBAL_SEARCH_KEYWORD = 'fund/SET_GLOBAL_SEARCH_KEYWORD'
export const GET_FUNDS_BY_KEYWORDS = 'fund/GET_FUNDS_BY_KEYWORDS'
export const GET_CURATED_FUNDS = 'fund/GET_CURATED_FUNDS'
export const GET_FUND_RANK = 'fund/GET_FUND_RANK'
export const RESET_SEARCH = 'fund/RESET_SEARCH'

// trading
export const GET_FUND_ORDERS = 'trading/GET_FUND_ORDERS'
export const GET_FUND_ORDER_INITIAL_CHARGE = 'trading/GET_FUND_ORDER_INITIAL_CHARGE'
export const GET_FUND_ORDER_CONFIRM_INFO = 'trading/GET_FUND_ORDER_CONFIRM_INFO'
export const GET_FUND_ORDER_RECEIPT = 'trading/GET_FUND_ORDER_RECEIPT'
export const SET_FUND_CODE_TO_SWITCHING = 'trading/SET_FUND_CODE_TO_SWITCHING'
export const REJECT_FUND_SUBSCRIPTION = 'trading/REJECT_FUND_SUBSCRIPTION'
export const SET_FUND_TRADING_PAGINATION = 'trading/SET_FUND_TRADING_PAGINATION'

// user
export const LOGIN_USER = 'user/LOGIN_USER'
export const LOGIN_CLIENT = 'user/LOGIN_CLIENT'
export const GET_PROFILE = 'user/GET_PROFILE'
export const SET_PROFILE = 'user/SET_PROFILE'
export const GET_FUND_CASH_BALANCE = 'user/GET_FUND_CASH_BALANCE'
export const GET_PENDING_ORDERS = 'user/GET_PENDING_ORDERS'
export const GET_FUND_HOLDINGS = 'user/GET_FUND_HOLDINGS'
export const GET_FUND_HOLDING = 'user/GET_FUND_HOLDING'
export const GET_FUND_ACCOUNT_VALUE_CHART = 'user/GET_FUND_ACCOUNT_VALUE_CHART'
export const GET_FUND_ACCOUNT_INFO = 'user/GET_FUND_ACCOUNT_INFO'
export const GET_IPOINT = 'user/GET_IPOINT'
export const GET_FUND_MONTHLY_STATEMENTS = 'user/GET_FUND_MONTHLY_STATEMENTS'
export const GET_CHARGE_GROUP = 'user/GET_CHARGE_GROUP'
export const GET_FUND_PENDING_CASH = 'user/GET_FUND_PENDING_CASH'
export const GET_ASSET_ALLOCATION = 'user/GET_ASSET_ALLOCATION'
export const GET_USER_FUND_STAT = 'user/GET_USER_FUND_STAT'
export const SAVE_OTP = 'user/SAVE_OTP'
export const GET_ME = 'user/GET_ME'
export const PUT_ME = 'user/PUT_ME'
export const SET_FAVOURITES = 'user/SET_FAVOURITES'
export const SET_IS_CLIENT = 'user/SET_IS_CLIENT'
export const SET_PERFORMANCE_VISIBILITY = 'user/SET_PERFORMANCE_VISIBILITY'
export const SET_BACTH_FUNDS_QUEUE = 'user/SET_BACTH_FUNDS_QUEUE'
export const GET_USER_BANK_ACCOUNT = 'user/GET_USER_BANK_ACCOUNT'
export const SET_NOTIFICATIONS = 'user/SET_NOTIFICATIONS'
export const SET_NOTIFICATIONS_PAGINATION = 'user/SET_NOTIFICATIONS_PAGINATION'
export const SET_NOTIFICATIONS_QUERY = 'user/SET_NOTIFICATIONS_QUERY'
export const SET_UNREAD_NOTIFICATIONS = 'user/SET_UNREAD_NOTIFICATIONS'
export const SET_UNREAD_NOTIFICATIONS_COUNT = 'user/SET_UNREAD_NOTIFICATIONS_COUNT'
export const SET_NOTIFICATION_AS_READ = 'user/SET_NOTIFICATION_AS_READ'
export const SET_NOTIFICATIONS_ALL_READ = 'user/SET_NOTIFICATIONS_ALL_READ'
export const LOGOUT = 'user/LOGOUT_USER'
export const GET_RPQ = 'user/GET_RPQ'
export const GET_RSP = 'user/GET_RSP'
export const GET_MAKER = 'user/GET_MAKER'
export const GET_ALLUSER = 'user/GET_ALLUSER'
export const GET_CLIENTS = 'user/GET_CLIENTS'

// user open account
export const SET_OPEN_ACCOUNT_FORM_DATA = 'openAccount/SET_OPEN_ACCOUNT_FORM_DATA'
export const SET_OPEN_ACCOUNT_JOINT_FORM_DATA = 'openAccount/SET_OPEN_ACCOUNT_JOINT_FORM_DATA'
export const GET_CURRENCIES = 'openAccount/GET_CURRENCIES'
export const GET_COUNTRIES = 'openAccount/GET_COUNTRIES'
export const GET_NATIONALITIES = 'openAccount/GET_NATIONALITIES'
export const GET_INDUSTRIES = 'openAccount/GET_INDUSTRIES'
export const GET_OCCUPATIONS = 'openAccount/GET_OCCUPATIONS'
export const SET_FORMCONTROL_PATH = 'openAccount/SET_FORMCONTROL_PATH'
export const GET_LOCATION_ADDRESS = 'openAccount/GET_LOCATION_ADDRESS'
export const GET_JOINT_ME = 'openAccount/GET_JOINT_ME'
export const CLEAR_OPEN_ACCOUNT_FORM_DATA = 'user/CLEAR_OPEN_ACCOUNT_FORM_DATA'
export const LOGOUT_JOINT_USER = 'openAccount/LOGOUT_JOINT_USER'

// errors
export const ADD_ERROR = 'errors/ADD_ERROR'
export const REMOVE_ERROR = 'errors/REMOVE_ERROR'

export const ADD_API_ERROR = 'errors/ADD_API_ERROR'
export const RM_API_ERROR = 'errors/RM_API_ERROR'
export const ADD_FORM_ERROR = 'errors/ADD_FORM_ERROR'
export const RM_FORM_ERROR = 'errors/RM_FORM_ERROR'
export const CLEAR_FORM_ERROR = 'errors/CLEAR_FORM_ERROR'

// helpers
export const SET_REDIRECT = 'helpers/SET_REDIRECT'
export const REDIRECT_TO_LOGIN = 'helpers/REDIRECT_TO_LOGIN'
export const SET_COLUMNS = 'helpers/SET_COLUMNS'
export const SHOW_MESSAGE = 'helpers/SHOW_MESSAGE'
export const HIDE_MESSAGE = 'helpers/HIDE_MESSAGE'
// fetching
export const ADD_FETCHING = 'helpers/ADD_FETCHING'
export const RM_FETCHING = 'helpers/RM_FETCHING'
export const ADD_TIMEOUT = 'helpers/ADD_TIMEOUT'
export const RM_TIMEOUT = 'helpers/RM_TIMEOUT'
export const SET_LANG = 'helpers/SET_LANG'
export const GET_ANNOUNCEMENT = 'helpers/GET_ANNOUNCEMENT'

// modal
export const OPEN_MODAL = 'modal/OPEN_MODAL'
export const CLOSE_MODAL = 'modal/CLOSE_MODAL'

// content
export const GET_POST = 'content/GET_POST'
export const GET_POSTS = 'content/GET_POSTS_CONTENT'
export const GET_PROCEDURES = 'content/GET_PROCEDURES'
export const GET_MISC_CONTENT = 'content/GET_MISC_CONTENT'
export const GET_GETTING_STARTED = 'content/GET_GETTING_STARTED'
export const GET_STATIC_CONTENT = 'content/GET_STATIC_CONTENT'
export const GET_CMS_FUND_COMPANIES = 'content/GET_CMS_FUND_COMPANIES'
export const GET_CMS_FUND_COMPANY = 'content/GET_CMS_FUND_COMPANY'
export const GET_CMS_CATEGORIES = 'content/GET_CMS_CATEGORIES'
export const UPDATE_CMS_FILTER = 'content/UPDATE_CMS_FILTER'
export const GET_CASH_TOPUP = 'content/GET_CASH_TOPUP'
export const SET_CURATED_THEME = 'content/SET_CURATED_THEME'
export const GET_CMS_PAGE = 'content/GET_CMS_PAGE'
export const GET_FAQ = 'content/GET_FAQ'
export const GET_DICTIONARY = 'content/GET_DICTIONARY'
export const GET_DISCLAIMER = 'content/GET_DISCLAIMER'
export const GET_RELATED_POSTS = 'content/GET_RELATED_POSTS'
export const GET_HOT_SEARCH_POSTS = 'content/GET_HOT_SEARCH_POSTS'
export const GET_SIDE_POSTS = 'content/GET_SIDE_POSTS'

// fund filter
export const GET_FUND_SEARCH_BASE = 'fundFilter/GET_FUND_SEARCH_BASE'
export const SET_FUND_FILTERS = 'fundFilter/SET_FUND_FILTERS'
export const RESET_FUND_FILTERS = 'fundFilter/RESET_FUND_FILTERS'
export const CLEAR_FUND_FILTERS = 'fundFilter/CLEAR_FUND_FILTERS'
export const FORMAT_FUND_FILTERS = 'fundFilter/FORMAT_FUND_FILTERS'
export const SET_FILTERED_FUND_CODES = 'fundFilter/SET_FILTERED_FUND_CODES'
export const SET_FUND_FILTER_PAGINATION = 'fundFilter/SET_FUND_FILTER_PAGINATION'
export const SET_FUND_AGGREGATES = 'fundFilter/SET_FUND_AGGREGATES'
export const CLEAR_FUND_AGGREGATES = 'fundFilter/CLEAR_FUND_AGGREGATES'

// fund search
export const SET_GLOBAL_FUND_SEARCH_CODES = 'search/SET_GLOBAL_FUND_SEARCH_CODES'
export const SET_GLOBAL_SEARCH_PAGINATION = 'search/SET_GLOBAL_SEARCH_PAGINATION'
export const SET_SEARCH_TAB = 'search/SET_SEARCH_TAB'
export const SET_GLOBAL_SEARCH_FUNDS = 'search/SET_GLOBAL_SEARCH_FUNDS'
export const SET_GLOBAL_SEARCH_ARTICLES = 'search/SET_GLOBAL_SEARCH_ARTICLES'

// image lightbox
export const SET_LIGHTBOX_IMAGES = 'content/SET_LIGHTBOX_IMAGES'
export const OPEN_LIGHTBOX = 'content/OPEN_LIGHTBOX'
export const CLOSE_LIGHTBOX = 'content/CLOSE_LIGHTBOX'

// google analytics
export const GA_SEND_EVENT = 'googleAnalytics/SEND_EVENT'

// appier analytics
export const AA_SEND_EVENT = 'appierAnalytics/SEND_EVENT'
export const AA_PUSH_EVENT = 'appierAnalytics/PUSH_EVENT'

// investment analysis
export const GET_RPDOCUTS_DATA = 'investmentAnalysis/GET_RPDOCUTS_DATA'
export const SET_AMOUNT = 'investmentAnalysis/SET_AMOUNT'
export const SET_STEP = 'investmentAnalysis/SET_STEP'
export const SET_SELECTED_RPODUCT_CODES = 'investmentAnalysis/SET_SELECTED_RPODUCT_CODES'
export const SET_PERIOD = 'investmentAnalysis/SET_PERIOD'
export const RE_EVALUATE = 'investmentAnalysis/RE_EVALUATE'
export const UPDDATE_INVESTMENT_ANALYSIS = 'investmentAnalysis/UPDATE'

// feature toggle
export const UPDATE_FEATURE_TOGGLE = 'featureToggle/UPDATE'

// Regular saving
export const GET_REGULAR_SAVEING_FUNDS = 'regularSaving/GET_FUNDS'
export const UPDATE_REGULAR_SAVEING_FUNDS_SELECTION = 'regularSaving/UPDATE_FUNDS_SELETION'
export const UPDATE_REGULAR_SAVEING_KEYWORD = 'regularSaving/UPDATE_KEYWORD'
export const GET_RSP_CONFIG = 'regularSaving/GET_RSP_CONFIG'
export const UPDATE_REGULAR_SAVEING_SELECTED_FUND_AMOUNT = 'regularSaving/UPDATE_FUND_AMOUNT'

export const GET_CSV = 'csv/GET_CSV'
export const SET_CSV_TOTAL_PAGE = 'csv/SET_CSV_TOTAL_PAGE'
export const RESET_CSV = 'csv/RESET_CSV'
