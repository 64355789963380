import React from 'react'
import PropTypes from 'prop-types'

import Dropdown from '~/components/Form/Dropdown'
import { countryCode, countryCodeOptions } from '~/utils/constants/common'

class CountryCodeDropDown extends React.PureComponent {
  state = {
    countryCode,
    countryCodeOptions
  }
  onChangeCountryCode = (countryCode) => {
    const { onChange } = this.props
    this.setState({
      countryCode
    })

    onChange && onChange(countryCode)
  }
  render () {
    const { className } = this.props
    const { countryCode, countryCodeOptions } = this.state
    return (
      <Dropdown
        className={className}
        value={countryCode}
        options={countryCodeOptions}
        onChange={value => this.onChangeCountryCode(value)}
      />
    )
  }
}

CountryCodeDropDown.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func
}

export default CountryCodeDropDown
