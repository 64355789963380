import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames/bind'

import Link from '~/components/Common/Link'
import IconHeaderMenuAngle from '~/assets/icons/headerMenuAngle.svg'
// import Banner from './Banner'

import styles from './Nav.scss'
const cx = classnames.bind(styles)

const NavItemWidth = 150

class Nav extends Component {
  state = {
    minWidth: NavItemWidth,
    hoverIdx: -1
  }
  handleMouseOver = (hoverIdx) => {
    if (this.state.hoverIdx === hoverIdx) return false
    this.setState({
      hoverIdx
    })
  }
  handleMouseOut = () => {
    this.setState({ hoverIdx: -1 })
  }

  menuAngleStyle = () => {
    const { minWidth, hoverIdx } = this.state
    let x = minWidth * (hoverIdx + 0.5)
    return {
      transform: `translateX(${x}px) rotate(180deg)`
    }
  }

  render () {
    const { navs } = this.props
    const { hoverIdx } = this.state

    return (
      <div
        className={styles.nav}
        onMouseOut={() => this.handleMouseOut()}
        data-test='globalNav'
      >
        <div className={styles.triggers}>
          {navs.map((nav, idx) =>
            <button
              key={idx}
              className={cx('item', { active: idx === hoverIdx })}
              onMouseOver={() => this.handleMouseOver(idx)}
            >
              {nav.title}
            </button>
          )}
        </div>
        <div className={styles.menuContainer}>
          <div className={styles.menu}>
            <IconHeaderMenuAngle
              className={styles.menuAngle}
              style={this.menuAngleStyle()}
            />
            {
              navs.map((nav, idx) =>
                <div
                  key={idx}
                  className={cx('menuGroup', { active: idx === hoverIdx })}
                  onMouseOver={() => this.handleMouseOver(idx)}
                >
                  <div className={styles.links}>
                    {this._renderMenuGroup(nav, idx) }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
  _renderMenuGroup = (nav, idx) => {
    // if (idx === 4) {
    //   return <Banner />
    // } else {
    return nav.menu.map((item, mIdx) =>
      <Link key={mIdx} to={item.link} className={styles.subItem}>
        <span>{item.title}</span>
      </Link>
    )
    // }
  }
}

Nav.propTypes = {
  navs: PropTypes.array
}

const mapStateToProps = (state) => ({
  lang: state.helpers.lang
})

const mapDispatchToProps = {
}

const Connect = connect(mapStateToProps, mapDispatchToProps)(Nav)
export default Connect
