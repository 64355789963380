import _isEmpty from 'lodash/isEmpty'
import { getInvestmentAnalysisResult } from '~/utils/cookies'

import {
  GET_RPDOCUTS_DATA,
  SET_AMOUNT,
  SET_STEP,
  SET_SELECTED_RPODUCT_CODES,
  SET_PERIOD,
  RE_EVALUATE,
  UPDDATE_INVESTMENT_ANALYSIS
} from '~/actions/types'

function getInitialState () {
  let step = 1
  const cookieState = getInvestmentAnalysisResult()
  if (!_isEmpty(cookieState)) {
    step = 3
  }
  return {
    step,
    amount: 1,
    fund: {},
    products: [],
    selectedProductCodes: [],
    period: 'THREE_YEARS',
    ...cookieState
  }
}

const initialState = getInitialState()

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AMOUNT: {
      return {
        ...state,
        amount: action.amount
      }
    }
    case GET_RPDOCUTS_DATA: {
      return {
        ...state,
        fund: action.fund,
        products: action.products
      }
    }
    case SET_STEP:
      return {
        ...state,
        step: action.step
      }
    case SET_SELECTED_RPODUCT_CODES: {
      return {
        ...state,
        selectedProductCodes: action.selectedProductCodes
      }
    }
    case SET_PERIOD: {
      return {
        ...state,
        period: action.period
      }
    }
    case RE_EVALUATE: {
      return {
        ...state,
        step: 1,
        amount: 1,
        selectedProductCodes: [],
        selectedFundCode: '',
        period: 'THREE_YEARS'
      }
    }
    case UPDDATE_INVESTMENT_ANALYSIS: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
