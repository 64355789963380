import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import _isString from 'lodash/isString'
import styles from './FormControl.scss'

const cx = classnames.bind(styles)

const FormControl = ({ label, extra, className, bodyClassName, children, id }) => {
  const formControlClass = cx('formControl', className)
  return (
    <section className={formControlClass} id={id}>
      {label &&
        <header className={styles.header}>
          {_isString(label)
            ? <label className={styles.label}>{label}</label>
            : label
          }
          {extra &&
            <div className={styles.extra}>
              {extra}
            </div>
          }
        </header>
      }
      <div className={cx('body', bodyClassName)}>
        {children}
      </div>
    </section>
  )
}

FormControl.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  extra: PropTypes.node,
  id: PropTypes.string
}

export default FormControl
