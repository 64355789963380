/* eslint no-unused-vars: 0 */

import _merge from 'lodash/merge'
import {
  SET_OPEN_ACCOUNT_FORM_DATA,
  GET_CURRENCIES,
  GET_COUNTRIES,
  GET_NATIONALITIES,
  GET_INDUSTRIES,
  GET_OCCUPATIONS,
  FETCHING_API,
  SET_OPEN_ACCOUNT_JOINT_FORM_DATA,
  GET_JOINT_ME,
  CLEAR_OPEN_ACCOUNT_FORM_DATA,
  LOGOUT_JOINT_USER
} from '~/actions/types'
import { getDefaultBirthDate } from '~/utils/logic/openAccount'

const initialFormData = {
  joinAccount: {
    jointUserToken: '',
    relationship: '',
    relationshipOthers: '',
    jointSignature: null
  },
  person: {
    // region: '', // 'hk', 'other'
    surname: '', // <= 100, *required
    givenName: '', // <= 100, *
    nameCh: '', // <= 100
    gender: '', // ['m', 'f']
    nationality: '', // ['HKG', 'TWN', 'ARG', ...], *
    usIdentity: null, // boolean, *
    idType: '', // <= 100, *
    inputIdType: '',
    idNum: '', // <= 100, *
    birthDate: getDefaultBirthDate(), // timestamp, *
    industry: '', // <= 3, *
    occupation: '', // <= 3, *
    education: '' // ['primary' , 'secondary', 'university'], *
  },
  sow: {
    income: false, // default false
    profit: false,
    pension: false,
    estate: false,
    invest: false,
    othersChecked: false,
    others: '' // <= 100
  },
  rpq: {
    incomeMin: '',
    netWorth: '',
    expectedInvestmentHorizon: '',
    investmentObjective: ''
  },
  phones: [
    { type: 'mobile', countryCode: '', number: '' } // countryCode + number <= 100
  ],
  address: {
    country: '', // <= 3, *
    rfb: '', // <= 100
    sac: '' // <= 100, *
    // spr: '', // <= 100
    // postcode: '' // <= 100
  },
  // bankAccount: {
  //   bankName: '', // <= 100, *
  //   bankSwift: '', // <= 11
  //   bankCountry: '', // <= 3, *
  //   bankRegion: '', // <= 100, *
  //   currency: '', // <= 3, *
  //   name: '', // <= 100, *
  //   num: '' // <= 100, *
  // },
  beneficiary: {
    name: null, // boolean, *
    sfc: null // boolean, *
  },
  tin: [{
    residence: '', // <= 3, *
    number: '', // <= 100
    reason: '', // ['1', '2', '3']
    explain: '', // <= 100
    hasTaxNumber: true
  }],
  investExp: { // [11, 6, 4, 1, 0]
    stock: '',
    derivatives: '',
    bonds: '',
    currencies: '',
    others: ''
  },
  signature: null,
  idFile: null,
  addrFile: null,
  pdfRecipient: '',
  agreement: {
    // tax: false,
    // sign: false,
    clientAgreement: false,
    // clientAgreement2: false,
    // clientAgreement3: false,
    // clientAgreement4: false,
    profileUsage: false,
    risk: false
  }
}

export const formConfig = {
  region: '',
  currencies: [],
  countries: [],
  nationalities: [],
  industries: [],
  occupations: []
}

// helpers
const getFormData = () => {
  if (typeof window !== 'undefined') {
    let _formData = window.localStorage.getItem('OPEN_ACCOUNT_FORM_DATA')
    if (_formData) {
      _formData = JSON.parse(_formData)
      return _formData
    }
  }
  return initialFormData
}

const saveFormData = (data) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('OPEN_ACCOUNT_FORM_DATA', JSON.stringify(data))
  }
}

const getJointFormData = (data) => {
  if (typeof window !== 'undefined') {
    let _formData = window.localStorage.getItem('OPEN_ACCOUNT_JOINT_FORM_DATA')
    if (_formData) {
      _formData = JSON.parse(_formData)
      return _formData
    }
  }
  return initialFormData
}

const saveJointFormData = (data) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('OPEN_ACCOUNT_JOINT_FORM_DATA', JSON.stringify(data))
  }
}

const rmJointFormData = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('OPEN_ACCOUNT_JOINT_FORM_DATA')
  }
}

const initialState = {
  formData: getFormData(),
  jointFormData: getJointFormData(),
  formConfig,
  jointMe: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_ACCOUNT_FORM_DATA:
      const { formData } = state
      const _formData = {
        ...formData,
        ...action.payload
      }
      saveFormData(_formData)
      const formDatas = _merge({}, state, {
        formData: _formData
      })
      formDatas.formData.tin = _formData.tin
      return formDatas

    case SET_OPEN_ACCOUNT_JOINT_FORM_DATA:
      const { jointFormData } = state
      const _jointFormData = _merge({}, jointFormData, action.payload)
      saveJointFormData(_jointFormData)
      return _merge({}, state, {
        jointFormData: _jointFormData
      })

    case GET_CURRENCIES:
      return _merge({}, state, {
        formConfig: { currencies: action.currencies }
      })

    case GET_COUNTRIES:
      return _merge({}, state, {
        formConfig: { countries: action.countries }
      })

    case GET_NATIONALITIES:
      return _merge({}, state, {
        formConfig: { nationalities: action.nationalities }
      })

    case GET_INDUSTRIES:
      return _merge({}, state, {
        formConfig: { industries: action.industries }
      })

    case GET_OCCUPATIONS:
      return _merge({}, state, {
        formConfig: { occupations: action.occupations }
      })

    case CLEAR_OPEN_ACCOUNT_FORM_DATA:
      let data = _merge({}, state, {
        formData: initialFormData
      })
      return data

    case LOGOUT_JOINT_USER:
      rmJointFormData()
      return {
        ...state,
        formData: {
          ...state.formData,
          joinAccount: {
            jointUserToken: '',
            relationship: '',
            relationshipOthers: '',
            jointSignature: null
          },
          agreement: {
            clientAgreement: false,
            profileUsage: false,
            risk: false
          }
        },
        jointFormData: getJointFormData(),
        jointMe: {}
      }

    case GET_JOINT_ME:
      return {
        ...state,
        jointMe: action.jointMe
      }

    default:
      return state
  }
}
