import {
  GET_FUND_SEARCH_BASE,

  SET_FUND_FILTERS,
  RESET_FUND_FILTERS,
  CLEAR_FUND_FILTERS,
  FORMAT_FUND_FILTERS,

  SET_FUND_AGGREGATES,
  CLEAR_FUND_AGGREGATES,

  SET_FILTERED_FUND_CODES,
  SET_FUND_FILTER_PAGINATION,
  SET_GLOBAL_SEARCH_KEYWORD
} from '~/actions/types'

const _initSearchBase = {
  companies: [],
  categories: [],
  fundFilterAssetClassesSectors: [],
  regions: [],
  fundFilterRegions: [],
  currencies: [],
  fundFilterCurrencies: [],
  fundFilterRisks: [],
  fundFilterTypes: [],
  order: ''
}

const _initFilters = {
  keyword: '',
  companies: [],
  categories: [],
  fundFilterAssetClassesSectors: [],
  regions: [],
  fundFilterRegions: [],
  currencies: [],
  fundFilterCurrencies: [],
  fundFilterRisks: [],
  fundFilterTypes: [],
  performancePeriod: 'cpFc1m',
  performance: [],
  annualisedReturn: [],
  volatilityFc: [],
  order: ''
}

const _initAggregates = {
  companies: {},
  categories: {}, // advance
  categoriesFilter: {}, // basic
  regions: {},
  regionsFilter: {},
  currencies: {},
  currenciesFilter: {},
  risks: {},
  risksFilter: {},
  order: ''
}

const initialState = {
  searchBase: _initSearchBase,
  filters: _initFilters,
  aggregates: _initAggregates,
  codes: [],
  pagination: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FUND_SEARCH_BASE:
      return {
        ...state,
        searchBase: action.searchBase
      }
    case SET_FUND_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    case FORMAT_FUND_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    case RESET_FUND_FILTERS:
      return {
        ...state,
        filters: {
          ..._initFilters
        }
      }
    case CLEAR_FUND_FILTERS:
      return {
        ...state,
        filters: {
          ..._initFilters,
          fundFilterTypes: []
        }
      }
    case SET_FUND_AGGREGATES:
      return {
        ...state,
        aggregates: action.aggregates
      }
    case CLEAR_FUND_AGGREGATES:
      return {
        ...state,
        aggregates: _initAggregates
      }
    case SET_FILTERED_FUND_CODES:
      return {
        ...state,
        codes: {
          ...state.codes,
          ...action.payload
        }
      }
    case SET_FUND_FILTER_PAGINATION:
      return {
        ...state,
        pagination: action.pagination
      }
    case SET_GLOBAL_SEARCH_KEYWORD:
      return {
        ...state,
        globalSearchKeyword: action.keyword
      }
    default:
      return state
  }
}
