import React, { Component, createRef } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import _flowRight from 'lodash/flowRight'

import { withRouter } from '@/router'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from '@/locales'
import { Link, Button, ContentSection, Form, Checkbox, Input } from '@/components'

import { rmApiError } from '~/actions/errorsv2'
import { loginUser } from '~/actions/user'
import { setRedirect } from '~/actions/helpers'
import { openModal, closeModal } from '~/actions/modal'
import { countryCode } from '~/utils/constants/common'

import Clickable from '~/components/Form/Clickable'
import NoticeLabel from '~/components/Form/NoticeLabel'
import ExtraFormControl from '~/components/Layout/ExtraFormControl'
import CountryCodeDropdown from '~/components/Common/CountryCodeDropdown'

import styles from './LoginUser.scss'

class LoginUser extends Component {
  constructor (props) {
    super(props)

    this.formRef = createRef()
    this.languages = ['en', 'zh-hk', 'zh-cn']

    const { intl: { formatMessage } } = props

    this.state = {
      type: 'email',
      model: {
        email: '',
        mobile: '',
        password: '',
        declaration: false
      },
      rules: {
        email: [
          { required: true, message: formatMessage({ id: 'auth.errors.inputEmail' }) },
          { type: 'email', message: formatMessage({ id: 'auth.errors.invalidEmail' }) }
        ],
        mobile: [
          { required: true, message: formatMessage({ id: 'auth.errors.inputMobile' }) }
        ],
        password: [
          { required: true, message: formatMessage({ id: 'auth.errors.inputPassword' }) }
        ],
        declaration: [
          { type: 'boolean', message: formatMessage({ id: 'auth.errors.checkDeclaration' }), validator: (rule, value) => !!value }
        ]
      },
      countryCode
    }
  }

  componentWillUnmount () {
    this.props.rmApiError('loginUser')
  }

  handleTabSelect = (index) => {
    window.location = `/${this.languages[index]}/login`
  }

  onChangeCountryCode = (countryCode) => {
    this.setState({
      countryCode
    })
  }

  changeLoginType = (type) => {
    this.formRef.current.clearErrors()
    this.setState({
      type,
      model: {
        email: '',
        mobile: '',
        password: ''
      }
    })
    this.props.rmApiError('loginUser')
  }

  handleFormChange = (field, value) => {
    this.setState({
      model: {
        ...this.state.model,
        [field]: value
      }
    })
  }

  cancel = () => {
    if (this.props.isLoginModalOpen) {
      this.props.closeModal('login')
    }
  }

  handleSubmit = () => {
    const { loginUser, onLoginUser } = this.props
    const { mobile, countryCode, type, model } = this.state
    const postData = {}

    postData.type = type

    if (type === 'mobile') {
      postData.mobile = `${countryCode}${mobile}`
      postData.password = model.password
    } else {
      postData.email = model.email
      postData.password = model.password
    }
    loginUser(postData).then(() => {
      if (!this.props.loginUserApiErr) {
        onLoginUser && onLoginUser(type)
      }
    })
  }

  render () {
    const { loginUserApiErr, isLoginModalOpen, intl: { formatMessage, locale } } = this.props
    const { type, model, rules } = this.state

    return (
      <Form model={model} rules={rules} disableScrollToError onChange={this.handleFormChange} onSubmit={this.handleSubmit} ref={this.formRef}>
        <ContentSection>
          <ContentSection.Header>
            <FormattedMessage id='auth.login' />
          </ContentSection.Header>
          <ContentSection.Body padding={'30px'}>
            {type === 'email' && this.renderEmailLogin()}
            {type === 'mobile' && this.renderMobileLogin()}
            <Checkbox field='declaration' tabIndex={3}>
              {formatMessage({ id: 'auth.login.declarationTitle' })}
            </Checkbox>
            <p className={styles.declarationContent}>
              <FormattedHTMLMessage id='auth.login.declarationContent' values={{ url: `/${locale}/resources/disclaimer/TermsOfUse` }} />
            </p>
            <NoticeLabel content={loginUserApiErr} show={!!loginUserApiErr} />
          </ContentSection.Body>

          <ContentSection.Footer justify='space-between'>
            {
              isLoginModalOpen
                ? <Clickable light onClick={this.cancel}>{formatMessage({ id: 'common.cancel' })}</Clickable>
                : <div />
            }
            <Button type='primary' htmlType='submit' loading={this.props.isLogining}>
              <FormattedMessage id='common.confirm' />
            </Button>
          </ContentSection.Footer>
        </ContentSection>
      </Form>
    )
  }
  renderEmailLogin () {
    const { intl: { formatMessage } } = this.props
    return (
      <>
        <ExtraFormControl
          label={formatMessage({ id: 'auth.email' })}
          extra={
            <Clickable
              onClick={() => this.changeLoginType('mobile')}>
              {formatMessage({ id: 'auth.loginWithMobile' })}
            </Clickable>
          }
          extraInHeader
        >
          <Input
            type='email'
            field='email'
            tabIndex={1}
            triggerValidationEvent='onBlur'
            placeholder={formatMessage({ id: 'auth.emailPlaceholder' })}
          />
        </ExtraFormControl>

        <ExtraFormControl
          label={formatMessage({ id: 'auth.password' })}
          extra={<Link type='primary' url='/forgot-password'>{formatMessage({ id: 'auth.forgotPassword' })}</Link>}
          extraInHeader
        >
          <Input
            tabIndex={2}
            type='password'
            field='password'
            triggerValidationEvent='onBlur'
            placeholder={formatMessage({ id: 'auth.passwordPlaceholder' })}
          />
        </ExtraFormControl>
      </>
    )
  }
  renderMobileLogin () {
    const { intl: { formatMessage } } = this.props
    return (
      <>
        <ExtraFormControl
          label={formatMessage({ id: 'auth.mobile' })}
          extra={<Clickable onClick={() => this.changeLoginType('email')}>{formatMessage({ id: 'auth.loginWithEmail' })}</Clickable>}
          extraInHeader
        >
          <div className={styles.mobileInputWrapper}>
            <CountryCodeDropdown onChange={this.onChangeCountryCode} />
            <Input
              type='tel'
              field='mobile'
              triggerValidationEvent='onBlur'
              tabIndex={1}
              className={styles.mobileInput}
              errorClassName={styles.mobileError}
              placeholder={formatMessage({ id: 'auth.mobile' })}
            />
          </div>
        </ExtraFormControl>

        <ExtraFormControl
          label={formatMessage({ id: 'auth.password' })}
          extra={<Link type='primary' url='/forgot-password'>{formatMessage({ id: 'auth.forgotPassword' })}</Link>}
          extraInHeader
        >
          <Input
            tabIndex={2}
            type='password'
            field='password'
            triggerValidationEvent='onBlur'
            placeholder={formatMessage({ id: 'auth.passwordPlaceholder' })}
          />
        </ExtraFormControl>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isLogining: state.helpers.fetching.loginUser,
  loginUserApiErr: state.errorsv2.api.loginUser,
  formErr: state.errorsv2.form.loginUser,
  isLoginModalOpen: state.modal.modalOpenStatus.login
})
const mapDispatchToProps = {
  openModal,
  closeModal,
  loginUser,
  rmApiError,
  setRedirect
}

LoginUser.propTypes = {
  onLoginUser: PropTypes.func
}

export default _flowRight(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginUser)
