import React from 'react'
import { Helmet } from 'react-helmet'
import { getSiteTitle } from '~/utils/locales'

const SiteTitle = ({ extras, title }) => (
  <Helmet>
    {extras}
    <meta name='description' content={title} />
    <title>{getSiteTitle(title)}</title>
  </Helmet>
)

export default SiteTitle
