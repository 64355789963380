import React from 'react'
import PropTypes from 'prop-types'
import t from '~/utils/locales'

// import { withLanguage, withComponent } from '~/hoc'
import { withComponent } from '~/hoc'
import IconLogo from '~/assets/icons/ifundplus-logo-2020.svg'

const defaultLogo = {
  type: 'svg',
  asset: IconLogo
  // assets: {
  //   en: LogoEn,
  //   cn: LogoCn
  // }
}

class Logo extends React.Component {
  render () {
    // const { lang, logo } = this.props
    const { logo } = this.props
    // const { type, assets } = logo
    // const asset = lang === 'en' ? assets.en : assets.cn
    const { type, asset } = logo

    if (type === 'img') {
      return <img src={asset} alt={t('title')} />
    }
    return withComponent(asset)
  }
}

Logo.propTypes = {
  logo: PropTypes.object
}

Logo.defaultProps = {
  logo: defaultLogo
}

// export default withLanguage(Logo)
export default Logo
