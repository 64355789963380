import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import IconChecked from '~/assets/icons/checkboxChecked.svg'
import IconUnchecked from '~/assets/icons/checkboxUnchecked.svg'
import IconDisabled from '~/assets/icons/checkboxDisabled.svg'
import IconCheckedDisabled from '~/assets/icons/checkboxCheckedDisabled.svg'
import styles from './Checkbox.scss'

const cx = classnames.bind(styles)

const Checkbox = ({ value, label, disabled, onChecked, className, border }) => {
  const checkboxClass = cx({
    'checkboxWrapper': true,
    'disabled': disabled,
    [className]: className,
    border
  })
  const checked = value
  return (
    <label className={checkboxClass}>
      <div className={styles.checkbox}>
        <input
          className={styles.checkboxInput}
          type='checkbox'
          checked={checked}
          onChange={e => onChecked && onChecked(e.target.checked)}
          disabled={disabled}
        />
        <div className={styles.icons}>
          {!disabled && checked && <IconChecked className={cx('icon', 'checked')} />}
          {!disabled && !checked && <IconUnchecked className={cx('icon', 'unchecked')} />}
          {disabled && checked && <IconCheckedDisabled className={cx('icon', 'checkedDisabled')} />}
          {disabled && !checked && <IconDisabled className={cx('icon', 'disabled')} />}
        </div>
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </label>
  )
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  onChecked: PropTypes.func,
  className: PropTypes.string,
  border: PropTypes.bool
}

export default Checkbox
