import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import t from '~/utils/locales'
import { logout } from '~/actions/user'

import AngleDropdownBox from '~/components/Common/AngleDropdownBox'
import Link from '~/components/Common/Link'
import Clickable from '~/components/Form/Clickable'

import AvatarIcon from '~/assets/icons/avatar.svg'

import styles from './UserMenu.scss'

class UserMenu extends PureComponent {
  checkLogin = (e, url) => {
    this.props.setRedirect(url)
  }
  render () {
    return (
      <div className={styles.userMenu}>
        <AvatarIcon className={styles.avatar} />
        <AngleDropdownBox className={styles.menu} show angleRight={15}>
          <ul className={styles.links}>
            <li><Link to='/user/setting' className={styles.item}>{t('userPanel.userCenter')}</Link></li>
            <li><Clickable className={styles.item} onClick={this.props.logout}>{t('userPanel.logout')}</Clickable></li>
          </ul>
        </AngleDropdownBox>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
