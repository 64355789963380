import { createSelector } from 'reselect'
import queryString from 'query-string'
import _compact from 'lodash/compact'
import _get from 'lodash/get'
import _find from 'lodash/find'

const favFundCodesSelector = state => state.user.favourites
const searchResultFundCodesSelector = state => state.fund.search.fundCodes
const fundpoolSelector = state => state.fund.fundpool
const searchpoolSelector = state => state.fund.searchpool
const meSelector = state => state.user.me

export const fundSelector = (state, code) => _get(state.fund.fundpool, code, {})

export const fundsByCodesSelector = (state, codes) => {
  const fundpool = state.fund.fundpool
  return _compact(codes.map(code => fundpool[code]))
}

export const favFundsSelector = createSelector(
  fundpoolSelector,
  favFundCodesSelector,
  (fundpool, codes) => {
    return _compact(codes.map(code => fundpool[code]))
  }
)

export const searchResultFundsSelector = createSelector(
  searchpoolSelector,
  searchResultFundCodesSelector,
  (searchpool, codes) => {
    return _compact(codes.map(code => searchpool[code]))
  }
)

export const fundHoldingByCodeSelector = (state, fundCode, type = '') => {
  const fundHoldings = state.user.fundHoldings[type]
  return _find(fundHoldings, { fund: { fundCode } }) || {}
}

export const userFundStatSelector = (state, code) => {
  const { userFundStats } = state.user
  return _get(userFundStats, code, {})
}

export const isUserVerifiedSelector = createSelector(
  meSelector,
  me => {
    return !!me.emailVerifiedAt || !!me.mobile
  }
)

export const getQuery = (location, key) => {
  const query = queryString.parse(location.search)
  if (key) {
    return query[key] || ''
  }
  return query
}
