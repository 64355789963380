import {
  GET_FUND_ORDER_INITIAL_CHARGE,
  GET_FUND_ORDER_CONFIRM_INFO,
  GET_FUND_ORDER_RECEIPT,
  SET_FUND_CODE_TO_SWITCHING,
  GET_FUND_ORDERS,
  SET_FUND_TRADING_PAGINATION
} from '~/actions/types'

const initialState = {
  fundCodeCompany: '',
  fundCodeToSwitching: '', // only storage once during trading flow, delete on unmount
  fundOrderInitialCharge: {}, // only storage once during trading flow, delete on unmount
  fundOrderConfirmInfoBase: {}, // only storage once during trading flow, delete on unmount
  receipt: {}, // only storage once during trading flow, delete on unmount,
  orders: [],
  pagination: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FUND_ORDER_INITIAL_CHARGE:
      return {
        ...state,
        fundOrderInitialCharge: action.fundOrderInitialCharge
      }
    case GET_FUND_ORDER_CONFIRM_INFO:
      return {
        ...state,
        fundOrderConfirmInfoBase: action.fundOrderConfirmInfoBase
      }
    case GET_FUND_ORDER_RECEIPT:
      return {
        ...state,
        receipt: action.receipt
      }
    case SET_FUND_CODE_TO_SWITCHING:
      return {
        ...state,
        fundCodeCompany: action.fund.company.code,
        fundCodeToSwitching: action.fund.code
      }
    case SET_FUND_TRADING_PAGINATION:
      return {
        ...state,
        pagination: action.pagination
      }
    case GET_FUND_ORDERS:
      return {
        ...state,
        orders: action.orders
      }
    default:
      return state
  }
}
