import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _isEmpty from 'lodash/isEmpty'

import { getMe, genOTP } from '~/actions/user'
import t from '~/utils/locales'

import Dropdown from '~/components/Form/Dropdown'
import Button from '~/components/Form/Button'
import NoticeLabel from '~/components/Form/NoticeLabel'
import FormControl from '~/components/Layout/FormControl'

import { getOTPCountDown, setOTPCountDown } from '~/utils/storage'

import styles from './LoginOtpSender.scss'

class LoginOtpSender extends Component {
  constructor (props) {
    super(props)
    this.state = {
      countDown: 60,
      isOtpSent: false,
      lock: false,
      type: '',
      otpOprions: []
    }
  }

  componentDidMount () {
    this.handelOtpOptions()
    const newType = this.handelType()
    this.initCountDown(newType)
  }

  componentWillUnmount () {
    clearInterval(this.countDownTimer)
  }

  componentDidUpdate (prevProps, prevState) {
    const { mobile, email, otpMethods } = this.props.me
    const prevMe = prevProps.me

    if (
      mobile !== prevMe.mobile ||
      email !== prevMe.email ||
      JSON.stringify(otpMethods) !== JSON.stringify(prevMe.otpMethods)
    ) {
      this.handelOtpOptions()

      const newType = this.handelType()
      if (prevState.type !== newType) {
        this.initCountDown(newType)
      }
    }
  }

  initCountDown = (type) => {
    if (type) {
      let time = getOTPCountDown(type)
      let walkedSeconds = Math.floor((new Date().getTime() - time) / 1000)
      let leftSeconds = 60 - walkedSeconds
      if (leftSeconds > 0 && leftSeconds < 60) {
        this.setState({ countDown: leftSeconds, lock: true })
        this.countDownTimer = setInterval(this.updateCountDown, 1000)
        return true
      }
    }
    return false
  }

  handelType () {
    const { me: { mobile = null, email = null }, onChangeType } = this.props
    let type = ''
    if (!_isEmpty(mobile)) {
      type = 'sms'
    } else if (!_isEmpty(email)) {
      type = 'email'
    }
    if (type !== this.state.type) {
      this.setState({ type })
      onChangeType(type)
    }
    return type
  }

  handelOtpOptions = () => {
    const { me: { mobile, email, otpMethods = ['mobile', 'email'] } } = this.props

    const otpOprions = []
    if (mobile) {
      // if mobile is registered, add this option anyway --
      otpOprions.push({ value: 'sms', label: t('otp.selectMobile', mobile) })
    }
    if (email && (otpMethods.includes('email') || !mobile)) {
      // if allow email otp or no mobile registered, add email option --
      otpOprions.push({ value: 'email', label: t('otp.selectEmail', email) })
    }

    this.setState({ otpOprions })
  }

  onChangeType = (type) => {
    this.setState({ type }, () => {
      this.props.onChangeType(type)
    })
  }

  genOTP = () => {
    const { type } = this.state

    if (!this.initCountDown(type)) {
      const { me, onSendOTP, apiError } = this.props
      const identifier = type === 'email' ? me.email : me.mobile
      this.setState({
        isOtpSent: false,
        lock: true
      })
      this.props.genOTP({
        type,
        action: 'pwd',
        identifier
      }).then(() => {
        if (apiError) {
          this.setState({
            lock: false,
            isOtpSent: false
          })
        } else {
          setOTPCountDown(this.state.type, new Date().getTime())
          onSendOTP && onSendOTP(type)
          this.countDownTimer = setInterval(this.updateCountDown, 1000)
        }
      })
    }
  }

  updateCountDown = () => {
    const { countDown } = this.state
    const nextTick = countDown >= 1 ? (countDown - 1) : 0
    if (nextTick <= 0) {
      this.setState({
        countDown: 60,
        lock: false
      })
      clearInterval(this.countDownTimer)
    } else {
      this.setState({ countDown: nextTick })
    }
  }

  render () {
    const { apiError } = this.props
    const { lock, type, isOtpSent, countDown, otpOprions } = this.state
    const signleMethod = otpOprions.length <= 1

    return (
      <FormControl label={t(signleMethod ? 'otp.method' : 'otp.chooseMethod')}>
        <div className={styles.wrapper}>
          {signleMethod
            ? <span className={styles.singleOtpType}>{{ ...otpOprions[0] }.label ?? ' ' }</span>
            : <Dropdown disabled={lock}
                className={styles.otpType}
                value={type}
                options={this.getOtpOptions()}
                onChange={this.onChangeType} />}
          <Button theme='primary'
            onClick={this.genOTP}
            disabled={lock}
            className={styles.button}
          >
            {lock ? <span className={styles.countDown}>{t('otp.countDown', countDown)}</span> : t('otp.send')}
          </Button>
        </div>

        <NoticeLabel show={!!apiError} content={apiError} />
        <NoticeLabel
          show={isOtpSent && !apiError}
          theme='info'
          content={type === 'email' ? t('otp.sentToUserEmail') : t('otp.sentToUserMobile')}
        />
      </FormControl>
    )
  }
}

LoginOtpSender.propTypes = {
  onSendOTP: PropTypes.func // callback (type)
}

const mapStateToProps = state => ({
  apiError: state.errorsv2.api.genOTP,
  me: state.user.me
})
const mapDispatchToProps = {
  genOTP,
  getMe
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginOtpSender)
