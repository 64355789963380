import React from 'react'
import classnames from 'classnames/bind'

import styles from './ExtraFormControl.scss'
const cx = classnames.bind(styles)

const ExtraFormControl = ({ className, label, largeLabel, children, extra, extraInHeader, extraInFooter }) =>
  <section className={cx('extraFormControl', className)}>
    {(label || (extra && extraInHeader)) &&
      <header className={styles.header}>
        {label && <label className={cx('label', { largeLabel })}>{label}</label>}
        {extra && extraInHeader &&
          <div className={cx('extra', { extraInHeader })}>
            {extra}
          </div>
        }
      </header>
    }
    <div className={styles.body}>
      <div className={styles.main}>
        {children}
      </div>
      {extra &&
        <div className={cx('extra', { extraInFooter })}>
          {extra}
        </div>
      }
    </div>
  </section>

export default ExtraFormControl
