import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '@/router'
import { withoutSSR, withLoginStatus } from '~/hoc'
import { logout } from '~/actions/user'
import { openModal } from '~/actions/modal'
import { setRedirect, setLang } from '~/actions/helpers'
import t from '~/utils/locales'

import Link from '~/components/Common/Link'
import Clickable from '~/components/Form/Clickable'
import MobileSearchModal from '~/components/Modal/MobileSearch'
import LocaleToggler from '../LocaleToggler'
import Notifications from './Notifications'
import UserMenu from './UserMenu'

import AvatarIcon from '~/assets/icons/avatar.svg'
import SearchIcon from '~/assets/icons/searchBlue.svg'

import styles from './UserPanel.scss'

class UserPanel extends Component {
  state = {
    show: true,
    showLocales: true
  }

  openModal = (e, modal) => {
    if (!e.metaKey) {
      // TODO window will use ctrlKey
      this.props.openModal(modal)
      return e.preventDefault()
    }
  }

  // temporiray add for UAT, refactor needed
  redirectLangRoute = (langPath) => {
    const { pathname, search } = this.props.location

    const to = pathname.replace(/(zh-hk|zh-cn|en)/, langPath) + search
    if (typeof window !== 'undefined') {
      this.props.setLang(langPath)
      window.location.replace(to)
    }
  }

  render () {
    return (
      <div className={styles.userPanel}>
        <Clickable
          onClick={() => this.props.openModal('mobileSearch')}
          className={styles.mobileSearch}
        >
          <SearchIcon />
        </Clickable>
        <LocaleToggler className={styles.localeToggler} />
        {this.props.isLoginUser()
          ? (
            <Fragment>
              <Notifications />
              <UserMenu showMenu={false} />
            </Fragment>
          ) : (
            <Fragment>
              <Link
                to='/login'
                className={styles.userMenu}
                onClick={e => this.openModal(e, 'login')}
                dataTest='loginLink'
                ariaLabel={t('login.login')}
              >
                <AvatarIcon className={styles.avatar} />
              </Link>
              <Link
                to='/login'
                className={styles.login}
                onClick={e => this.openModal(e, 'login')}
                primary
              >
                {t('login.login')}
              </Link>
            </Fragment>
          )
        }
        <MobileSearchModal />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lang: state.helpers.lang
})

const mapDispatchToProps = {
  openModal,
  logout,
  setRedirect,
  setLang
}

export default withoutSSR(withLoginStatus(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPanel))))
