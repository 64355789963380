import _reduce from 'lodash/reduce'
import _get from 'lodash/get'
import _find from 'lodash/find'

// parse headers pagination to { pagination: { currentPage, pageCount, perPage, totalCount } }
export const parseHeaders = (headers) => {
  const pagination = {}
  if (headers) {
    headers.forEach((value, key) => {
      key = key.toLowerCase()
      if (key.match(/^x-pagination-/i)) {
        const _key = key.replace(/^x-pagination-/i, '').replace(/-(\w)/g, (match, $1) => $1.toUpperCase())
        pagination[_key] = parseInt(value, 10)
      }
    })
  }
  headers.pagination = pagination
  return headers
}

export const qs = {
  parse (search) {
    search = search.replace(/^\?/, '')
    const query = _reduce(search.split('&'), (query, item) => {
      const [key, value] = item.split('=')
      query[key] = decodeURIComponent(value)
      return query
    }, {})
    return query
  },
  stringify (query) {
    let search = _reduce(Object.keys(query), (search, key) => {
      search += `${key}=${query[key]}&`
      return search
    }, '')
    search = search.replace(/&$/, '')
    return search
  }
}

export const getApiErrMsg = (e) => {
  return _get(e, ['error', 'fieldData', 0, 'message']) || _get(e, ['error', 'message']) || ''
}

export const getApiErrMsgByField = (e, field) => {
  const errors = _get(e, ['error', 'fieldData'])

  let message = ''

  if (errors && errors.length > 0) {
    let error = _find(errors, err => err.field === field)
    if (error) {
      message = error.message
    } else {
      message = errors[0].message
    }
  }

  return message || _get(e, ['error', 'message']) || e.toString()
}
