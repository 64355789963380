import moment from 'moment'
import t from '~/utils/locales'

export const type = {
  '': t('notifications.allCategories'),
  'notification': t('notifications.notification'),
  'post': t('notifications.post'),
  'fund': t('notifications.fund'),
  'account': t('notifications.account'),
  'buy': t('notifications.buy'),
  'sell': t('notifications.sell'),
  'switchBuy': t('notifications.switchBuy'),
  'switchSell': t('notifications.switchSell'),
  'rspBuy': t('notifications.rspBuy'),
  'exchange': t('notifications.exchange'),
  'withdraw': t('notifications.withdraw'),
  'addValue': t('notifications.addValue')
}

export const typeOptions = Object.keys(type).map(value => {
  return { value, label: type[value] }
})

const dateSubstract = function (amount, unit) {
  return moment().subtract(amount, unit).format('YYYY-MM-DD')
}

export const timeRangeOptions = [
  { label: t('notifications.timeRange'), value: '' },
  { label: t('notifications.1m'), value: dateSubstract(1, 'months') },
  { label: t('notifications.3m'), value: dateSubstract(3, 'months') },
  { label: t('notifications.6m'), value: dateSubstract(6, 'months') },
  { label: t('notifications.1y'), value: dateSubstract(1, 'years') }
]
