import './polyfill'

import * as React from 'react'
import ReactDom from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from '@/router'
import { CookiesProvider } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { IntlProvider, getB2BLocale } from '@/locales'

import { getPreferLanguage } from '~/utils/preferLanguageUtils'
import { getEnvVar } from '~/utils/env'

import cookies from './cookies'
import store from './store'

const tagManagerArgs = {
  gtmId: getEnvVar('SERVER_ENV_GTM_ACCOUNT')
}

TagManager.initialize(tagManagerArgs)

const lang = getPreferLanguage()
const appLocale = getB2BLocale(lang)

const render = App => {
  ReactDom.render(
    <IntlProvider locale={lang} messages={appLocale.messages}>
      <CookiesProvider cookies={cookies}>
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </CookiesProvider>
    </IntlProvider>,
    document.getElementById('root')
  )
}

render(require('./App').default)

// hot reload App when files are changed
if (module.hot) {
  module.hot.accept('./App', () => {
    console.log('[hot reload]') /* eslint-disable-line no-console */
    const App = require('./App').default
    render(App)
  })
}
