export const STOCK_SERVICE = Object.freeze({ name: 'stock-service', version: 'v1' })
export const CORE_SERVICE = Object.freeze({ name: 'core-service', version: 'v2' })
export const CONTENT_SERVICE = Object.freeze({ name: 'content-service', version: 'v1' })
export const FUND_TRADING_SERVICE = Object.freeze({ name: 'fund-trading-service', version: 'v1' })
export const FUND_ACCOUNT_SERVICE = Object.freeze({ name: 'fund-account-service', version: 'v1' })
export const GEO_SERVICE = Object.freeze({ name: 'geo-service', version: 'v1' })
export const SEARCH_SERVICE = Object.freeze({ name: 'search-service', version: 'v1' })

export const USER_TOKEN_KEY = 'USER_TOKEN'
export const USER_TOKEN_EXPIRES_KEY = 'USER_TOKEN_EXPIRES'
export const USER_REFRESH_TOKEN_KEY = 'USER_REFRESH_TOKEN'
export const USER_REFRESH_TOKEN_EXPIRES_KEY = 'USER_REFRESH_TOKEN_EXPIRES'

export const CLIENT_TOKEN_KEY = 'CLIENT_TOKEN'
export const CLIENT_TOKEN_EXPIRES_KEY = 'CLIENT_TOKEN_EXPIRES'
export const CLIENT_TOKEN_EXPIRES_IN_KEY = 'CLIENT_TOKEN_EXPIRES_IN'
export const CLIENT_TOKEN_TO_REFRESH_IN_KEY = 'CLIENT_TOKEN_TO_REFRESH_IN'

export const JOINT_USER_TOKEN_KEY = 'JOINT_USER_TOKEN_KEY'
export const JOINT_USER_TOKEN_EXPIRES_KEY = 'JOINT_USER_TOKEN_EXPIRES_KEY'

export const ANONYMOUS_TOKEN_KEY = 'ANONYMOUS_TOKEN'
export const ANONYMOUS_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjU5NmM3ZWU0NGMwYjMzMDAwMTYxMDY3OCJ9.eyJraWQiOiI1OTZjN2VlNDRjMGIzMzAwMDE2MTA2NzgiLCJpaWQiOiIwIiwic3ViIjoiMCJ9.B2XpxaOAYZnosIAdE3Ud8Hi3IiZI3EJLzmsoKwrGqV1FdMGaiaU-5vfLlLF_fOn54IdBzLeH4M3DgJQKFEVyFblSth7xnB4oUQV9iKQSMGcDhqKF4ISAAN072MVMqK3k8zxp-4tRDE6hPJfafdhW5X3BKTNZIxh6svB4yzhX6rMRFLM_II68gMFq_oaCrbEzQQw5zyfOYVX_1aGrcb9rYAah_nPx5tg6xJHKIridRtEIxBuiybs6nAGFXdcwJ-I76uGZj9yxRM_8uUa6xsb4ti6FoEz8-yXOp_C1j5LQGBoDEj1guaxvGJ5_aAghvuvYIDtiBehrGRAhaPrgaxyKBg'
// cat's super token for test
// export const ANONYMOUS_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjU5NmM3ZWU0NGMwYjMzMDAwMTYxMDY3OCJ9.eyJpc3MiOiJodHRwOlwvXC9pZnVuZC1hcGktc3RnLmxpb25maW4uY29tXC8iLCJraWQiOiI1OTZjN2VlNDRjMGIzMzAwMDE2MTA2NzgiLCJpaWQiOiIzMzUyIiwiY2lkIjoiaTg3ODg1NzAiLCJzdWIiOiJpODc4ODU3MCIsImlhdCI6MTUyNTA4Mzk4MywiZXhwIjoxNjA5NDU5MjAwfQ.ZLFG5jo4il9Iimtvz2SUro5nsBi2PO4Lugc-v9M0JRShezGjfqp1z2Qyj0fWf1OzcafC876Jcmh8xyeip7X6mkbOjCHAMZPj1i-RaYOrGuVGfSr680e4wqIiuXc-KQIQMA-Yo-dkTcNJhEJmteJesYZ1C8ONc0XCalbJ2XAhpvjNFBiP8l90eOb49n8LFtE6PT0CkZBUirVPyXlvUhja23MwQyZ8Zhsbl0atKeV0nTpck44go69xSETxuVBaAuuWzClEJ-1Kr_Mawc85r4GXINuhwDLAFT5S7ANSd52G8U8W_65_fZY7SR6ye1dEMcA996vcTZiYiYaOfxMCm6j_Rw'

export const IS_CLIENT_KEY = 'IS_CLIENT'
