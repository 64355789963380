import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _includes from 'lodash/includes'

import { withLoginStatus } from '~/hoc'
import t from '~/utils/locales'
import { openModal } from '~/actions/modal'
import { addFavourite, rmFavourite } from '~/actions/user'

import { Spinner } from '@/components'

import FavedIcon from '~/assets/icons/faved.svg'
import UnfavedIcon from '~/assets/icons/unfaved.svg'

import styles from './Favourite.scss'

/**
 * Favourite Component to add & delete faviroute of a fund
 * getFavourites inside component because of massive duplicated call in list component
 */
class Favourite extends React.Component {
  state = {
    hasLabel: this.props.hasLabel || false
  }
  onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (this.props.isLoginUser()) {
      this.toggleFaved()
    } else {
      this.props.openModal('login', this.toggleFaved)
    }
  }
  toggleFaved = () => {
    const { favourites, fundCode, onClick } = this.props
    const remove = _includes(favourites, fundCode)

    let promise = remove
      ? this.props.rmFavourite(fundCode)
      : this.props.addFavourite(fundCode)

    promise.then((result) => {
      onClick && onClick({ fundCode, remove })
    })
  }
  render () {
    const { isFetching } = this.props
    const isFaved = _includes(this.props.favourites, this.props.fundCode)
    const displayTxt = isFaved ? t('logic.favourite.unfaved') : t('logic.favourite.faved')
    return (
      <button onClick={this.onClick} className={styles.fav} disabled={isFetching} aria-label={displayTxt}>
        {
          isFetching
            ? <Spinner size='small' />
            : isFaved
              ? <FavedIcon />
              : <UnfavedIcon />
        }
        {
          this.state.hasLabel &&
          <span className={styles.label}>{ displayTxt }</span>
        }
      </button>
    )
  }
}

Favourite.propTypes = {
  fundCode: PropTypes.string.isRequired,
  hasLabel: PropTypes.bool,
  onClick: PropTypes.func
}
const mapToProps = [
  (state, props) => {
    const code = props.fundCode
    return {
      isFetching: state.helpers.fetching[`addFavourite${code}`] ||
        state.helpers.fetching[`rmFavourite${code}`],
      favourites: state.user.favourites
    }
  },
  {
    addFavourite,
    rmFavourite,
    openModal
  }
]
export default withLoginStatus(connect(...mapToProps)(Favourite))
