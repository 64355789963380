import React from 'react'
import classnames from 'classnames/bind'

import styles from './MediaQuery.scss'

const cx = classnames.bind(styles)

// show = 's', 'm', 'l', 'm,l', 's,m,l', ...
// hide = 's', 'm', 'l', 's,l', 's,m,l', ...
// does not support using show & hide together
// display = 'inline', 'inline-block', 'block'
const MediaQuery = ({ show = '', hide = '', display = 'block', className, children }) => {
  const classNameMap = {
    'show': show,
    'hide': hide,
    [display]: display,
    [className]: className
  }
  show.split(',').forEach(size => {
    classNameMap[size] = size
  })
  hide.split(',').forEach(size => {
    classNameMap[size] = size
  })
  return (
    <div className={cx(classNameMap)}>
      {children}
    </div>
  )
}

export default MediaQuery
