import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'

import { RouterLink } from '@/router'

import { withLoginStatus } from '~/hoc'
import { matchRouteByPath } from '~/routes'
import { openModal } from '~/actions/modal'
import { setRedirect } from '~/actions/helpers'
import { langPathRegExp } from '~/utils/locales'
import { getLanguagePath } from '~/utils/urlUtils'
import ExternalLinkIcon from '~/assets/icons/externalLinkIcon.svg'

import styles from './Link.scss'
const cx = classnames.bind(styles)

class Link extends Component {
  authCheck = (e) => {
    if (e.metaKey || !this.props.authCheck) {
      return true
    }
    let { to, isLoginUser, isLoginClient, openModal, setRedirect } = this.props
    to = to.replace(langPathRegExp, '')
    const authority = _get(matchRouteByPath(to), 'authority')
    if (authority) {
      const shouldRedirectToLogin = !isLoginUser() || (!isLoginClient() && authority.indexOf('client') > -1)
      if (shouldRedirectToLogin) {
        openModal('login', () => {
          setRedirect(to)
        })
        e.preventDefault()
      }
    }
  }
  render () {
    const {
      children,
      className,
      light,
      primary,
      hover,
      onClick,
      onMouseUp,
      target,
      vcenter,
      block,
      dataTest,
      external,
      noreferrer,
      rel = '',
      ariaLabel = ''
     } = this.props
    let { to } = this.props

    if (to.match(/^http/)) {
      return (
        <a
          className={cx('link', className, { light, primary, hover })}
          onClick={onClick || this.authCheck}
          onMouseUp={onMouseUp}
          href={to}
          target={target}
          rel={noreferrer ? 'noreferrer noopener' : rel}
          aria-label={ariaLabel}
        >
          {external && <ExternalLinkIcon className={styles.externalIcon} /> }{children}
        </a>
      )
    }

    if (!_isNil(to) && !to.match(langPathRegExp)) {
      to = `${getLanguagePath()}${to}`
    }
    return (
      <RouterLink
        target={external ? '_blank' : null}
        data-test={dataTest}
        className={cx('link', className, {
          light,
          primary,
          hover,
          vcenter,
          block
        })}
        onClick={onClick || this.authCheck}
        to={to}
        aria-label={ariaLabel}
      >
        {external && <ExternalLinkIcon className={styles.externalIcon} /> }{children}
      </RouterLink>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string,
  primary: PropTypes.bool,
  light: PropTypes.bool,
  hover: PropTypes.bool,
  vcenter: PropTypes.bool,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  dataTest: PropTypes.string
}

Link.defaultProps = {
  locale: true,
  enableAuthCheck: true,
  external: false
}

const mapToProps = [
  null,
  {
    openModal,
    setRedirect
  }
]
export default withLoginStatus(connect(...mapToProps)(Link))
