import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { withRouter } from '@/router'
import _includes from 'lodash/includes'

import Link from '~/components/Common/Link'

import styles from './ContentSection.scss'
const cx = classnames.bind(styles)

class TabHeader extends React.Component {
  isActive = (path) => {
    const pathAndSearch = this.props.location.pathname + this.props.location.search
    return _includes(pathAndSearch, path)
  }
  render () {
    const { tabs, extra, className, children } = this.props
    return (
      <header className={cx('tabHeader', className)}>
        <div className={styles.tabs}>
          {tabs.map((tab, idx) =>
            <Link key={idx} to={tab.path} className={cx('item', { active: this.isActive(tab.path) })}>
              {tab.title}
            </Link>
          )}
        </div>
        <div className={styles.extra}>
          {extra || children}
        </div>
      </header>
    )
  }
}

TabHeader.propTypes = {
}

const Header = ({ title, extra, children, className, large, border, responsive }) => {
  return (
    <header className={cx('header', className, { large, border, responsive })}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.extra}>
        {extra || children}
      </div>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  large: PropTypes.bool
}

const Footer = ({ footerLeft, footerRight, children, className }) => {
  return (
    <footer className={cx('footer', className)}>
      <div className={styles.left}>
        {footerLeft}
      </div>
      {children}
      <div className={styles.right}>
        {footerRight}
      </div>
    </footer>
  )
}

/**
 * ContentSection with title & extra (not yet) & children
 */
const ContentSection = ({
  title, // title is a must for Header
  extra,
  footerLeft,
  footerRight, // right as action is a must for Footer
  theme,
  className,
  children,
  noPadding,
  smallPadding,
  border,
  dataTest
}) => {
  return (
    <div className={cx(theme, className, { noPadding, smallPadding })} data-test={dataTest}>
      {title && <Header title={title} extra={extra} border={border} />}
      {children}
      {footerRight && <Footer footerLeft={footerLeft} footerRight={footerRight} />}
    </div>
  )
}

ContentSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footerLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footerRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  theme: PropTypes.oneOf(['minimal', 'headernfooter']),
  noPadding: PropTypes.bool,
  smallPadding: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  border: PropTypes.bool,
  dataTest: PropTypes.string
}
ContentSection.defaultProps = {
  theme: 'minimal',
  noPadding: false
}

ContentSection.Header = Header
ContentSection.Footer = Footer
ContentSection.TabHeader = withRouter(TabHeader)
export default ContentSection
