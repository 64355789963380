import React from 'react'
import { connect } from 'react-redux'

export default (WrappedComponent) => {
  class WithLanguageWrapper extends React.Component {
    render () {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
        </React.Fragment>
      )
    }
  }

  const mapProps = [
    state => ({
      lang: state.helpers.lang
    }),
    {}
  ]
  return connect(...mapProps)(WithLanguageWrapper)
}
