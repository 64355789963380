import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import _get from 'lodash/get'

import { formatDate } from '~/utils/num'

import StepDoneIcon from '~/assets/icons/radioChecked.svg'

import styles from './OrderProgress.scss'
const cx = classnames.bind(styles)

class OrderProgress extends React.PureComponent {
  render () {
    const { steps, stepHeight } = this.props
    const customItemStyle = {
      minHeight: `${stepHeight}px`
    }

    return (
      steps.length === 0
        ? null
        : (
          <div>
            <ul className={styles.progress}>
              {steps.map((s, idx) => (
                <li
                  key={`step${idx}`}
                  className={cx('item', {
                    'thisStepIsDone': s.status === 'DONE',
                    'nextStepIsDone': _get(steps[idx + 1], 'status') === 'DONE'
                  })}
                  style={customItemStyle}
                >
                  <div className={styles.stepIcon}>
                    {{
                      'DONE': <StepDoneIcon />,
                      'TODO': <div className={styles.todoIcon} />
                    }[s.status]}
                  </div>
                  <div className={styles.stepLabel}>
                    <h3 className={styles.status}>
                      {s.name}
                    </h3>
                    <p className={styles.date}>
                      {s.time > 0
                        ? formatDate(s.time, 'YYYY-MM-DD')
                        : null
                      }
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
    )
  }
}
/**
 * steps from backend
 * @type [Array]
 * [{ code: 'Buy_Received', name: '申購已下單', status: 'DONE', time: 1525017600 }, ...]
 * status: ['DONE', 'TODO']
 */
OrderProgress.propTypes = {
  steps: PropTypes.array.isRequired,
  stepHeight: PropTypes.number
}
OrderProgress.defaultProps = {
  steps: [],
  stepHeight: 40
}
export default OrderProgress
