import {
  SET_REDIRECT,
  REDIRECT_TO_LOGIN,
  ADD_FETCHING,
  RM_FETCHING,
  ADD_TIMEOUT,
  RM_TIMEOUT,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  SET_COLUMNS,
  SET_LANG,
  GET_ANNOUNCEMENT
} from '~/actions/types'
import { DEFAULT_MSG_DISMISS_TIME } from '~/hoc/withMessage'

const initialState = {
  redirect: null,
  fetching: {},
  timeout: {},
  columns: {},
  lang: '',
  message: {
    show: false,
    message: '',
    theme: 'error',
    dismissTime: DEFAULT_MSG_DISMISS_TIME // default 5s, will be change for each action
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.path
      }
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirect: action.path
      }
    case ADD_FETCHING:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.endpoint]: true
        }
      }
    case RM_FETCHING:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.endpoint]: false
        }
      }
    case ADD_TIMEOUT:
      return {
        ...state,
        timeout: {
          ...state.timeout,
          [action.endpoint]: true
        }
      }
    case RM_TIMEOUT:
      return {
        ...state,
        timeout: {
          ...state.timeout,
          [action.endpoint]: false
        }
      }
    case SET_COLUMNS:
      return {
        ...state,
        columns: {
          ...state.columns,
          ...action.payload
        }
      }
    case SHOW_MESSAGE:
      return {
        ...state,
        message: {
          show: true,
          message: action.message,
          theme: action.theme,
          dismissTime: action.dismissTime
        }
      }
    case HIDE_MESSAGE:
      return {
        ...state,
        message: {
          ...state.message,
          show: false
        }
      }
    case SET_LANG:
      return {
        ...state,
        lang: action.lang
      }
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        annoucement: action.announcement
      }
    default:
      return state
  }
}
