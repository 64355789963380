import { GA_SEND_EVENT, AA_SEND_EVENT, AA_PUSH_EVENT } from '~/actions/types'

const defaultGAEventArgs = {
  hitType: 'event',
  eventAction: 'click'
}

export function sendGAEvent (args = {}) {
  return {
    type: GA_SEND_EVENT,
    payload: {
      ...defaultGAEventArgs,
      ...args
    }
  }
}

export function sendAAEvent (args = {}) {
  return {
    type: AA_SEND_EVENT,
    payload: { ...args }
  }
}

export function pushAAEvent (...args) {
  return {
    type: AA_PUSH_EVENT,
    payload: args
  }
}
