import React from 'react'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Route, Redirect } from '@/router'
import { getLocalePath } from '~/utils/urlUtils'

/*
  return `<Redirect to='/login?back=account' />`
  while url is `/login?back=account`
 */

export const RedirectRoute = ({ path, search }) => {
  return (
    <Route
      path={path}
      render={props => {
        const pathname = _get(props, 'location.pathname')
        search = search || _get(props, 'location.search')
        const to = {
          pathname: getLocalePath(pathname),
          search: search
        }
        return <Redirect to={to} />
      }}
    />
  )
}

RedirectRoute.propTypes = {
  path: PropTypes.string.isRequired,
  search: PropTypes.string
}
