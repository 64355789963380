import colors from '~/styles/colors'
import { getColor } from '~/utils/cookies'

const { a11ygreen, oceangreen, grannyapple, persianred, linen, mineshaft } = colors

export const getGreen = ({ useA11y = false }) => {
  return useA11y ? a11ygreen : oceangreen
}

export const getUpDownColors = ({ useA11y = false }) => {
  const curColor = getColor()
  const green = getGreen({ useA11y })
  if (curColor === 'redIsUp') {
    return { down: green, downBg: grannyapple, up: persianred, upBg: linen }
  }
  return { up: green, upBg: grannyapple, down: persianred, downBg: linen }
}

export const getReversedUpDownColors = ({ useA11y = false }) => {
  const curColor = getColor()
  const green = getGreen({ useA11y })
  if (curColor === 'redIsUp') {
    return { down: '#fff', downBg: green, up: '#fff', upBg: persianred }
  }
  return { up: '#fff', upBg: green, down: '#fff', downBg: persianred }
}

export const getColorBySign = ({ num, useA11y = false, fairColor = mineshaft }) => {
  const upDownColors = getUpDownColors({ useA11y })
  if (num > 0) {
    return upDownColors.up
  } else if (num < 0) {
    return upDownColors.down
  }
  return fairColor
}

export const getPerformanceLabelStyle = ({ useA11y = false }) => {
  const colorObj = getReversedUpDownColors({ useA11y })
  const upStyle = {
    color: colorObj.up,
    background: colorObj.upBg
  }
  const downStyle = {
    color: colorObj.down,
    background: colorObj.downBg
  }

  return {
    upStyle,
    downStyle
  }
}
