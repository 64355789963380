import _fetch from './_fetch'
import {
  STOCK_SERVICE,
  FUND_TRADING_SERVICE,
  CORE_SERVICE,
  CLIENT_TOKEN_KEY,
  ANONYMOUS_TOKEN_KEY
} from '~/utils/constants/api'

const Fund = {
  info: {
    get ({ id }, query = {}) {
      return _fetch({
        api: {
          service: STOCK_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: STOCK_SERVICE.version
        },
        method: 'GET',
        path: `/funds/${id}`,
        query
      })
    }
  },
  infoByCode: {
    get ({ id }, query = {}) {
      return _fetch({
        api: {
          service: STOCK_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: STOCK_SERVICE.version
        },
        method: 'GET',
        path: `/funds/${id}`,
        query
      })
    }
  },
  list: {
    get (query = {}) {
      return _fetch({
        api: {
          service: STOCK_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: STOCK_SERVICE.version
        },
        method: 'GET',
        path: '/funds',
        query
      })
    }
  },
  chart: {
    get ({ id }, query = {}) {
      return _fetch({
        api: {
          service: STOCK_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: STOCK_SERVICE.version
        },
        method: 'GET',
        path: `/funds/${id}/chart`,
        query
      })
    }
  },
  fundCompanies: {
    get ({ ...query }) {
      return _fetch({
        api: {
          service: STOCK_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: STOCK_SERVICE.version
        },
        method: 'GET',
        path: `/fundCompanies`,
        query
      })
    }
  },
  fundTransactions: {
    get (query) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        query,
        method: 'GET',
        path: '/fundTransactions'
      })
    }
  },
  fundTransactionDetail: {
    get (transRef) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'GET',
        path: `/fundTransactions/${transRef}`
      })
    }
  },
  fundTransactionContractNote: {
    get (transRef) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        method: 'GET',
        path: `/fundTransactions/${transRef}/note`
      })
    }
  },
  fundCashTransactions: {
    get (query) {
      return _fetch({
        api: {
          service: FUND_TRADING_SERVICE.name,
          tokenType: CLIENT_TOKEN_KEY,
          version: FUND_TRADING_SERVICE.version
        },
        query,
        method: 'GET',
        path: '/fundCashTransactions'
      })
    }
  },
  fundSearchBase: {
    get () {
      return _fetch({
        api: {
          service: STOCK_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: STOCK_SERVICE.version
        },
        method: 'GET',
        path: '/fundSearchBase'
      })
    }
  },
  fundComparison: {
    get (query) {
      return _fetch({
        api: {
          service: STOCK_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: STOCK_SERVICE.version
        },
        method: 'GET',
        path: '/fundCompare',
        query
      })
    }
  },
  fundTransferInDocs: {
    get (query) {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'GET',
        path: '/fundTransferInDocs',
        query
      })
    }
  },
  fundHoliday: {
    get () {
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'GET',
        path: '/fundHoliday'
      })
    }
  },
  marketIndex: {
    get (query) { // { type: 'stock', 'bond', 'other' }
      return _fetch({
        api: {
          service: CORE_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CORE_SERVICE.version
        },
        method: 'GET',
        path: '/marketIndex',
        query
      })
    }
  }
}

export default Fund
