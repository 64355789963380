import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { connect } from 'react-redux'

import { dismissMessage } from '~/actions/helpers'
import IconRemove from '~/assets/icons/remove.svg'
import t from '~/utils/locales'

import styles from './Message.scss'
const cx = classnames.bind(styles)

class Message extends React.Component {
  onClose = () => {
    this.props.dismissMessage()
  }
  shouldComponentUpdate (nextProps) {
    if (nextProps.show !== this.props.show && nextProps.show === true) {
      setTimeout(() => {
        this.props.dismissMessage()
      }, nextProps.dismissTime)
    }
    return true
  }
  render () {
    const { show, message, theme } = this.props
    return (
      <div className={cx('message', theme, { show, hide: !show })} data-test='message'>
        {message}
        <button onClick={this.onClose} className={styles.btnClose} aria-label={t('common.close')}>
          <IconRemove />
        </button>
      </div>
    )
  }
}

// all Message props are from redux store
Message.propTypes = {
  message: PropTypes.string,
  theme: PropTypes.oneOf(['error', 'info']),
  dismissTime: PropTypes.number
}
const mapToProps = [
  state => ({
    ...state.helpers.message
  }),
  {
    dismissMessage
  }
]
export default connect(...mapToProps)(Message)
