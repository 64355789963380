import _get from 'lodash/get'
import _merge from 'lodash/merge'
import _range from 'lodash/range'
import _includes from 'lodash/includes'

import { formatDate } from '~/utils'
import wheelColors from '~/styles/wheelColors'

export const getCurrenciesFromBalance = (fundCashBalance) => {
  const balances = _get(fundCashBalance, ['cashBalanceItemJsons'], [])
  return balances.map(b => b.currency)
}

/**
 * normarlize points for chart tips
 * @param  {Array} data backend /fundCompare response
 * @return {Object} {
 *   $fundCode: {
 *     $time: [
 *       {$time, $price, $performance, $currency, $compareCurrency, $compareCurrencyPrice},
 *       ...
 *     ]
 *   }
 * }
 */
export const normalizePoints = (data) => {
  const normalizedPoints = {}

  data.forEach(fund => {
    const _points = {}
    const code = _get(fund, ['fundJson', 'code'])
    const currency = _get(fund, ['fundJson', 'currency'])
    const compareCurrency = _get(fund, 'compareCurrency')

    fund.points.forEach(point => {
      const timeStamp = point.time
      _points[timeStamp] = _merge({}, point, {
        currency, compareCurrency
      })
    })

    normalizedPoints[code] = _points
  })

  return normalizedPoints
}

/**
 * constructComparisonChartDataKey
 * @param  {Number} start    Unix timeStamp without ms: 1501776000
 * @param  {Number} end      Unix timeStamp without ms: 1501776000
 * @param  {String} currency USD
 */
export const constructComparisonChartDataKey = ({ start, end, currency }) => {
  const _start = formatDate(start, 'YYYYMMDD')
  const _end = formatDate(end, 'YYYYMMDD')
  return `${_start}-${_end}-${currency || 'DEFAULT'}`
}

export const checkEmail = (email) => {
  return !!email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)
}

export const rgbToHex = (rgb) => {
  return '#' + ((rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16)
}

export const samplingPoints = (points, threshold) => {
  const ptNum = points.length

  let _points = []
  if (ptNum > threshold) {
    const step = Math.ceil(ptNum / threshold)
    const idxArr = _range(0, ptNum, step)

    points.forEach((point, idx) => {
      if (_includes(idxArr, idx)) {
        _points.push(point)
      }
    })

    return _points
  }

  return points
}

/**
 * Time related
 */
export const lastNYear = n => {
  let date = new Date()
  let day = new Date(date.getFullYear() - n, date.getMonth(), date.getDate())
  return day.getTime()
}

export const lastNMonth = n => {
  let date = new Date()
  let day = new Date(date.getFullYear(), date.getMonth() - n, date.getDate())
  return day.getTime()
}

export const monthFirstDay = () => {
  let date = new Date()
  let day = new Date(date.getFullYear(), date.getMonth(), 1)
  return day.getTime()
}

export const monthLastDay = () => {
  let date = new Date()
  let day = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return day.getTime()
}

export const today = () => {
  let day = new Date()
  return day.getTime()
}

export const todayStart = () => {
  let now = new Date()
  now.setHours(0, 0, 0, 0)
  return now.getTime()
}

export const calcAgeByBirthday = birthdayTimestamp => {
  if (!birthdayTimestamp) return birthdayTimestamp

  let today = new Date()
  let thisYear = today.getFullYear()
  let birthday = null
  // if less than 11 digits, mark as unix timestamp without ms
  if (String(birthdayTimestamp).length < 13) {
    birthday = new Date(birthdayTimestamp * 1000)
  } else {
    birthday = new Date(birthdayTimestamp)
  }
  return thisYear - birthday.getFullYear()
}

export const getCapitalColor = (num) => {
  return wheelColors.slice(0, num)
}
