import _zipObject from 'lodash/zipObject'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _pickBy from 'lodash/pickBy'
import _sortBy from 'lodash/sortBy'
import _isEmpty from 'lodash/isEmpty'
import _keys from 'lodash/keys'
import _includes from 'lodash/includes'
import _uniq from 'lodash/uniq'
import _compact from 'lodash/compact'
import _find from 'lodash/find'
import _cloneDeep from 'lodash/cloneDeep'

import {
  SET_FUND_POOL,
  SET_FUND_SEARCH_POOL,
  SET_FUND_CHART_POOL,
  SET_COMPARISON_CHART_POOL,
  GET_FUND_COMPANIES,
  GET_FUNDS_BY_COMPANY,
  GET_FUNDS_BY_COMPANYCODE,
  SET_FUNDS_BY_COMPANY_PAGINATION,
  GET_FUND_TRANSACTIONS,
  GET_FUND_TRANSACTION_DETAIL,
  GET_FUND_TRANSACTION_CONTRACT_NOTE,
  GET_FUND_CASH_TRANSACTIONS,
  SET_FUND_COMPARISON,
  GET_FUND_TRANSFER_IN_DOCS,
  GET_FUND_HOLIDAY,
  GET_MARKET_INDEX,
  GET_TOP_PERFORMANCE_FUNDS,
  GET_FUND_RELATED_TOP_PERFORMANCE_FUNDS,
  GET_HOT_BUY_FUNDS,
  SET_COMPARISON_POOL,
  SET_COMPARISON_SELECTED,
  GET_FUNDS_BY_KEYWORDS,
  GET_CURATED_FUNDS,
  RESET_SEARCH,
  GET_FUND_RANK
} from './types'

import { Fund } from '~/utils/api'
import { constructComparisonChartDataKey } from '~/utils/chart'
import { addFetching, rmFetching, addTimeout, rmTimeout } from './helpers'

const API_TIMEOUT = 1000 * 10 // 10s

/**
 * setFunds as { code: {fund} }
 * @param {[type]} funds []
 */
export const setFunds = funds => dispatch => {
  const codes = funds.map(f => f.code)
  const _funds = funds.map(f => {
    f.minTransferFc = f.minInvestInitialFc // hack for backend missing field
    return f
  })
  dispatch({
    type: SET_FUND_POOL,
    fundPoolCodes: codes,
    fundpool: _zipObject(codes, _funds)
  })
}

/**
 * setSearchFunds as { code: {fund} }
 * @param {[type]} funds []
 */
export const setSearchFunds = funds => dispatch => {
  const codes = funds.map(f => f.code)
  const _funds = funds.map(f => {
    f.minTransferFc = f.minInvestInitialFc // hack for backend missing field
    return f
  })
  dispatch({
    type: SET_FUND_SEARCH_POOL,
    searchpool: _zipObject(codes, _funds)
  })
}

/** private
 * setFundCharts as { code: { period: {chart} } }
 * @param {String} fundCode
 * @param {String} chartType
 * @param {Array}  data
 */
const setFundCharts = ({ fundCode, chartType, data }) => (dispatch, getState) => {
  const chartpool = Object.assign({}, getState().fund.chartpool)
  if (chartpool[fundCode]) {
    chartpool[fundCode][chartType] = data
  } else {
    chartpool[fundCode] = { [chartType]: data }
  }

  dispatch({
    type: SET_FUND_CHART_POOL,
    chartpool
  })
}

/** private
 * setFundCharts as { code: { period: {chart} } }
 * @param {Array}  fundCodes
 * @param {Numner} start
 * @param {Numner} end
 * @param {String} currency
 * @param {Object} data
 */
const setComparisonChartPool = ({ fundCodes, start, end, currency, data }) => (dispatch, getState) => {
  const comparisonChartpool = Object.assign({}, getState().fund.comparisonChartpool)

  fundCodes.forEach(fundCode => {
    const _data = _find(data, d => d.fundJson.code === fundCode)

    const timePeriodCurrencyBasedKey = constructComparisonChartDataKey({ start, end, currency })

    if (comparisonChartpool[fundCode]) {
      comparisonChartpool[fundCode][timePeriodCurrencyBasedKey] = _data
    } else {
      comparisonChartpool[fundCode] = { [timePeriodCurrencyBasedKey]: _data }
    }
  })

  dispatch({
    type: SET_COMPARISON_CHART_POOL,
    comparisonChartpool
  })
}

/**
 * get single fund
 * @param  {String} code fundCode
 */
export const getFund = code => (dispatch) => {
  const endpoint = 'getFund'
  dispatch(addFetching(endpoint))

  return Fund.infoByCode.get({ id: 0 }, { fundCode: code })
    .then(({ data: fund }) => {
      dispatch(setFunds([fund]))
      dispatch(rmFetching(endpoint))
    })
}

/**
 * get curate fund
 */
export const getCuratedFunds = ({
  index,
  fundCodes
}) => (dispatch) => {
  const endpoint = 'getCuratedFunds'
  dispatch(addFetching(endpoint))

  return Fund.list.get({ fundCodes: fundCodes.join(','), size: fundCodes.length }) // size default is 5
    .then(({ data: funds }) => {
      dispatch(rmFetching(endpoint))
      dispatch({
        type: GET_CURATED_FUNDS,
        index: index,
        funds: funds
      })
    })
}

/**
 * getFundCompanies
 */
export const getFundCompanies = () => (dispatch, getState) => {
  const { fundCompanies } = getState().fund
  const endpoint = 'getFundCompanies'
  dispatch(addFetching(endpoint))
  // if has data in store no need to call API
  if (!_isEmpty(fundCompanies)) {
    dispatch(rmFetching(endpoint))
    return Promise.resolve()
  }

  return Fund.fundCompanies.get()
    .then(({ data: fundCompanies }) => {
      const fundCompanyCodes = _compact(fundCompanies.map(
        company => company.code && company.code.toLowerCase()
      ))
      dispatch(rmFetching(endpoint))
      dispatch({
        type: GET_FUND_COMPANIES,
        fundCompanies,
        fundCompanyCodes
      })
    })
}

/**
 * get multiple funds that not in fund pool by fundCodes
 * @param  {Array} fundCodes [123456, 123457, 123458]
 */
export const getFundList = (query) => (dispatch, getState) => {
  const { fundCodes } = query

  const endpoint = 'getFundList'
  dispatch(addFetching(endpoint))

  const { fundpool } = getState().fund
  let codesNotInPool = []
  fundCodes.forEach((code) => {
    if (!_includes(_keys(fundpool), code)) {
      codesNotInPool.push(code)
    }
  })
  if (_isEmpty(codesNotInPool) && !query.order) {
    dispatch(rmFetching(endpoint))
    return true // has all funds in the pool
  }
  if (query.order) {
    codesNotInPool = fundCodes
  }

  return Fund.list.get({ ...query, fundCodes: codesNotInPool.join(',') })
    .then(({ data: funds }) => {
      dispatch(rmFetching(endpoint))
      dispatch(setFunds(funds))
      return funds
    })
}

/**
 * search Funds width pagination, do not store
 * dynamicPerformanceRank will heavily slow down the response
 * so set false as default, set true when dynamicPerformanceRank is needed
 */
export const searchFundsByKeyword = ({ q, dynamicPerformanceRank = false, page = 1, size = 20 }) => (dispatch, getState) => {
  const endpoint = 'searchFundsByKeyword'
  dispatch(addFetching(endpoint))

  const currentSearchStore = getState().fund.search
  const query = { size, q, dynamicPerformanceRank, page }
  return Fund.list.get(query)
    .then(({ data: funds, headers }) => {
      dispatch(setSearchFunds(funds))
      dispatch(setFunds(funds))
      const fundCodes = page > 1 // only if pagination load more, concat results to original code array
        ? _uniq(currentSearchStore.fundCodes.concat(funds.map(fund => fund.code)))
        : funds.map(fund => fund.code)
      dispatch({
        type: GET_FUNDS_BY_KEYWORDS,
        data: {
          pagination: headers.pagination,
          fundCodes,
          q
        }
      })
      dispatch(rmFetching(endpoint))
    })
    .catch(e => {
      dispatch(rmFetching(endpoint))
    })
}

export const resetSearch = () => dispatch => {
  dispatch({ type: RESET_SEARCH })
}

/**
 * getFundsByCompany with pagination
 * @param  {String} companyId
 */
export const getFundsByCompany = ({ companyCode, page = 1, size = 20, order = '' }) => (dispatch, getState) => {
  const endpoint = 'getFundsByCompany'
  const { companyFundsMap } = getState().fund
  dispatch(addFetching(endpoint))

  return Fund.list.get({ companyCode, page, size, order })
    .then(({ data: funds, headers }) => {
      const { pagination } = headers
      dispatch(setFunds(funds))
      const prevFundCodes = _get(companyFundsMap, [companyCode, 'fundCodes'], {})
      const currentfundCodes = funds.map(f => f.code)
      const fundCodes = Object.assign({}, prevFundCodes, {
        [page]: currentfundCodes
      })

      dispatch({
        type: GET_FUNDS_BY_COMPANYCODE,
        companyCode,
        fundCodes
      })
      dispatch({
        type: SET_FUNDS_BY_COMPANY_PAGINATION,
        companyCode,
        pagination
      })
      dispatch(rmFetching(endpoint))
    })
}

// TODO: need refactor for setFunds
export const getAllFundsByCompany = ({ companyId }) => (dispatch) => {
  const endpoint = 'getAllFundsByCompany'
  dispatch(addFetching(endpoint))

  const size = 20
  let funds = []
  return Fund.list.get({ companyId, size })
    .then(({ data: _funds, headers }) => {
      const { pagination } = headers
      const tasks = []
      funds = _funds
      for (let page = 2; page <= pagination.pageCount; page++) {
        tasks.push(Fund.list.get({ companyId, size, page }))
      }
      if (tasks.length) {
        // data is more than one page
        Promise.all(tasks).then(values => {
          values.forEach(({ data }) => {
            funds = [...funds, ...data]
          })

          dispatch(setFunds(funds))
          const fundCodes = _uniq(funds.map(f => f.code))
          // set all fundCodes from one company at once
          // no need to set pagination
          dispatch({
            type: GET_FUNDS_BY_COMPANY,
            companyId,
            fundCodes
          })
          dispatch(rmFetching(endpoint))
        })
      } else {
        // data is in one page
        dispatch(setFunds(funds))
        const fundCodes = _uniq(funds.map(f => f.code))
        dispatch({
          type: GET_FUNDS_BY_COMPANY,
          companyId,
          fundCodes
        })
        dispatch(rmFetching(endpoint))
      }
    })
}

/**
 * get Client's fund transactions history
 * @param  {Object} query { from, to }
 */
export const getFundTransactions = (query) => (dispatch) => {
  const endpoint = 'getFundTransactions'
  dispatch(addFetching(endpoint))

  return Fund.fundTransactions.get(query)
    .then(({ data: txs }) => {
      // fundTransaction API 如返回 isSwitch true 则 transType 为 XS 或 XB
      const _txs = txs.map(tx => {
        if (tx.isSwitch) {
          const _transType = tx.transType
          tx.transType = tx.transType.indexOf('X') < 0 ? `X${_transType}` : _transType
        }
        return tx
      })

      dispatch({
        type: GET_FUND_TRANSACTIONS,
        fundTransactions: _txs
      })
      dispatch(rmFetching(endpoint))
    })
}

/**
 * getFundTransactionDetail
 * @param  {String} transRef
 */
export const getFundTransactionDetail = (transRef) => (dispatch) => {
  const endpoint = 'getFundTransactionDetail'
  dispatch(addFetching(endpoint))

  return Fund.fundTransactionDetail.get(transRef)
    .then(({ data }) => {
      // fundTransaction API 如返回 isSwitch true 则 transType 为 XS 或 XB
      if (data.isSwitch) {
        const _transType = data.transType
        data.transType = data.transType.indexOf('X') < 0 ? `X${_transType}` : _transType
      }
      if (data.switchBuyTransaction && data.switchBuyTransaction.isSwitch) {
        const _transType = data.switchBuyTransaction.transType
        data.switchBuyTransaction.transType = _transType.indexOf('X') < 0 ? `X${_transType}` : _transType
      }

      dispatch({
        type: GET_FUND_TRANSACTION_DETAIL,
        transRef: transRef,
        fundTransactionDetail: data
      })
      dispatch(rmFetching(endpoint))

      return data
    })
}

/**
 * getFundTransactionContractNote
 * @param  {String} transRef
 */
export const getFundTransactionContractNote = (transRef) => (dispatch) => {
  const endpoint = 'getFundTransactionContractNote'
  dispatch(addFetching(endpoint))

  return Fund.fundTransactionContractNote.get(transRef)
    .then(({ data }) => {
      dispatch({
        type: GET_FUND_TRANSACTION_CONTRACT_NOTE,
        transRef: transRef,
        note: data
      })
      dispatch(rmFetching(endpoint))
    })
}

/**
 * getFundCashTransactions
 * @param  {[type]} query [description]
 * @return {[type]}       [description]
 */
export const getFundCashTransactions = (query) => (dispatch) => {
  const endpoint = 'getFundCashTransactions'
  dispatch(addFetching(endpoint))

  return Fund.fundCashTransactions.get(query)
    .then(({ data: tx }) => {
      dispatch({
        type: GET_FUND_CASH_TRANSACTIONS,
        fundCashTransactions: tx
      })
      dispatch(rmFetching(endpoint))
    })
}

export const getFundChart = ({ fundCode, type }) => (dispatch, getState) => {
  const { fund: { chartpool } } = getState()
  if (chartpool[fundCode] && chartpool[fundCode][type]) {
    return Promise.resolve(chartpool[fundCode][type])
  }
  const endpoint = 'getFundChart'
  dispatch(addFetching(endpoint))

  const timer = setTimeout(() => {
    dispatch(addTimeout(endpoint))
  }, API_TIMEOUT)

  return Fund.chart.get({ id: 0 }, { fundCode, type })
    .then(({ data }) => {
      clearTimeout(timer)
      dispatch(rmFetching(endpoint))
      dispatch(rmTimeout(endpoint))

      dispatch(setFundCharts({ fundCode, chartType: type, data }))
    })
}

/**
 * get funds comparison data
 * @param  {Array}  fundCodes [01234, 56678]
 * @param  {Number} start     1501776000
 * @param  {Number} end       1504501418
 * @param  {String} currency  USD
 */
export const getFundComparison = ({ fundCodes, start, end, currency }) => (dispatch, getState) => {
  const timePeriodCurrencyBasedKey = constructComparisonChartDataKey({ start, end, currency })
  const { comparisonChartpool } = getState().fund
  let hasAllDataInPool = true
  fundCodes.forEach(code => {
    if (_isEmpty(_get(comparisonChartpool, [code, timePeriodCurrencyBasedKey]))) {
      hasAllDataInPool = false
    }
  })

  if (hasAllDataInPool) return true // hasAllDataInPool, no need to call API

  return Fund.fundComparison.get({
    fundCodes: fundCodes.join(','),
    start,
    end,
    currency
  }).then(({ data }) => {
    data = _sortBy(data, item => fundCodes.indexOf(item.fundJson.code))
    dispatch(setComparisonChartPool({ fundCodes, start, end, currency, data }))
  })
}

export const deleteFundComparisonItem = (fundCode) => (dispatch, getState) => {
  const { fund: { fundComparison, fundComparisonPoints } } = getState()

  const _fundComparison = _filter(fundComparison, fund => {
    return fund.fundJson.code !== fundCode
  })
  const _points = _pickBy(fundComparisonPoints, (value, key) => (key !== fundCode))

  return dispatch({
    type: SET_FUND_COMPARISON,
    fundComparison: _fundComparison,
    points: _points
  })
}

export const getFundTransferInDocs = () => (dispatch) => {
  const endpoint = 'getFundTransferInDocs'
  dispatch(addFetching(endpoint))
  return Fund.fundTransferInDocs.get()
    .then(({ data: docs }) => {
      dispatch({
        type: GET_FUND_TRANSFER_IN_DOCS,
        docs
      })
      dispatch(rmFetching(endpoint))
    })
}

/**
 * getFundHoliday
 */
export const getFundHoliday = () => (dispatch) => {
  const endpoint = 'getFundHoliday'
  dispatch(addFetching(endpoint))

  return Fund.fundHoliday.get()
    .then(({ data: holidayInfo }) => {
      dispatch({
        type: GET_FUND_HOLIDAY,
        holidayInfo
      })
      dispatch(rmFetching(endpoint))
    })
}

export const getMarketIndex = (type) => (dispatch) => {
  return Fund.marketIndex.get({ type })
    .then(({ data }) => {
      dispatch({
        type: GET_MARKET_INDEX,
        data: {
          [type]: data
        }
      })
    })
}

export const getFundRelatedTopPerformanceFunds = ({
  categoryId,
  regionId,
  period,
  size = 6
}) => (dispatch) => {
  return Fund.list.get({
    order: `-${period}`,
    categoryId,
    regionId,
    size
  }).then(({ data: fundRelatedTopPerformanceFunds }) => {
    dispatch({
      type: GET_FUND_RELATED_TOP_PERFORMANCE_FUNDS,
      data: { [period]: fundRelatedTopPerformanceFunds }
    })
  })
}

export const getTopPerformanceFunds = (period) => (dispatch) => {
  const endpoint = `getTopPerformanceFunds-${period}`
  dispatch(addFetching(endpoint))
  return Fund.list.get({
    noPageable: true,
    order: `-${period}`,
    size: 5
  }).then(({ data: topPerformanceFunds }) => {
    dispatch({
      type: GET_TOP_PERFORMANCE_FUNDS,
      data: { [period]: topPerformanceFunds }
    })
    dispatch(rmFetching(endpoint))
  })
}

export const getFundRank = ({ categoryCode, type, order }) => (dispatch) => {
  const endpoint = `getFundRank-${categoryCode}-${type}-${order}`
  dispatch(addFetching(endpoint))
  return Fund.list.get({
    noPageable: true,
    categoryCode: categoryCode,
    order: `-${order}`,
    size: 5
  }).then(({ data: fundRank }) => {
    dispatch({
      type: GET_FUND_RANK,
      period: order,
      fundType: type,
      data: fundRank
    })
    dispatch(rmFetching(endpoint))
  })
}

export const getHotBuyFunds = (query = { size: 10 }) => (dispatch) => {
  const endpoint = 'getHotBuyFunds'
  dispatch(addFetching(endpoint))

  return Fund.list.get({
    noPageable: true,
    order: 'hotBuy',
    ...query
  }).then(({ data: hotBuyFunds }) => {
    dispatch(rmFetching(endpoint))
    return dispatch({
      type: GET_HOT_BUY_FUNDS,
      hotBuyFunds
    })
  })
}

export const resetFundToComparisonPool = query => (dispatch) => {
  const { fundCodes } = query
  Fund.list.get({ ...query, fundCodes: fundCodes.join(',') })
    .then(({ data: funds }) => {
      dispatch({
        type: SET_COMPARISON_POOL,
        pool: funds.map(item => item.code)
      })
    })
}

export const addFundToComparisonPool = code => (dispatch, getState) => {
  const { comparison } = getState().fund
  let pool = _cloneDeep(comparison.pool)
  pool.push(code)

  dispatch({
    type: SET_COMPARISON_POOL,
    pool: _compact(_uniq(pool))
  })
}

export const rmFundFromComparisonPool = code => (dispatch, getState) => {
  const { comparison } = getState().fund
  let pool = _cloneDeep(comparison.pool)
  if (_includes(pool, code)) {
    pool.splice(pool.indexOf(code), 1)
  }

  dispatch({
    type: SET_COMPARISON_POOL,
    pool: _compact(_uniq(pool))
  })
  dispatch(rmFundToComparisonSelected(code))
}

export const addFundToComparisonSelected = (code, init) => (dispatch, getState) => {
  const { comparison } = getState().fund
  let selected = _cloneDeep(comparison.selected)
  // remove init fund
  if (!init && comparison.init) {
    selected = []
  }
  selected.push(code)

  // allow at most 5 funds to comparison
  if (selected.length <= 5) {
    return dispatch({
      type: SET_COMPARISON_SELECTED,
      selected: _compact(_uniq(selected)),
      init
    })
  }
}

export const rmFundToComparisonSelected = code => (dispatch, getState) => {
  const { comparison } = getState().fund
  let selected = _cloneDeep(comparison.selected)
  if (_includes(selected, code)) {
    selected.splice(selected.indexOf(code), 1)
  }

  dispatch({
    type: SET_COMPARISON_SELECTED,
    selected: _compact(_uniq(selected))
  })
}

export const clearFundToComparisonSelected = () => (dispatch) => {
  dispatch({
    type: SET_COMPARISON_SELECTED,
    selected: []
  })
}

export const clearFundComparisonPool = () => (dispatch) => {
  dispatch({
    type: SET_COMPARISON_POOL,
    pool: []
  })
}
