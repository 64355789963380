/**
 * default country code
 */
export const countryCode = '+852'

/**
 * country code list
 */
export const countryCodeOptions = [
  { value: '+852', label: '+852' },
  { value: '+86', label: '+86' },
  { value: '+853', label: '+853' }
  // { value: '+886', label: '+886' }
]
