import React from 'react'
import { connect } from 'react-redux'

import { withoutSSR } from '~/hoc'
import t from '~/utils/locales'
import { getDropdownNotifications, setNotificationItemsAsRead } from '~/actions/user'
import { showMessage } from '~/actions/helpers'

import Link from '~/components/Common/Link'
import AngleDropdownBox from '~/components/Common/AngleDropdownBox'
import NotificationList from '~/components/Logic/NotificationList'

import IconCheckAll from '~/assets/icons/checkAll.svg'
import IconBlue from '~/assets/icons/blue.svg'
import IconEmpty from '~/assets/icons/notificationEmpty.svg'

import styles from './Notifications.scss'

class Notification extends React.Component {
  state = {
    show: true
  }

  onClickReadAll = () => {
    this.props.setNotificationItemsAsRead(this.props.notifications)
  }

  componentDidMount () {
    this.props.getDropdownNotifications()
  }

  render () {
    const { count, notifications } = this.props
    return (
      <div className={styles.notification} data-test='userPanelNotifications'>
        <div className={styles.notificationIcon}>
          {count}
        </div>
        <AngleDropdownBox className={styles.panel} bodyClassName={styles.panelBody} angleClassName={styles.angle} show={this.state.show}>
          <header className={styles.boxHeader}>
            <div className={styles.title}>
              <Link to='/notifications'>
                <div className={styles.text}>{t('notifications.all')}</div>
                <IconBlue className={styles.blue} />
              </Link>
            </div>
            <div className={styles.right} onClick={this.onClickReadAll}>
              <IconCheckAll />
            </div>
          </header>
          <section className={styles.boxContent}>
            {
              notifications.length === 0
                ? <div className={styles.empty}>
                  <IconEmpty />
                  <p className={styles.tip}>{t('notifications.empty')}</p>
                </div>
                : <NotificationList items={notifications} />
            }
          </section>
        </AngleDropdownBox>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  count: state.user.notification.unreadCount,
  notifications: state.user.notification.dropdownItems
})

const mapDispatchToProps = {
  showMessage,
  getDropdownNotifications,
  setNotificationItemsAsRead
}

export default withoutSSR(connect(mapStateToProps, mapDispatchToProps)(Notification))
