import _merge from 'lodash/merge'
import _isObject from 'lodash/isObject'
import _isArray from 'lodash/isArray'
import _get from 'lodash/get'

import store from '~/store'
import { showMessage } from '~/actions/helpers'
import { openModal } from '~/actions/modal'
import { isPath } from '~/utils/urlUtils'
import { getPreferLanguage } from '~/utils/preferLanguageUtils'
import t from '~/utils/locales'
import { qs, parseHeaders } from '~/utils/net'
import { getApiTokens } from '~/utils/cookies'
import { getEnvVar } from '~/utils/env'

const checkStatus = (res) => {
  if (res.status >= 200 && res.status < 300) return res
  if (res.status === 403) {
    if (typeof window !== 'undefined' && !isPath('/login')) {
      // let url = getRedirectUrl('/login')
      store.dispatch(showMessage(t('message.tokenExpired'), 'info'))
      store.dispatch(openModal('login'))
    }
  }
  if (res.status >= 500) {
    store.dispatch(showMessage(t('message.apiError'), 'error'))
  }
  throw res
}

const parseResponse = (res) => {
  return res.text()
    .then(text => {
      const headers = parseHeaders(res.headers)
      let data = null
      try {
        data = JSON.parse(text)
      } catch (e) {
        data = text || null
      }
      return { headers, data }
    })
}

const getRedistributeHeader = () => {
  const fi = _get(store.getState(), 'user.me.fi')

  if (fi && fi.fiType === 'redistribute') {
    return 'Yes'
  }
  return 'No'
}

export default ({
  api, method, path, query = {}, body, token
}) => {
  const API_HOST = getEnvVar('SERVER_ENV_API_HOST')
  const language = getPreferLanguage()
  query = { ...query, lang: language }
  const queries = `?${qs.stringify(query)}`
  const _url = `${API_HOST}/${api.service}/${api.version}${path}${queries}`
  const tokens = getApiTokens()

  const API_HEADERS = {
    'Accept-Language': language,
    Authorization: token ? `Bearer ${token}` : tokens[api.tokenType]
    // Authorization: `Bearer ${ANONYMOUS_TOKEN}` // super token for test
  }

  if (api.service === 'stock-service') {
    API_HEADERS['X-User-Redistribute'] = getRedistributeHeader()
  }

  const _opts = {
    method,
    headers: Object.assign({}, API_HEADERS)
  }

  if (body) {
    let _headers = {}
    let _body = body
    if (body instanceof FormData) {
      _headers = {
        Accept: 'application/json'
      }
      // nothing to do
    } else if (_isObject(body) || _isArray(body)) {
      _headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
      _body = JSON.stringify(body)
    }

    _merge(_opts, {
      headers: _headers,
      body: _body
    })
  }

  return fetch(_url, _opts)
    .then(checkStatus)
    .then(parseResponse)
    .catch((err) => {
      if (err.status < 500) {
        try {
          return err.json().then().then(errJson => {
            const e = {
              status: err.status,
              error: errJson
            }
            throw e
          })
        } catch (e) {
          throw err
        }
      } else {
        throw err
      }
    })
}
