import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import { parseNumber } from '~/utils/num'
import { withLanguage } from '~/hoc'

import styles from './TextInput.scss'
const cx = classnames.bind(styles)

class TextInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      focused: false,
      value: props.value
    }
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.value !== this.props.value && nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value })
    }
    if (nextProps.focus !== this.props.focus) {
      nextProps.focus ? this.inputDom.focus() : this.inputDom.blur()
    }
    return true
  }

  onChange = (e) => {
    let value = e.target.value
    if (this.props.beforeChange) {
      value = this.props.beforeChange(this.state.value, value)
    }
    if (this.props.numberOnly) {
      value = parseNumber(value)
    }
    if (this.props.overZero) {
      value = value.replace(/^0/, '')
    }
    this.setState({ value })
    this.props.onChange && this.props.onChange(value)
  }

  onBlur = () => {
    this.setState({ focused: false })
    this.props.onBlur && this.props.onBlur(this.state.value)
  }

  onFocus = () => {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus(this.state.value)
  }

  onInput = (e) => {
    const { onInput } = this.props

    let value = e.target.value
    if (this.props.numberOnly) {
      value = parseNumber(value)
    }
    if (this.props.overZero) {
      value = value.replace(/^0/, '')
    }

    onInput && onInput(value)
  }

  onKeyDown = (e) => {
    const { onEnter } = this.props
    if (e.keyCode === 13) {
      e.preventDefault()
      this.inputDom.blur()
      onEnter && onEnter(this.state.value)
    }
  }

  render () {
    const { value, focused } = this.state
    const { placeholder, disabled, maxLength, type, className, tabIndex } = this.props

    const inputClass = cx({
      textInput: true,
      focused: focused,
      [className]: className
    })

    return (
      <input
        {...this.props.inputProps}
        ref={el => { this.inputDom = el }}
        type={type}
        className={inputClass}
        placeholder={disabled ? '' : placeholder}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        onChange={this.onChange}
        onInput={this.onInput}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={e => this.onKeyDown(e)}
        autoComplete='off'
        tabIndex={tabIndex}
      />
    )
  }
}

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  focus: PropTypes.bool,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  numberOnly: PropTypes.bool,
  overZero: PropTypes.bool,
  inputProps: PropTypes.object
}
TextInput.defaultProps = {
  type: 'text',
  focus: false,
  inputProps: {}
}

export default withLanguage(TextInput)
