
import t from '~/utils/locales'

export const getNewsMenus = () => {
  return [
    {
      link: '/news/inews',
      title: t('news_i_news'),
      // route parameter
      name: 'inews',
      // content service type
      type: 'i_news',
      hasFilter: false
    },
    {
      link: '/news/iresearch',
      title: t('news_blog_post'),
      name: 'iresearch',
      type: 'blog_post',
      hasFilter: false
    },
    {
      link: '/news/insights',
      title: t('news_market_insights'),
      name: 'insights',
      type: 'market_insights',
      hasFilter: true
    },
    {
      link: '/news/ivideo',
      title: t('news_investment_talk'),
      name: 'ivideo',
      type: 'investment_talk',
      hasFilter: false
    },
    // {
    //   link: '/news/iclasses',
    //   title: t('news_i_class'),
    //   name: 'iclasses',
    //   type: 'i_class',
    //   hasFilter: false
    // },
    // {
    //   link: '/news/iglossary',
    //   title: t('news_invest_dictionary'),
    //   name: 'iglossary',
    //   type: 'invest_dictionary',
    //   hasFilter: false
    // },
    {
      link: '/news/notices',
      title: t('news_fund_notices'),
      name: 'notices',
      type: 'fund_notices',
      hasFilter: true
    }
  ]
}

export const getNavs = () => {
  const newMenus = getNewsMenus()
  return [
    {
      title: t('header.fundMarket'),
      menu: [
        { link: '/market', title: t('fundFilter.title') },
        { link: '/compare', title: t('fundComparison.title') },
        { link: '/companies', title: t('fundCompany.title') },
        // { link: '/select', title: t('home.fundListEntries.value') },
        { link: '/hotbuy', title: t('home.fundListEntries.hot') },
        { link: '/performance-rank', title: t('home.fundListEntries.performance') },
        { link: '/news/notices', title: t('news_fund_notices') }
      ]
    },
    {
      title: t('header.info'),
      menu: newMenus.splice(0, newMenus.length - 1)
    },
    {
      title: t('header.resouces'),
      menu: [
        { link: '/resources/faq', title: t('resources_faq') },
        { link: '/resources/account-opening-dealing-procedures', title: t('resources_procedures') },
        { link: '/resources/disclaimer', title: t('resources_important_disclaimer') },
        { link: '/resources/getting-started', title: t('resources_getting_started') }
      ]
    },
    {
      title: t('header.account'),
      menu: [
        { link: '/account', title: t('header.account') },
        { link: '/favourites', title: t('favouritesList.title') },
        { link: '/account/cashtx/history', title: t('header.cashtx') },
        { link: '/user/monthly-statements', title: t('userPanel.monthlyStatement') },
        { link: '/order-approval', title: t('header.orderApproval') },
        { link: '/audit-trail', title: t('auditTrail.title'), authority: ['admin'] }
      ]
    }
  ]
}
