import _fetch from './_fetch'
import {
  CONTENT_SERVICE,
  ANONYMOUS_TOKEN_KEY
} from '~/utils/constants/api'

const Content = {
  misc: {
    get (query) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: '/pages',
        query
      })
    }
  },
  posts: {
    get (query) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: '/posts',
        query
      })
    }
  },
  companies: {
    get (query) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: '/fund-companies',
        query
      })
    }
  },
  company: {
    get (slug) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: `/fund-companies/${slug}`
      })
    }
  },
  categoires: {
    get (query) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: '/categories',
        query
      })
    }
  },
  post: {
    get (id) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: `/posts/${id}`
      })
    }
  },
  page: { // pages API only use for get full post by slug
    get (slug) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: `/pages/${slug}`
      })
    }
  },
  statics: {
    get (suffix) { // suffix = lang + json_file_name, e.g.: zh-HK/curated.json
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: `/statics/${suffix}`
      })
    }
  },
  articles: {
    get (query = {}) {
      return _fetch({
        api: {
          service: CONTENT_SERVICE.name,
          tokenType: ANONYMOUS_TOKEN_KEY,
          version: CONTENT_SERVICE.version
        },
        method: 'GET',
        path: '/contents',
        query
      })
    }
  }
}

export default Content
