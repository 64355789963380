import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'

import { OTP_COUNT_DOWN_KEY } from './constants'

export const setStorageItem = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data))
}

export const getStorageItem = (key, defaultData) => {
  let item = window.localStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
  return defaultData
}

export const removeStorageItem = (key) => {
  window.localStorage.removeItem(key)
}

export const getOTPCountDown = (key) => {
  let countDown = getStorageItem(OTP_COUNT_DOWN_KEY, [])

  let item = _find(countDown, item => {
    return item.key === key
  })

  if (item) {
    return item.value
  }
}

export const setOTPCountDown = (key, value) => {
  let countDown = getStorageItem(OTP_COUNT_DOWN_KEY, [])

  let index = _findIndex(countDown, item => {
    return item.key === key
  })

  if (index >= 0) {
    countDown.splice(index, 1, { key, value })
  } else {
    countDown.push({ key, value })
  }

  setStorageItem(OTP_COUNT_DOWN_KEY, countDown)
}
