import _get from 'lodash/get'

export const isBrowser = () => (typeof window !== 'undefined')
export const isSSR = () => (typeof window === 'undefined')
export const getEnvVar = (name) => {
  return _get(isBrowser() ? window : global, `__ENV_VARS__.${name}`) || process.env[name]
}
export const isProd = () => getEnvVar('SERVER_ENV') === 'production'
export const isStg = () => getEnvVar('SERVER_ENV') === 'staging'
export const isDev = () => getEnvVar('SERVER_ENV') === 'development'

export const isBelowIE11 = () => {
  const ua = isBrowser() && window.navigator.userAgent
  return ua.indexOf('MSIE ') > 0
}
