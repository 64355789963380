import { combineReducers } from 'redux'

import fund from './fund'
import user from './user'
import openAccount from './openAccount'
import errors from './errors'
import errorsv2 from './errorsv2'
import helpers from './helpers'
import content from './content'
import modal from './modal'
import fundFilter from './fundFilter'
import search from './search'
import trading from './trading'
import investmentAnalysis from './investmentAnalysis'
import featureToggle from './featureToggle'
import regularSaving from './regularSaving'

export default combineReducers({
  fund,
  user,
  errors,
  errorsv2,
  openAccount,
  helpers,
  content,
  modal,
  fundFilter,
  search,
  trading,
  investmentAnalysis,
  featureToggle,
  regularSaving
})
