import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { closeModal } from '~/actions/modal'
import t from '~/utils/locales'

import Modal from '~/components/Layout/Modal'
import ContentSection from '~/components/Layout/ContentSection'
import Button from '~/components/Form/Button'
import styles from './Announcement.scss'

class Announcement extends PureComponent {
  close = () => {
    this.props.closeModal('announcement')
    return true
  }
  render () {
    const { title, content } = this.props.announcement
    return (
      <Modal
        isModalOpen={this.props.modalOpenStatus.announcement}
        onRequestClose={this.close}
        overlayDismiss
      >
        <ContentSection theme='headernfooter'>
          <ContentSection.Header title={title} />
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
          <ContentSection.Footer
            footerRight={<Button theme='primary' onClick={this.close}>{t('announcement.close')}</Button>}
          />
        </ContentSection>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  modalOpenStatus: state.modal.modalOpenStatus
})
const mapDispatchToProps = {
  closeModal
}
const Connect = connect(mapStateToProps, mapDispatchToProps)(Announcement)
export default Connect
