import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'

import { withLoginStatus } from '~/hoc'
import { setRedirect } from '~/actions/helpers'
import { getMe, getProfile, getFavourites } from '~/actions/user'
import { openModal } from '~/actions/modal'

import Link from '~/components/Common/Link'
import Logo from '~/components/Common/Logo'
import GlobalSearch from '~/components/Logic/GlobalSearch'
import MediaQuery from '~/components/Layout/MediaQuery'
import t from '~/utils/locales'
import Nav from './Nav'
import UserPanel from './UserPanel'
import MobileNav from './MobileNav'
import Announcement from './Announcement'

import { getNavs } from '~/utils/constants/navs'

import styles from './Header.scss'

class Header extends React.Component {
  componentDidMount () {
    const { isLoginUser, getFavourites, me, getMe, profile, getProfile } = this.props
    if (isLoginUser()) {
      getFavourites()
      if (_isEmpty(me)) {
        getMe()
      }
      if (_isEmpty(profile)) {
        getProfile()
      }
    }
  }

  onSearchEnter = () => {
    this.props.setRedirect(`/search/${this.props.tab}`)
  }

  getNavs = () => {
    const { me } = this.props
    const navs = getNavs()

    return navs.map((nav) => {
      return {
        title: nav.title,
        menu: _filter(nav.menu, (item) => {
          if (item.authority) {
            return item.authority.indexOf(me.role) > -1
          }
          return true
        })
      }
    })
  }

  render () {
    const { logo, className } = this.props
    const navs = this.getNavs()

    const isLoggedUser = this.props.isLoginUser()
    return (
      <header className={cx(styles.header, className)}>
        <div className={styles.headerContainer}>
          <div className={styles.body}>
            <Link to={'/'} className={styles.logo} ariaLabel={t('header.home')}>
              <Logo logo={logo} />
            </Link>
            <div className={styles.rightItems}>
              <MediaQuery hide='s'>
                <GlobalSearch ifLogin={isLoggedUser} />
              </MediaQuery>
              <UserPanel />
              <Announcement />
            </div>
            <MobileNav navs={navs} />
          </div>
          <div className={styles.navBar}>
            <Nav navs={navs} />
          </div>
        </div>
      </header>
    )
  }
}

const mapToProps = [
  (state) => ({
    me: state.user.me
  }),
  {
    getMe,
    getProfile,
    getFavourites,
    setRedirect,
    openModal
  }
]
export default withLoginStatus(connect(...mapToProps)(Header))
