import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import t from '~/utils/locales'

import Favourite from '~/components/Logic/Favourite'
import EmptyIcon from '~/components/Layout/Empty/EmptyIcon'
import Link from '~/components/Common/Link'
import FormattedNum from '~/components/Common/FormattedNum'

import PurchaseIcon from '~/assets/icons/purchase.svg'

import styles from './SearchResultList.scss'

class SearchResultList extends PureComponent {
  handleItemClick = (code) => {
    window.sessionStorage.ifRecommond = ''
    this.props.onItemClick && this.props.onItemClick(code)
  }

  render () {
    const { funds, keyword, onKeywordClick } = this.props
    return (
      funds.length === 0
        ? (
          <EmptyIcon
            text={t('fundComparison.emptyFunds')}
            height={240}
            withText
            comparison
          />
        )
        : (
          <div className={styles.panel}>
            <div className={styles.heading}>
              <span className={styles.searchAll}>{t('globalSearch.searchAll')}</span>
              <Link to={`/search/fund/${keyword}`} hover onClick={onKeywordClick}>
                <div className={styles.keyword}>{keyword}</div>
              </Link>
            </div>
            <ul className={styles.list}>
              {funds.map(fund => (
                <li key={fund.code} className={styles.item}>
                  <div className={styles.fundInfo}>
                    <Link to={`/fund/${fund.code}`} hover onClick={() => this.handleItemClick(fund.code)}>
                      <div className={styles.title}>{fund.name}</div>
                    </Link>
                    <div className={styles.sub}>
                      {fund.isin} | {t('fundComparison.importFromFavModal.cpFc1y')}&nbsp;
                      <FormattedNum num={fund.cpFc1y} color showPlus percent />
                    </div>
                  </div>
                  <div className={styles.control}>
                    <Link to={`/fund/${fund.code}/buy`} className={styles.purchase}>
                      <PurchaseIcon />
                    </Link>
                    <Favourite fundCode={fund.code} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
    )
  }
}
SearchResultList.propTypes = {
  funds: PropTypes.array.isRequired,
  keyword: PropTypes.string,
  onItemClick: PropTypes.func,
  onKeywordClick: PropTypes.func.isRequired
}
SearchResultList.defaultProps = {
  funds: [],
  keyword: ''
}
export default SearchResultList
