import React from 'react'

import t from '~/utils/locales'
import SiteTitle from '~/components/Common/SiteTitle'
import AppContext from '~/shared/contexts/appContext'

export default function SharedMeta () {
  return (
    <AppContext.Consumer>
      {context => (
        <React.Fragment>
          <SiteTitle
            title={t('title')}
            extras={[
              <meta key='keywords' httpEquiv='keywords' content='general keyword' />,
              <meta key='description' name='description' content={t('common.description')} />,
              <meta key='viewport' name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=5.0' />,
              <meta key='app-id' name='apple-itunes-app' content='app-id=1295548235, app-argument=ifund://' />,
              <meta key='web-app-title' name='apple-mobile-web-app-title' content='AppTitle' />,
              <meta key='standalone' name='apple-mobile-web-app-capable' content='yes' />,
              <meta key='status-bar' name='apple-mobile-web-app-status-bar-style' content='black' />,
              <html key='lang' lang={context.preferLanguage} />
            ]}
          />
          {/* hack: disable input autofill */}
          <input type='text' name='prevent_autofill' id='prevent_autofill' defaultValue='' style={{ display: 'none' }} />
          <input type='password' name='password_fake' id='password_fake' defaultValue='' style={{ display: 'none' }} />
          <input type='email' name='email_fake' id='email_fake' defaultValue='' style={{ display: 'none' }} />
          <input style={{ display: 'none' }} />
        </React.Fragment>
      )}
    </AppContext.Consumer>
  )
}
