import React from 'react'
import { connect } from 'react-redux'
import _flowRight from 'lodash/flowRight'

import t from '~/utils/locales'

import SiteTitle from '~/components/Common/SiteTitle'
import DefaultTemplate from '~/templates/Default'
import withTemplate from '~/hoc/withTemplate'

import styles from './NotFound.scss'

class NotFound extends React.Component {
  render () {
    return (
      <div className={styles.container}>
        <SiteTitle title={t('NotFound.title')} />
        <h1>404 Not Found</h1>
      </div>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {}

export default _flowRight(
  withTemplate(DefaultTemplate),
  connect(mapStateToProps, mapDispatchToProps)
)(NotFound)
