import React from 'react'

const withoutSSR = (WrappedComponent) => {
  return class WithoutSSR extends React.Component {
    state = {
      canRender: false
    }
    // SSR will not run componentDidMount
    componentDidMount () {
      this.setState({ canRender: true })
    }
    render () {
      return (
        <React.Fragment>
          { this.state.canRender && <WrappedComponent {...this.props} /> }
        </React.Fragment>
      )
    }
  }
}

export default withoutSSR
