import _filter from 'lodash/filter'

import {
  GET_PENDING_ORDERS,
  GET_FUND_HOLDINGS,
  GET_FUND_HOLDING,
  GET_FUND_ACCOUNT_VALUE_CHART,
  GET_FUND_ACCOUNT_INFO,
  GET_FUND_CASH_BALANCE,
  GET_IPOINT,
  GET_FUND_MONTHLY_STATEMENTS,
  GET_CHARGE_GROUP,
  GET_FUND_PENDING_CASH,
  GET_ASSET_ALLOCATION,
  GET_USER_FUND_STAT,
  GET_USER_BANK_ACCOUNT,
  GET_RPQ,
  LOGIN_USER,
  GET_PROFILE,
  SET_PROFILE,
  LOGOUT,
  SAVE_OTP,
  GET_ME,
  PUT_ME,
  SET_FAVOURITES,
  SET_PERFORMANCE_VISIBILITY,
  SET_BACTH_FUNDS_QUEUE,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_PAGINATION,
  SET_NOTIFICATIONS_QUERY,
  SET_UNREAD_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATIONS_COUNT,
  SET_NOTIFICATION_AS_READ,
  SET_NOTIFICATIONS_ALL_READ,
  GET_ANNOUNCEMENT,
  GET_RSP,
  GET_MAKER,
  GET_ALLUSER,
  GET_CLIENTS
} from '~/actions/types'

const markNotificationItemsRead = (items, idList) => {
  return items.map(item => {
    if (idList.indexOf(item.id) > -1) {
      return { ...item, status: 'read' }
    }
    return item
  })
}

const initialState = {
  account: {},
  profile: {},
  performanceVisibility: true,
  chargeGroup: [],
  fundMonthlyStatements: [],
  iPoint: { value: 0, transactions: [] },
  accountValueChartData: {},
  fundAccountInfo: {},
  pendingOrders: [],
  pendingCash: [],
  fundHoldings: {
    '': [],
    'RSP': []
  },
  fundCashBalance: {},
  assetAllocation: {},
  userFundStats: [],
  userBankAccounts: [],
  me: {},
  announcement: null,
  favourites: [],
  batchFundsQueue: [],
  notification: {
    query: {
      page: 1,
      size: 20
    },
    dropdownItems: [],
    unreadCount: 0,
    items: [],
    count: 0,
    pagination: {}
  },
  investment: {
    productList: []
  },
  rpq: {},
  rsp: {},
  makers: [],
  allUser: [],
  clients: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state
      }
    case SET_PROFILE:
    case GET_PROFILE:
      return {
        ...state,
        profile: action.profile
      }
    case LOGOUT:
      return {
        ...state,
        me: {},
        account: {},
        profile: {},
        userFundStats: []
      }
    case GET_FUND_CASH_BALANCE:
      return {
        ...state,
        fundCashBalance: action.fundCashBalance
      }
    case GET_FUND_ACCOUNT_INFO:
      return {
        ...state,
        fundAccountInfo: {
          ...state.fundAccountInfo,
          ...action.info
        }
      }
    case GET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: action.pendingOrders
      }
    case GET_FUND_PENDING_CASH:
      return {
        ...state,
        pendingCash: action.items
      }
    case GET_FUND_HOLDINGS:
      return {
        ...state,
        fundHoldings: {
          ...state.fundHoldings,
          [action.holdingType]: action.fundHoldings
        }
      }
    case GET_FUND_HOLDING:
      return {
        ...state,
        fundHoldings: {
          ...state.fundHoldings,
          [action.holdingType]: [
            ...state.fundHoldings[action.holdingType],
            action.fundHolding
          ]
        }
      }
    case GET_FUND_ACCOUNT_VALUE_CHART:
      return {
        ...state,
        accountValueChartData: action.chartData
      }
    case GET_IPOINT:
      return {
        ...state,
        iPoint: action.iPoint
      }
    case GET_FUND_MONTHLY_STATEMENTS:
      return {
        ...state,
        fundMonthlyStatements: action.fundMonthlyStatements
      }
    case GET_CHARGE_GROUP:
      return {
        ...state,
        chargeGroup: action.chargeGroup
      }
    case GET_ASSET_ALLOCATION:
      return {
        ...state,
        assetAllocation: action.data
      }
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.announcement
      }
    case GET_USER_FUND_STAT:
      return {
        ...state,
        userFundStats: {
          ...state.userFundStats,
          [action.code]: action.userFundStat
        }
      }
    case SAVE_OTP:
      return {
        ...state,
        otp: {
          ...state.otp,
          ...action.otp
        }
      }
    case GET_ME:
      return {
        ...state,
        me: action.me
      }
    case PUT_ME:
      return {
        ...state,
        me: action.data
      }
    case SET_FAVOURITES:
      return {
        ...state,
        favourites: action.favourites
      }
    case SET_PERFORMANCE_VISIBILITY:
      return {
        ...state,
        performanceVisibility: action.visibility
      }
    case SET_BACTH_FUNDS_QUEUE:
      return {
        ...state,
        batchFundsQueue: action.batchFundsQueue
      }
    case GET_USER_BANK_ACCOUNT:
      return {
        ...state,
        userBankAccounts: action.userBankAccounts
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          ...state.notification,
          items: action.items
        }
      }
    case SET_NOTIFICATIONS_PAGINATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          pagination: action.pagination
        }
      }
    case SET_NOTIFICATIONS_QUERY:
      return {
        ...state,
        notification: {
          ...state.notification,
          query: action.query
        }
      }
    case SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          ...state.notification,
          dropdownItems: action.dropdownItems
        }
      }
    case GET_RPQ:
      return {
        ...state,
        rpq: action.rpq
      }
    case SET_UNREAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notification: {
          ...state.notification,
          unreadCount: action.unreadCount
        }
      }
    case SET_NOTIFICATION_AS_READ: {
      const { unreadCount, dropdownItems, items } = state.notification
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === action.id) {
          items[i].status = 'read'
          break
        }
      }
      return {
        ...state,
        notification: {
          ...state.notification,
          unreadCount: unreadCount - 1,
          dropdownItems: _filter(dropdownItems, item => item.id !== action.id),
          items: [...items]
        }
      }
    }
    case SET_NOTIFICATIONS_ALL_READ: {
      const { unreadCount, dropdownItems, items } = state.notification
      const idList = action.idList
      return {
        ...state,
        notification: {
          ...state.notification,
          unreadCount: unreadCount - idList.length,
          dropdownItems: markNotificationItemsRead(dropdownItems, idList),
          items: markNotificationItemsRead(items, idList)
        }
      }
    }
    case GET_RSP: {
      return {
        ...state,
        rsp: action.rsp
      }
    }
    case GET_MAKER: {
      return {
        ...state,
        makers: action.makers
      }
    }
    case GET_ALLUSER: {
      return {
        ...state,
        allUser: action.all
      }
    }
    case GET_CLIENTS: {
      return {
        ...state,
        clients: action.clients
      }
    }
    default:
      return state
  }
}
