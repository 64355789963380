import {
  getPreferLanguage
} from '~/utils/preferLanguageUtils'
import {
  langPathRegExp
} from '~/utils/locales'

export function getLanguagePath (language = null) {
  return `/${getPreferLanguage(language).toLowerCase()}`
}

export const getLocalePath = (path = '') => {
  if (!path.match(langPathRegExp)) {
    const langPath = getLanguagePath()
    path = `${langPath}${path}`
  }
  return path
}

export const isPath = (path) => {
  let re = new RegExp(`((zh-cn)|(zh-hk)|(en))${path}`, 'i')
  return re.test(window.location.pathname)
}

export const getRedirectUrl = (path) => {
  const back = window.location.pathname + window.location.search
  let redirectUrl = getLocalePath(path)
  return `${redirectUrl}?back=${encodeURIComponent(back)}`
}
