import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '@/router'
import classnames from 'classnames/bind'

import { setRedirect, setLang } from '~/actions/helpers'
import t from '~/utils/locales'

import Clickable from '~/components/Form/Clickable'

import styles from './LocaleToggler.scss'

const cx = classnames.bind(styles)

class UserPanel extends Component {
  state = {
    showLocales: true
  }

  // temporiray add for UAT, refactor needed
  redirectLangRoute = (langPath) => {
    const { pathname, search } = this.props.location

    const to = pathname.replace(/(zh-hk|zh-cn|en)/, langPath) + search
    if (typeof window !== 'undefined') {
      this.props.setLang(langPath)
      window.location.replace(to)
    }
  }

  render () {
    const { lang, className, isMobileMenu = false } = this.props
    return (
      <div className={cx('localeToggler', className)} data-test='localeToggler'>
        {lang.toLowerCase() !== 'zh-hk' &&
          <div className={styles.icon}>
            <Clickable
              className={cx(styles.item, isMobileMenu ? styles.mobile : null)}
              onClick={() => this.redirectLangRoute('zh-hk')}
            >
              {t('locales.hk')}
            </Clickable>
          </div>
        }
        {lang.toLowerCase() !== 'zh-cn' &&
          <div className={styles.icon}>
            <Clickable
              className={cx(styles.item, isMobileMenu ? styles.mobile : null)}
              onClick={() => this.redirectLangRoute('zh-cn')}
            >
              {t('locales.zh')}
            </Clickable>
          </div>
        }
        {lang !== 'en' &&
          <div className={styles.icon}>
            <Clickable
              className={cx(styles.item, isMobileMenu ? styles.mobile : null)}
              onClick={() => this.redirectLangRoute('en')}
            >
              EN
            </Clickable>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lang: state.helpers.lang
})

const mapDispatchToProps = {
  setRedirect,
  setLang
}

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPanel)))
