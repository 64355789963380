import { matchPath } from '@/router'
import _reduce from 'lodash/reduce'
import loadable from '@loadable/component'

import { langPathRegExp, supportedLanguages } from '~/utils/locales'

const Home = loadable(() => import(/* webpackPrefetch: true */'~/pages/Home'))
const FundComparison = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundComparison'))
const FundMarket = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundFilter'))
const FundCompany = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundCompany'))
const FundCompanyDetail = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundCompanyDetail'))
const FundDetail = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundDetail'))
const Search = loadable(() => import(/* webpackPrefetch: true */'~/pages/Search'))
const News = loadable(() => import(/* webpackPrefetch: true */'~/pages/News'))
const NewsDetail = loadable(() => import(/* webpackPrefetch: true */'~/pages/NewsDetail'))
const Login = loadable(() => import(/* webpackPrefetch: true */'~/pages/Login'))
const ForgotPassword = loadable(() => import(/* webpackPrefetch: true */'~/pages/ForgotPassword'))
const ResetPassword = loadable(() => import(/* webpackPrefetch: true */'~/pages/ResetPassword'))
const UserCenter = loadable(() => import(/* webpackPrefetch: true */'~/pages/UserCenter'))
const FavouritesList = loadable(() => import(/* webpackPrefetch: true */'~/pages/FavouritesList'))
const FundSubscription = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundSubscription'))
const FundRedemption = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundRedemption'))
const FundSwitching = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundSwitching'))
const Account = loadable(() => import(/* webpackPrefetch: true */'~/pages/Account'))
const DetailedFundHoldings = loadable(() => import(/* webpackPrefetch: true */'~/pages/DetailedFundHoldings'))
const FundTransaction = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundTransaction'))
const CashTransaction = loadable(() => import(/* webpackPrefetch: true */'~/pages/CashTransaction'))
const FundHolding = loadable(() => import(/* webpackPrefetch: true */'~/pages/FundHolding'))
const AuditTrail = loadable(() => import(/* webpackPrefetch: true */'~/pages/AuditTrail'))
const CurateList = loadable(() => import(/* webpackPrefetch: true */'~/pages/CurateList'))
const CuratedListDetail = loadable(() => import(/* webpackPrefetch: true */'~/pages/CuratedListDetail'))
const PerformanceRank = loadable(() => import(/* webpackPrefetch: true */'~/pages/PerformanceRank'))
const HotBuy = loadable(() => import(/* webpackPrefetch: true */'~/pages/HotBuy'))
const AboutUs = loadable(() => import(/* webpackPrefetch: true */'~/pages/AboutUs'))
const UserFeedback = loadable(() => import(/* webpackPrefetch: true */'~/pages/UserFeedback'))
const Notifications = loadable(() => import(/* webpackPrefetch: true */'~/pages/Notifications'))
const Resources = loadable(() => import(/* webpackPrefetch: true */'~/pages/Resources'))
const Maintenance = loadable(() => import(/* webpackPrefetch: true */'~/pages/Maintenance'))
const OrderApproval = loadable(() => import(/* webpackPrefetch: true */'~/pages/OrderApproval'))
const Clients = loadable(() => import(/* webpackPrefetch: true */'~/pages/Clients'))
const SelectClient = loadable(() => import(/* webpackPrefetch: true */'~/pages/SelectClient'))

const routes = [
  { path: '/', exact: true, component: Home },

  // fund market
  { path: '/compare', component: FundComparison, authority: ['user'] },
  { path: '/market', component: FundMarket, authority: ['user'] },
  { path: '/companies', exact: true, component: FundCompany, authority: ['user'] },
  { path: '/companies/:companyCode', component: FundCompanyDetail, authority: ['user'] },

  // single fund detail
  { path: '/fund/:code', exact: true, component: FundDetail, authority: ['user'] },

  // fund search result
  { path: '/search/:tab/:keyword', exact: true, component: Search, authority: ['user'] },

  // news
  { path: '/news', exact: true, component: News, authority: ['user'] },
  { path: '/news/:tab', component: News, authority: ['user'] },
  { path: '/post/:id', component: NewsDetail, authority: ['user'] },

  // auth
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgotPassword },

  // come from email link
  { path: '/reset-password', component: ResetPassword },

  // user center
  { path: '/user', exact: true, component: UserCenter, authority: ['user'] }, // only logged in user can visit
  { path: '/user/:tab', component: UserCenter, authority: ['user'] },

  // favoursites
  { path: '/favourites', component: FavouritesList, authority: ['user'] },

  // trasaction
  { path: '/fund/:code/buy', component: FundSubscription, authority: ['client'] }, // only client can visit
  { path: '/fund/:code/sell', component: FundRedemption, authority: ['client'] },
  { path: '/fund/:code/switch', component: FundSwitching, authority: ['client'] },

  // accounts
  { path: '/account', exact: true, component: Account, authority: ['client'] },
  { path: '/account/holdings', exact: true, component: DetailedFundHoldings, authority: ['client'] },
  { path: '/account/fundtx/:status', component: FundTransaction, authority: ['client'] },
  { path: '/account/cashtx/:status', component: CashTransaction, authority: ['client'] },
  { path: '/holding/:code', exact: true, component: FundHolding, authority: ['client'] },

  { path: '/audit-trail', component: AuditTrail, authority: ['user'] },

  // hot list
  { path: '/curated', component: CurateList, authority: ['user'] },

  // cureateList
  { path: '/curated-list/:code', component: CuratedListDetail, authority: ['user'] },

  // fundRank
  { path: '/performance-rank', component: PerformanceRank, authority: ['user'] },

  // hot buy fund list
  { path: '/hotbuy', component: HotBuy, authority: ['user'] },

  { path: '/about-us', component: AboutUs },

  { path: '/user-feedback', component: UserFeedback, authority: ['user'] },

  // notifications
  { path: '/notifications', component: Notifications, authority: ['user'] },

  // resources
  // resources
  { path: '/resources', exact: true, component: Resources },
  { path: '/resources/:tab', exact: true, component: Resources },
  { path: '/resources/:tab/:subTab', exact: true, component: Resources },

  // maintenance
  { path: '/maintenance', component: Maintenance },
  { path: '/order-approval', component: OrderApproval, authority: ['client'] },
  { path: '/clients', component: Clients, authority: ['client'] },
  { path: '/select-client', component: SelectClient, authority: ['client'] }
]

export const i18nRoutes = _reduce(routes, (i18nRoutes, route) => {
  supportedLanguages.forEach(lang => {
    i18nRoutes.push({ ...route, path: `/${lang}${route.path}` })
  })
  return i18nRoutes
}, [])

export const matchRouteByPath = (path) => {
  path = path.replace(langPathRegExp, '')
  let matchedRoute = null
  routes.some(_route => {
    const match = matchPath(path, _route)
    if (match) {
      matchedRoute = _route
      return true
    }
    return false
  })
  return matchedRoute
}
export const getFundUrl = (code) => `/fund/${code}`
export const getFundSubscriptionUrl = (code) => `/fund/${code}/buy`
export const getFundHoldingUrl = (code, type) => type ? `/holding/${code}?type=${type}` : `/holding/${code}`
export const getFundRedemptionUrl = (code) => `/fund/${code}/sell`
export const getFundSwitchingUrl = (code) => `/fund/${code}/switch`

export default routes

export { AdvRoute } from './AdvRoute'
export { RedirectRoute } from './RedirectRoute'
